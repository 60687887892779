import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "styled-components";

/* import utils */
import { filterString, maskCurrencyReal, getDate, maskCurrencyRealtoCalc } from "./../../util/functions";
import { NotifyContext } from "./../../util/notify";

/* import services */
import api from "./../../services/api";
import upload from "./../../services/s3";

import { useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";

/* import components */
import Legend from "./../../components/Legend";
import Search from "./../../components/Search";
import Input from "./../../components/Form/Input";
import Select from "./../../components/Form/Select";
import FormSelectMultiple from "./../../components/Form/SelectMultiple";
import File from "./../../components/Form/File";
import FormArchive from "./../../components/Form/Archive";

/* import icons */
import IncomeIcon from "./../../assets/icons/income";

/* import styles */
import { Container, Cards, Card, Title, Subtitle, Comment, AddButton, FormModal, FormGroup } from "./styles";

export default function Magazine({ toggleTitles }) {
  toggleTitles("Revista CRO-MT", "Edições em PDF");

  const { status: colors } = useContext(ThemeContext);
  const { notify } = useContext(NotifyContext);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const client_id = process.env.REACT_APP_CLIENT_ID;
  const site_id = process.env.REACT_APP_SITE_ID;

  const [modalOpen, setModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchStatus, setSearchStatus] = useState("");

  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);

  const [titleModal, setTitleModal] = useState("Nova Edição");

  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [is_active, setIs_active] = useState(true);
  const [image_path, setImage_path] = useState("");
  const [images, setImages] = useState([]);
  const [file_path, setFile_path] = useState("");
  const [files, setFiles] = useState([]);

  const handleFilter = async text => {
    const filtered = initialData.filter(
      item =>
        filterString(item.is_active ?? false, (searchStatus === "inative" ? false : true) ?? "") &&
        (filterString(item.id, text) || filterString(item.title, text)),
    );
    setData(filtered);
  };

  const handleClearForm = event => {
    event.preventDefault();

    setTitleModal("Nova Edição");

    setId("");
    setTitle("");
    setFile_path("");
    setIs_active(true);
    setImage_path("");
    setImages([]);
    setFile_path("");
    setFiles([]);
  };

  const handleNewData = () => {
    setTitleModal("Nova Edição");

    setId("");
    setTitle("");
    setFile_path("");
    setIs_active(true);
    setImage_path("");
    setImages([]);
    setFiles([]);

    setModalOpen(true);
  };

  const handleOpenModalEdit = async (p_id, p_title, p_file_path, p_is_active, p_image_path) => {
    setTitleModal("Editar Edição");

    setId(p_id);
    setTitle(p_title);
    setFile_path(p_file_path);
    setIs_active(p_is_active);
    setImage_path(p_image_path);
    setImages([{ file: p_image_path }]);
    setFiles([{ file: p_file_path }]);

    setModalOpen(true);
  };

  const handleSave = async event => {
    event.preventDefault();

    if (images.length <= 0) {
      notify.show(true, "Uma imagem é obrigatória", "info");
      return;
    }

    if (files.length <= 0) {
      notify.show(true, "Selecione o arquivo em PDF", "info");
      return;
    }

    notify.show(true, "Salvando...", "info");

    const imagesUpload = await upload(client_id, site_id, images);
    let image;

    if (imagesUpload && imagesUpload[0].fileUrl) {
      image = imagesUpload[0].fileUrl;
    } else {
      notify.show(true, "Não foi possivel realizar o upload da imagem! Tente novamente.", "error");
      return;
    }

    const fileUpload = await upload(client_id, site_id, files);
    let file;

    if (fileUpload && fileUpload[0].fileUrl) {
      file = fileUpload[0].fileUrl;
    } else {
      notify.show(true, "Não foi possivel realizar o upload do arquivo! Tente novamente.", "error");
      return;
    }

    try {
      if (!id) {
        if (!file) {
          notify.show(true, "Defina um arquivo", "info");
          return false;
        }

        await api.post(`/magazines/`, { image_path: image, file_path: file, title, is_active });
        notify.show(true, "Registro adicionado com sucesso", "success");
      } else {
        await api.put(`/magazines/${id}`, { image_path: image, file_path: file, title, is_active });
        notify.show(true, "Registro atualizado com sucesso", "success");
      }

      loadData();
      setModalOpen(false);
    } catch (error) {
      console.log(error);
      notify.show(true, "Erro ao salvar registro! Tente novamente", "error");
    }
  };

  async function loadData() {
    try {
      const { data } = await api.get(`/magazines/panel`, {});
      await setInitialData(data);
      await setData(data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleFilter(searchValue);
  }, [searchStatus]);

  useEffect(() => {
    loadData();
    document.querySelector("#input").focus();
  }, []);

  return (
    <>
      <Container>
        <Legend
          active={searchStatus}
          onClick={status => {
            setSearchStatus(status);
          }}
          items={[
            {
              key: "status",
              value: "active",
              title: "Filtrar Ativos",
              color: colors["active"],
            },
            {
              key: "status",
              value: "inative",
              title: "Filtrar Inativos",
              color: colors["inative"],
            },
          ]}
        />

        <Cards>
          {data.map(({ id, title, file_path, is_active, image_path }) => {
            return (
              <Card
                onClick={() => {
                  handleOpenModalEdit(id, title, file_path, is_active, image_path);
                }}
                status={colors[is_active ? "active" : "inative"]}
                key={id}>
                <Title>
                  {title}
                  <br />
                  <Comment>{file_path}</Comment>
                </Title>
              </Card>
            );
          })}
        </Cards>

        <Search
          InitialValue={searchValue}
          onChange={text => {
            setSearchValue(text);
            handleFilter(text);
          }}
        />

        <AddButton onClick={() => handleNewData()}>
          <IncomeIcon /> Nova Edição
        </AddButton>
      </Container>

      <Dialog
        fullScreen={fullScreen}
        open={modalOpen}
        onClose={event => {
          handleClearForm(event);
          setModalOpen(false);
        }}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{titleModal}</DialogTitle>
        <DialogContent>
          <FormModal onSubmit={handleSave} autoComplete="off">
            <FormGroup>
              <Input
                id="title"
                name="title"
                label="Título"
                tabIndex={101}
                type="text"
                initialValue={title}
                onChange={text => {
                  setTitle(text);
                }}
                required={true}
              />

              <FormArchive
                id="video_path"
                name="video_path"
                label="Arquivo em pdf"
                accept="application/pdf"
                tabIndex={102}
                onChange={files => {
                  setFiles(files);
                }}
                required={false}
              />

              <Select
                id="is_active"
                name="is_active"
                label="Situação"
                tabIndex={103}
                initialValue={is_active}
                options={[
                  { value: true, text: "Ativo" },
                  { value: false, text: "Inativo" },
                ]}
                onChange={text => {
                  setIs_active(text);
                }}
                multiple={false}
              />

              <File
                id="image_path"
                initialFile={image_path}
                onChange={files => {
                  setImages(files);
                }}
              />
              <Comment>Imagem 470px x 585px e no máximo 1mb</Comment>
            </FormGroup>

            <button id="btnFormSubmit" style={{ display: "none" }}>
              enviar
            </button>
          </FormModal>
        </DialogContent>

        <DialogActions>
          <Button
            tabIndex={105}
            onClick={event => {
              handleClearForm(event);
              setModalOpen(false);
            }}
            color="secondary">
            Cancelar
          </Button>
          <Button tabIndex={104} color="primary" onClick={() => document.querySelector("#btnFormSubmit").click()}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
