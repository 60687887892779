import React from "react";
import { useHistory } from "react-router-dom";

/* import icons */
import LoginIcon from "./../../assets/icons/login";

import { Container, Article, Content, Welcome, Button, ContentText } from "./styles";

export default function PrivacyPolicy() {
  const history = useHistory();

  return (
    <Container>
      <Article>
        <Content>
          <Welcome>Bem Vindo!</Welcome>
          <ContentText>
            <h3>Aplicativo Gallo Man</h3>
            <br />
            <h3>POLÍTICA DE PRIVACIDADE</h3>
            <br />
            Entendemos a importância de esclarecer a respeito de quais dados são coletados de nossos usuários/colaboradores e o que fazemos com eles, bem como a responsabilidade de manter essas
            informações em segurança.
            <br />
            <br />
            Para isso detalhamos a seguir quais informações são essas e como as utilizamos.
            <br />
            <br />
            <strong>É importante que o usuário fique ciente de que essa política pode sofrer alterações à qualquer momento.</strong>
            <br />
            <br />
            <strong>Dados pessoais que são coletados:</strong> Quando o Usuário/Colaborador entra no Aplicativo é solicitado o e-mail e senha de acesso. Tudo fica armazenado em nossos bancos de dados
            e protegido por mecanismos de seguranças que visam coibir qualquer tipo de acesso indevido.
            <br />
            <br />
            <br />
            Caso tenha mais alguma dúvida, pode entrar em contato conosco através do e-mail tech@agenciaonside.com.br que teremos o prazer de respoder.
          </ContentText>
          <Button onClick={() => history.push("/login")}>
            Ir para o site <LoginIcon width={20} height={20} color="#100F03" />
          </Button>
        </Content>
      </Article>
    </Container>
  );
}
