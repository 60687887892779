import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "styled-components";

/* import utils */
import { filterString, maskCurrencyReal, getDate, maskCurrencyRealtoCalc } from "./../../util/functions";
import { NotifyContext } from "./../../util/notify";

/* import services */
import api from "./../../services/api";

/* import components */
import Legend from "./../../components/Legend";
import Search from "./../../components/Search";
import Input from "./../../components/Form/Input";
import Select from "./../../components/Form/Select";

/* import icons */
import IncomeIcon from "./../../assets/icons/income";

/* import styles */
import { Container, Cards, Card, Title, Subtitle, Comment, AddButton, FormModal, FormGroup } from "./styles";

export default function Certificate({ toggleTitles }) {
  toggleTitles("Certificados", "Histórico das importações");

  const { status: colors } = useContext(ThemeContext);
  const { notify } = useContext(NotifyContext);

  const [searchValue, setSearchValue] = useState("");
  const [searchStatus, setSearchStatus] = useState("");

  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);

  const [titleModal, setTitleModal] = useState("Novo SubMenu");

  const handleFilter = async text => {
    const filtered = initialData.filter(
      item => filterString(item.id, text) || filterString(item.user.name, text) || filterString(getDate(item.created_at, "dd'/'MM'/'yyyy"), text),
    );
    setData(filtered);
  };

  async function loadData() {
    try {
      const { data } = await api.get(`/certificates/imports`, {});
      await setInitialData(data);
      await setData(data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleFilter(searchValue);
  }, [searchStatus]);

  useEffect(() => {
    loadData();
    document.querySelector("#input").focus();
  }, []);

  return (
    <>
      <Container>
        {/*<Legend
          active={searchStatus}
          onClick={status => {
            setSearchStatus(status);
          }}
          items={[
            {
              key: "status",
              value: "active",
              title: "Filtrar Ativos",
              color: colors["active"],
            },
            {
              key: "status",
              value: "inative",
              title: "Filtrar Inativos",
              color: colors["inative"],
            },
          ]}
        />*/}

        <Cards>
          {data.map(({ id, user, created_at }) => {
            return (
              <Card to={`/certificados/historico/${id}`} key={id}>
                {/*status={colors[is_active ? "active" : "inative"]}*/}
                <Title>{user.name}</Title>
                {/*<Subtitle>{uri}</Subtitle>*/}
                <Comment>{getDate(created_at, "dd'/'MM'/'yyyy 'às' HH':'mm")}</Comment>
              </Card>
            );
          })}
        </Cards>

        <Search
          InitialValue={searchValue}
          onChange={text => {
            setSearchValue(text);
            handleFilter(text);
          }}
          btnAdd
          btnAddTo="/certificados/importacao"
          btnAddIcon={<IncomeIcon />}
          btnAddtitle="Nova Carga"
        />
      </Container>
    </>
  );
}
