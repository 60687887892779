import React, { useEffect, useState } from "react";
import Select from "react-select";
import api from "../../services/api";

import { Container, FilterContainer, FilterTitle, FilterContent, Filter, Label, HorarioButton, FilterHorario, Content, CardAgendamento, CardTitle, CardRow, CardLabel, CardText } from "./styles";
import { getDate } from "../../util/functions";

const setorOptions = [
  { value: "Secretaria e Cadastro", label: "Secretaria e Cadastro" },
  { value: "Financeiro", label: "Financeiro" },
  { value: "Jurídico", label: "Jurídico" },
  { value: "Fiscalização", label: "Fiscalização" },
];

const customStyle = {
  control: base => ({
    ...base,
    height: 52,
    minHeight: 52,
  }),
  valueContainer: provided => ({
    ...provided,
    height: 52,
    minHeight: 52,
  }),
  indicatorSeparator: () => ({
    height: 52,
    minHeight: 52,
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: 52,
    minHeight: 52,
  }),
};

const horarios = ["08:00", "09:00", "10:00", "11:00", "13:00", "14:00", "15:00", "16:00"];

function Agendamentos({ toggleTitles }) {
  toggleTitles("Agendamento", "Agenda de Horários");
  const [horario, setHorario] = useState("");
  const [sedeOptions, setSedeOptions] = useState([]);
  const [date, setDate] = useState("");
  const [agendamento, setAgendamentos] = useState([]);
  const [sede_id, setSede] = useState("");
  const [setor, setSetor] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function LoadData() {
      setLoading(true);
      try {
        await api
          .get("/sede")
          .then(({ data }) => {
            let sedes = data.map(item => {
              return { value: item.id, label: item.name };
            });
            setSedeOptions(sedes);
          })
          .then(async () => {
            await api.get(`/agendamento?date=${date}&sede_id=${sede_id}&setor=${setor}&horario=${horario}`).then(({ data }) => {
              setAgendamentos(data.data);
              setLoading(false);
            });
          });
      } catch (error) {
        console.log(error);
      }
    }
    LoadData();
  }, [date, sede_id, setor, horario]);

  const Horarios = horarios.map((item, index) => (
    <HorarioButton key={index} className={horario === item ? "active" : ""} onClick={() => setHorario(item)}>
      {item}
    </HorarioButton>
  ));

  const Card = agendamento.map((item, index) => (
    <CardAgendamento key={index}>
      <CardTitle>{item.name}</CardTitle>
      <CardRow>
        <CardLabel>AGENDAMENTO</CardLabel>

        <CardText>{`${getDate(item.date, "dd/MM/yyyy")} ${item.horario}`}</CardText>
      </CardRow>
      <CardRow>
        <CardLabel>E-MAIL</CardLabel>
        <CardText>{item.email}</CardText>
      </CardRow>
      <CardRow>
        <CardLabel>TELEFONE</CardLabel>
        <CardText>{item.phone}</CardText>
      </CardRow>
      <CardRow>
        <CardLabel>NÚMERO DO CRO-MT</CardLabel>
        <CardText>{item?.cro || "Não informado"}</CardText>
      </CardRow>
      <CardRow>
        <CardLabel>SOLICITAÇÃO</CardLabel>
        <CardText>{item.solicitacao}</CardText>
      </CardRow>
    </CardAgendamento>
  ));
  return (
    <Container>
      <FilterContainer>
        <FilterTitle>FILTROS:</FilterTitle>
        <FilterContent>
          <Filter>
            <Label>DATA</Label>
            <input type="date" value={date} onChange={event => setDate(event.target.value)} />
          </Filter>
          <Filter>
            <Label>SEDE</Label>
            <Select options={sedeOptions} placeholder="Selecione..." styles={customStyle} onChange={({ value }) => setSede(value)} />
          </Filter>
          <Filter>
            <Label>SETOR</Label>
            <Select options={setorOptions} placeholder="Selecione..." styles={customStyle} onChange={({ value }) => setSetor(value)} />
          </Filter>
        </FilterContent>
        <FilterHorario>{Horarios}</FilterHorario>
      </FilterContainer>
      <Content>{Card}</Content>
    </Container>
  );
}

export default Agendamentos;
