import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "styled-components";

/* import utils */
import { filterString, maskCurrencyReal, getDate, maskCurrencyRealtoCalc } from "./../../util/functions";
import { NotifyContext } from "./../../util/notify";

/* import services */
import api from "./../../services/api";
import upload from "./../../services/s3";

import { useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";

/* import components */
import Legend from "./../../components/Legend";
import Search from "./../../components/Search";
import Input from "./../../components/Form/Input";
import Select from "./../../components/Form/Select";
import FormSelectMultiple from "./../../components/Form/SelectMultiple";
import File from "./../../components/Form/File";

/* import icons */
import IncomeIcon from "./../../assets/icons/income";

/* import styles */
import { Container, Cards, Card, Title, Subtitle, Comment, AddButton, FormModal, FormGroup } from "./styles";

export default function Banner({ toggleTitles }) {
  toggleTitles("Popups", "Popups Informativos");

  const { status: colors } = useContext(ThemeContext);
  const { notify } = useContext(NotifyContext);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const client_id = process.env.REACT_APP_CLIENT_ID;
  const site_id = process.env.REACT_APP_SITE_ID;

  const [modalOpen, setModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchStatus, setSearchStatus] = useState("");

  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);

  const [titleModal, setTitleModal] = useState("Novo Banner");

  const [id, setId] = useState("");
  const [uri, setUri] = useState("");
  const [target, setTarget] = useState("");
  const [title, setTitle] = useState("");
  const [is_active, setIs_active] = useState(true);
  const [image_path, setImage_path] = useState("");
  const [images, setImages] = useState([]);

  const handleFilter = async text => {
    const filtered = initialData.filter(
      item =>
        filterString(item.is_active ?? false, (searchStatus === "inative" ? false : true) ?? "") &&
        (filterString(item.id, text) || filterString(item.title, text) || filterString(item.uri ?? "", text)),
    );
    setData(filtered);
  };

  const handleClearForm = event => {
    event.preventDefault();

    setTitleModal("Novo Popup");

    setId("");
    setUri("");
    setTarget("");
    setTitle("");
    setIs_active(true);
    setImage_path("");
    setImages([]);
  };

  const handleNewData = () => {
    setTitleModal("Novo Popup");

    setId("");
    setUri("");
    setTarget("");
    setTitle("");
    setIs_active(true);
    setImage_path("");
    setImages([]);

    setModalOpen(true);
  };

  const handleOpenModalEdit = async (p_id, p_uri, p_target, p_title, p_is_active, p_image_path) => {
    setTitleModal("Editar Popup");

    setId(p_id);
    setUri(p_uri);
    setTarget(p_target);
    setTitle(p_title);
    setIs_active(p_is_active);
    setImage_path(p_image_path);
    setImages([{ file: p_image_path }]);

    setModalOpen(true);
  };

  const handleSave = async event => {
    event.preventDefault();

    if (images.length <= 0) {
      notify.show(true, "Uma imagem é obrigatória", "info");
      return;
    }

    notify.show(true, "Salvando...", "info");

    const imagesUpload = await upload(client_id, site_id, images);
    let image;

    if (imagesUpload && imagesUpload[0].fileUrl) {
      image = imagesUpload[0].fileUrl;
    } else {
      notify.show(true, "Não foi possivel realizar o upload da imagem! Tente novamente.", "error");
      return;
    }

    try {
      if (!id) {
        await api.post(`/popups/`, { image_path: image, uri, target, title, is_active });
        notify.show(true, "Registro adicionado com sucesso", "success");
      } else {
        await api.put(`/popups/${id}`, { image_path: image, uri, target, title, is_active });
        notify.show(true, "Registro atualizado com sucesso", "success");
      }

      loadData();
      setModalOpen(false);
    } catch (error) {
      console.log(error);
      notify.show(true, "Erro ao salvar registro! Tente novamente", "error");
    }
  };

  async function loadData() {
    try {
      const { data } = await api.get(`/popups/panel`, {});
      await setInitialData(data);
      await setData(data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleFilter(searchValue);
  }, [searchStatus]);

  useEffect(() => {
    loadData();
    document.querySelector("#input").focus();
  }, []);

  return (
    <>
      <Container>
        <Legend
          active={searchStatus}
          onClick={status => {
            setSearchStatus(status);
          }}
          items={[
            {
              key: "status",
              value: "active",
              title: "Filtrar Ativos",
              color: colors["active"],
            },
            {
              key: "status",
              value: "inative",
              title: "Filtrar Inativos",
              color: colors["inative"],
            },
          ]}
        />

        <Cards>
          {data.map(({ id, uri, target, title, is_active, image_path }) => {
            return (
              <Card
                onClick={() => {
                  handleOpenModalEdit(id, uri, target, title, is_active, image_path);
                }}
                status={colors[is_active ? "active" : "inative"]}
                key={id}>
                <Title>
                  {title}
                  <br />
                  <Comment>{uri}</Comment>
                </Title>
              </Card>
            );
          })}
        </Cards>

        <Search
          InitialValue={searchValue}
          onChange={text => {
            setSearchValue(text);
            handleFilter(text);
          }}
        />

        <AddButton onClick={() => handleNewData()}>
          <IncomeIcon /> Novo Popup
        </AddButton>
      </Container>

      <Dialog
        fullScreen={fullScreen}
        open={modalOpen}
        onClose={event => {
          handleClearForm(event);
          setModalOpen(false);
        }}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{titleModal}</DialogTitle>
        <DialogContent>
          <FormModal onSubmit={handleSave} autoComplete="off">
            <FormGroup>
              <Input
                id="title"
                name="title"
                label="Título"
                tabIndex={101}
                type="text"
                initialValue={title}
                onChange={text => {
                  setTitle(text);
                }}
                required={true}
              />

              <Input
                id="uri"
                name="uri"
                label="Destino"
                tabIndex={103}
                type="text"
                initialValue={uri}
                onChange={text => {
                  setUri(text);
                }}
              />

              <Select
                id="target"
                name="target"
                label="Abrir em"
                tabIndex={104}
                initialValue={target}
                options={[
                  { value: "", text: "Mesma página" },
                  { value: "_blank", text: "Nava aba" },
                ]}
                onChange={text => {
                  setTarget(text);
                }}
                multiple={false}
              />

              <Select
                id="is_active"
                name="is_active"
                label="Situação"
                tabIndex={105}
                initialValue={is_active}
                options={[
                  { value: true, text: "Ativo" },
                  { value: false, text: "Inativo" },
                ]}
                onChange={text => {
                  setIs_active(text);
                }}
                multiple={false}
              />

              <File
                id="image_path"
                initialFile={image_path}
                onChange={files => {
                  setImages(files);
                }}
                height="250px"
              />

              <Comment>Imagem 900px x 500px e no máximo 1mb</Comment>
            </FormGroup>

            <button id="btnFormSubmit" style={{ display: "none" }}>
              enviar
            </button>
          </FormModal>
        </DialogContent>

        <DialogActions>
          <Button
            tabIndex={107}
            onClick={event => {
              handleClearForm(event);
              setModalOpen(false);
            }}
            color="secondary">
            Cancelar
          </Button>
          <Button tabIndex={106} color="primary" onClick={() => document.querySelector("#btnFormSubmit").click()}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
