import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
`;

export const FormGroup = styled.div`
  background: #fff;

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;

  padding: 10px 20px;

  transition: 0.4s;

  ${props => (props.active ? `box-shadow: rgba(0,0,0,0.15) 0px 0px 35px 1px; position: relative; z-index: 9;` : ``)};
`;

export const Thumbnail = styled.label`
  margin-bottom: 20px;
  margin-right: 20px;

  border: 1px dashed #fcd000;

  background-color: ${props => props.bg};
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;

  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 2px 0px;

  cursor: pointer;

  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: ${props => props.height ?? `120px`};

  & input {
    display: none;
  }
  &.has-thumbnail {
    border: 1px dashed #ddd;
    &.has-thumbnail img {
      display: none;
    }
  }
`;

export const ThumbnailIcon = styled.img`
  width: 100px;
`;

export const ThumbnailInput = styled.input``;

export const Label = styled.label`
  font-family: "AvenirLTStd-Black";
  font-size: 12px;
  line-height: 19px;
  color: #100f03;
  width: 100%;
`;
