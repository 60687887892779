import React from "react";
import { Link } from "react-router-dom";

import { Container, FormGroup, Label, Input, Add } from "./styles";

import SearchIcon from "./../../assets/icons/search";

export default function Search({
  InitialValue,
  onChange,
  btnAdd = false,
  btnAddTo = "",
  btnAddIcon = "",
  btnAddtitle = "Adicionar"
}) {
  return (
    <Container>
      <FormGroup
        active={true}
        onClick={() => {
          document.querySelector("#input").focus();
        }}>
        <Label for="input">Filtrar</Label>
        <Input
          tabIndex={0}
          id="input"
          type="text"
          value={InitialValue}
          onChange={(event) => onChange(event.target.value)}
        />

        <SearchIcon styles={{ position: "absolute", right: "17px" }} />

        {btnAdd ? (
          <Link to={btnAddTo}>
            <Add>{btnAddIcon} {btnAddtitle}</Add>
          </Link>
        ) : (
          ""
        )}
      </FormGroup>
    </Container>
  );
}
