import React from "react";

export default function AddProductIcon({
  color = "#000000",
  width = 19.311,
  height = 18.96,
  styles = {},
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 19.311 18.96">
      <g transform="translate(0 -0.5)">
        <path
          d="M17.2,3.309V.5H0V17H2.458V19.46H19.311V3.309ZM.7,16.3V1.2H16.5V3.309H2.458V16.3Zm17.907,2.458H3.16V4.011H18.609Z"
          fill={color}
        />
        <path
          d="M27.076,23.362H23.564V19.851a.351.351,0,1,0-.7,0v3.511H19.351a.351.351,0,1,0,0,.7h3.511v3.511a.351.351,0,0,0,.7,0V24.064h3.511a.351.351,0,0,0,0-.7Z"
          transform="translate(-12.329 -12.329)"
          fill={color}
        />
      </g>
    </svg>
  );
}
