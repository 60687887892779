import React from "react";

export default function AddPageIcon({ color = "#000000", width = 92.308, height = 50, styles = {} }) {
  return (
    <svg class="MuiSvgIcon-root" width={width} height={height} focusable="false" viewBox="0 0 24 24" aria-hidden="true">
      <path d="M17 19.22H5V7h7V5H5c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-7h-2v7.22z" fill={color}></path>
      <path d="M19 2h-2v3h-3c.01.01 0 2 0 2h3v2.99c.01.01 2 0 2 0V7h3V5h-3V2zM7 9h8v2H7zM7 12v2h8v-2h-3zM7 15h8v2H7z" fill={color}></path>
    </svg>
  );
}
