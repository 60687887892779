import React, { useState, useContext } from "react";
import CSVReader from "react-csv-reader";
import jsonToTable from "json-to-table";
import { Table } from "semantic-ui-react";

/* import utils */
import { NotifyContext } from "./../../../util/notify";

/* import services */
import api from "./../../../services/api";

/* import components */
import SaveButton from "./../../../components/Form/Buttons/Save";
import CancelButton from "./../../../components/Form/Buttons/Cancel";

/* import styles  */
import { Container, Form, FormGroup } from "./styles";
import "./styles.css";

export default function Import({ toggleTitles }) {
  toggleTitles("Certificados", "Nova carga de dados");
  const { notify } = useContext(NotifyContext);

  const [dataLoad, setDataLoad] = useState([]);
  const [dataTable, setDataTable] = useState([]);

  const handleClear = event => {
    event.preventDefault();

    setDataLoad([]);
    setDataTable([]);

    notify.show(true, "Dados foram limpos", "info");
  };

  const handleSave = async event => {
    event.preventDefault();

    notify.show(true, "Salvando...", "info");

    try {
      await api.post("/certificates", { data: dataLoad });

      notify.show(true, "Realizando importação. Esse processo pode demorar.", "success");
      //document.location.reload();
    } catch (error) {
      console.log(error);
      notify.show(true, "Erro ao realizar importação", "error");
    }
  };

  const handleError = error => {
    notify.show(true, "Erro ao carregar arquivo", "info");
    console.log(error);
  };

  const handleForce = async (data, fileInfo) => {
    notify.show(true, `O arquivo ${fileInfo.name} foi carregado`, "info");
    await setDataLoad(data);
    showTable(data);
  };

  const showTable = data => {
    notify.show(true, `Gerando visualização dos 100 primeiros registros...`, "info");
    setDataTable(jsonToTable(data.slice(1, 101)));
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header => header.toLowerCase(),
  };

  const handleMountLines = data => {
    return (
      <Table.Row>
        {data.map(cell => (
          <Table.Cell>{cell}</Table.Cell>
        ))}
      </Table.Row>
    );
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSave}>
          <Form>
            <CSVReader cssClass="react-csv-input" label="Selecione o CSV" onError={handleError} onFileLoaded={handleForce} parserOptions={papaparseOptions} />

            <FormGroup>
              {dataTable.length > 0 ? (
                <>
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        {dataTable[0].map(column => (
                          <Table.HeaderCell>{column}</Table.HeaderCell>
                        ))}
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>{dataTable.map((column, index) => (index > 0 ? handleMountLines(column) : <></>))}</Table.Body>
                  </Table>
                </>
              ) : (
                ""
              )}
            </FormGroup>
          </Form>

          <SaveButton {...{ id: "save", text: "Importar", tabIndex: 2 }} onClick={() => {}} />
          <CancelButton {...{ id: "cancel", text: "Limpar", tabIndex: 3 }} onClick={event => handleClear(event)} />
        </form>
      </Container>
    </>
  );
}
