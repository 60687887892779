import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.nav`
  position: fixed;
  top: 0;
  left: 0;

  height: 100vh;
  width: ${props => (props.open ? props.theme.sizes.nav : props.theme.sizes.minNav)}px;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 80px 1px;

  background: #ffffff;

  transition: 0.5s;

  overflow-x: hidden;
  overflow-y: hidden;

  z-index: 9;
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 60px 0 54px 19px;

  svg {
    cursor: pointer;
  }
`;

export const Items = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  padding: 6px;
  padding-bottom: 48px;

  list-style: none;

  height: calc(100vh - 131.56px);

  overflow-x: hidden;
  overflow-y: auto;

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.25);
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.colors.primary};
  }
`;

export const Item = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  transition: 0.4s;
  width: 100%;
  padding: 10px;
  margin-bottom: 5px;

  background-color: ${props => (props.active ? `#f4f4fd` : `#ffffff`)};

  svg {
    path,
    circle {
      fill: ${props => (props.active ? props.theme.colors.primary : `#000000`)};
    }
  }

  span {
    color: ${props => (props.active ? props.theme.colors.primary : `#707070`)};
  }

  &:hover {
    background-color: #f4f4fd;

    svg {
      path,
      circle {
        fill: ${props => props.theme.colors.primary};
      }
    }
  }

  &.exit {
    justify-content: ${props => (props.navOpen ? `center` : `flex-start`)};

    position: absolute;
    bottom: -5px;
    width: 100%;
    left: 0;
    background: #100f03;

    span {
      color: #ffffff;
    }
    svg {
      path,
      circle {
        fill: #ffffff;
      }
    }
  }

  &.exit:hover {
    background: red;
  }
`;

export const Label = styled.span`
  font-family: "AvenirLTStd-Book";
  font-size: 15px;
  line-height: 15px;
  letter-spacing: 2px;

  margin-top: 5px;

  transition: 0.4s;

  margin-left: ${props => (props.navOpen ? `20px` : `-300px`)};
`;
