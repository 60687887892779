import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const Item = styled(Link)`
  border: 0.5px solid #707070;
  border-radius: 2px;
  background: ${(props) => (props.active ? "#FFF" : "transparent")};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: ${(props) => (props.active ? "11px" : "5px")};
  margin: ${(props) =>
    props.active ? "0px 10px 0px 0px" : "6px 10px 6px 6px"};

  cursor: pointer;

  transition: 0.4s;
  &:hover {
    background: #fff;
  }
`;

export const Icon = styled.div`
  width: 10px;
  height: 10px;

  border-radius: 10px;

  background-color: ${(props) => props.color};

  margin-right: 7px;
  cursor: pointer;

  transition: 0.4s;
`;

export const Label = styled.label`
  font-family: "AvenirLTStd-Roman";
  color: ${(props) => props.color};
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1px;
  cursor: pointer;

  transition: 0.4s;
`;
