import styled from "styled-components";

export const Container = styled.div`
  display: flex;

  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  width: 100%;
  min-height: 100vh;
`;

export const Main = styled.main`
  display: flex;

  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex: 1;

  width: 100%;

  transition: 0.6s;
  padding: ${(props) =>
    props.openNav
      ? `15px 15px 15px ${props.theme.sizes.nav + 25}px`
      : `15px 15px 15px ${props.theme.sizes.minNav + 25}px`};
`;
