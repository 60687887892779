import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

/* import utils */
import { NotifyContext } from "./../../../util/notify";
import { maskCurrencyReal, maskCpfCnpj } from "./../../../util/functions";

/* import services */
import api from "./../../../services/api";
import upload from "./../../../services/s3";

/* import components */
import Input from "./../../../components/Form/Input";
import Select from "./../../../components/Form/Select";
import File from "./../../../components/Form/File";

import SaveButton from "./../../../components/Form/Buttons/Save";
import CancelButton from "./../../../components/Form/Buttons/Cancel";

/* import styles  */
import { Container, Form } from "./styles";

export default function Update({ toggleTitles }) {
  const client_id = process.env.REACT_APP_CLIENT_ID;
  const site_id = process.env.REACT_APP_SITE_ID;

  toggleTitles("Agenda", "Criar novo evento");
  const { notify } = useContext(NotifyContext);
  const history = useHistory();

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [place, setPlace] = useState("");
  const [summary, setSummary] = useState("");
  const [uri, setUri] = useState("");
  const [is_active, setIs_active] = useState(true);
  const [schedule_type_id, setSchedule_type_id] = useState("");
  const [schedule_types, setSchedule_types] = useState([]);

  const [image_path, setImage_path] = useState("");
  const [images, setImages] = useState([]);

  const [componentEditor, setComponentEditor] = useState();

  const handleClear = event => {
    event.preventDefault();

    setId("");
    setName("");
    setAddress("");
    setCity("");
    setStart_date("");
    setEnd_date("");
    setPlace("");
    setSummary("");
    setIs_active(true);
    setUri("");
    setSchedule_type_id("");
    setImage_path("");

    setImages([]);

    notify.show(true, "Campos limpos", "info");
  };

  const handleSave = async event => {
    event.preventDefault();
    if (images.length <= 0) {
      notify.show(true, "Uma imagem é obrigatória", "info");
      return;
    }

    notify.show(true, "Salvando...", "info");

    const imagesUpload = await upload(client_id, site_id, images, "agenda/");
    let image;

    if (imagesUpload && imagesUpload[0].fileUrl) {
      image = imagesUpload[0].fileUrl;
    } else {
      notify.show(true, "Não foi possivel realizar o upload da imagem! Tente novamente.", "error");
      return;
    }

    try {
      await api.post("/schedule", { name, address, city, start_date, end_date, place, summary, is_active, schedule_type_id, image_path: image });
      notify.show(true, "Registro adicionado com sucesso", "success");
      document.location.reload();
    } catch (error) {
      console.log(error);
      notify.show(true, "Erro ao adicionar registro", "error");
    }
  };

  useEffect(() => {
    async function loadTypes() {
      try {
        const { data } = await api.get("/schedule/types", {});
        setSchedule_types(data);
      } catch (error) {
        notify.show(true, "Erro ao consutar tipo de agenda", "error");
      }
    }

    loadTypes();
  }, []);

  return (
    <>
      <Container>
        <form onSubmit={handleSave}>
          <Form>
            <Select
              id="schedule_type_id"
              name="schedule_type_id"
              label="Tipo de evento"
              tabIndex={0}
              initialValue={schedule_type_id}
              onChange={text => {
                setSchedule_type_id(text);
              }}
              options={[{ value: "", text: "Selecione" }, ...schedule_types.map(item => ({ value: item.id, text: item.name }))]}
              multiple={false}
              required={true}
            />

            <Input
              id="name"
              name="name"
              label="Nome do evento (importante para o SEO)"
              tabIndex={1}
              type="text"
              initialValue={name}
              onChange={text => {
                setName(text);
              }}
              required={true}
              maxLength={59}
            />

            <Input
              id="summary"
              name="summary"
              label="Chamada/Resumo (importante para o SEO)"
              tabIndex={2}
              type="text"
              initialValue={summary}
              onChange={text => {
                setSummary(text);
              }}
              required={true}
              maxLength={160}
            />

            <Input
              id="start_date"
              name="start_date"
              label="Data e hora de início"
              tabIndex={3}
              type="datetime-local"
              initialValue={start_date}
              onChange={text => {
                setStart_date(text);
              }}
              required={true}
            />

            <Input
              id="end_date"
              name="end_date"
              label="Data e hora de encerramento"
              tabIndex={4}
              type="datetime-local"
              initialValue={end_date}
              onChange={text => {
                setEnd_date(text);
              }}
            />

            <Input
              id="address"
              name="address"
              label="Endereço"
              tabIndex={5}
              type="text"
              initialValue={address}
              onChange={text => {
                setAddress(text);
              }}
            />

            <Input
              id="place"
              name="place"
              label="Local"
              tabIndex={6}
              type="text"
              initialValue={place}
              onChange={text => {
                setPlace(text);
              }}
            />

            <Input
              id="city"
              name="city"
              label="Cidade/UF"
              tabIndex={7}
              type="text"
              initialValue={city}
              onChange={text => {
                setCity(text);
              }}
            />

            <File
              id="image_path"
              initialFile={image_path}
              onChange={files => {
                setImages(files);
              }}
              label="Imagem da capa (800 x 600)"
              tabIndex={8}
              bg="#FFFFFF"
            />

            <Select
              id="is_active"
              name="is_active"
              label="Situção"
              tabIndex={9}
              initialValue={is_active}
              onChange={text => {
                setIs_active(text);
              }}
              options={[
                { value: true, text: "Ativa" },
                { value: false, text: "Inativa" },
              ]}
              multiple={false}
              required={true}
            />
          </Form>

          <SaveButton type="submit" {...{ id: "save", text: "Salvar", tabIndex: 10 }} onClick={() => {}} />
          <CancelButton {...{ id: "cancel", text: "Voltar", tabIndex: 11 }} onClick={event => history.goBack()} />
        </form>
      </Container>
    </>
  );
}
