import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "styled-components";

/* import utils */
import { filterString, maskCurrencyReal, getDate, maskCurrencyRealtoCalc } from "./../../util/functions";
import { NotifyContext } from "./../../util/notify";

/* import services */
import api from "./../../services/api";

import { useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";

/* import components */
import Legend from "./../../components/Legend";
import Search from "./../../components/Search";
import Input from "./../../components/Form/Input";
import Select from "./../../components/Form/Select";

/* import icons */
import AddProductIcon from "./../../assets/icons/addProduct";

/* import styles */
import { Container, Cards, Card, Title, Subtitle, Comment, AddButton, FormModal, FormGroup } from "./styles";

export default function ScheduleType({ toggleTitles }) {
  toggleTitles("Tipos de agenda", "Gerenciamento dos tipos de agenda");

  const { status: colors } = useContext(ThemeContext);
  const { notify } = useContext(NotifyContext);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [modalOpen, setModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchStatus, setSearchStatus] = useState("");

  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);

  const [titleModal, setTitleModal] = useState("Editar tipo");

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [color, setColor] = useState("");
  const [is_active, setIs_active] = useState(true);

  const handleFilter = async text => {
    const filtered = initialData.filter(
      item =>
        filterString(item.is_active ?? false, (searchStatus === "inative" ? false : true) ?? "") &&
        (filterString(item.id, text) || filterString(item.name, text) || filterString(item.color, text)),
    );
    setData(filtered);
  };

  const handleClearForm = event => {
    event.preventDefault();

    setTitleModal("Editar tipo");

    setId("");
    setName("");
    setColor("");
    setIs_active(true);
  };

  const handleOpenModalEdit = async (p_id, p_name, p_color, p_is_active) => {
    setTitleModal("Editar tipo");

    setId(p_id);
    setName(p_name);
    setColor(p_color);
    setIs_active(p_is_active);
    setModalOpen(true);
  };

  const handleSave = async event => {
    event.preventDefault();

    try {
      await api.put(`/schedule/types/${id}`, { name, color, is_active });
      notify.show(true, "Registro atualizado com sucesso", "success");

      loadData();
      setModalOpen(false);
    } catch (error) {
      console.log(error);
      notify.show(true, "Erro ao salvar registro! Tente novamente", "error");
    }
  };

  async function loadData() {
    try {
      const { data } = await api.get(`/schedule/types/panel`, {});
      await setInitialData(data);
      await setData(data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleFilter(searchValue);
  }, [searchStatus]);

  useEffect(() => {
    loadData();
    document.querySelector("#input").focus();
  }, []);

  return (
    <>
      <Container>
        <Legend
          active={searchStatus}
          onClick={status => {
            setSearchStatus(status);
          }}
          items={[
            {
              key: "status",
              value: "active",
              title: "Filtrar Ativos",
              color: colors["active"],
            },
            {
              key: "status",
              value: "inative",
              title: "Filtrar Inativos",
              color: colors["inative"],
            },
          ]}
        />

        <Cards>
          {data.map(({ id, name, color, is_active }) => {
            return (
              <Card
                onClick={() => {
                  handleOpenModalEdit(id, name, color, is_active);
                }}
                status={color}
                key={id}>
                <Title>{name}</Title>
                {/*<Subtitle>{uri}</Subtitle>*/}
                <Comment>{is_active ? "ATIVO" : "DESATIVADO"}</Comment>
              </Card>
            );
          })}
        </Cards>

        <Search
          InitialValue={searchValue}
          onChange={text => {
            setSearchValue(text);
            handleFilter(text);
          }}
        />
      </Container>

      <Dialog
        fullScreen={fullScreen}
        open={modalOpen}
        onClose={event => {
          handleClearForm(event);
          setModalOpen(false);
        }}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{titleModal}</DialogTitle>
        <DialogContent>
          <FormModal onSubmit={handleSave}>
            <FormGroup>
              <Input
                id="name"
                name="name"
                label="Nome"
                tabIndex={101}
                type="text"
                initialValue={name}
                onChange={text => {
                  setName(text);
                }}
                required={true}
              />

              <Select
                id="is_active"
                name="is_active"
                label="Situção"
                tabIndex={102}
                initialValue={is_active}
                onChange={text => {
                  setIs_active(text);
                }}
                options={[
                  { value: "", text: "Selecione" },
                  { value: true, text: "Ativo" },
                  { value: false, text: "Inativo" },
                ]}
                multiple={false}
                required={true}
              />

              <Input
                id="color"
                name="color"
                label="Cor"
                tabIndex={103}
                type="text"
                initialValue={color}
                onChange={text => {
                  setColor(text);
                }}
                required={true}
              />
            </FormGroup>

            <FormGroup>
              <br />
              <br />
              <br />
              <Comment>Use o site da adobe para encontrar a cor e use a cor hexadecimal ex: #81a81d</Comment>
            </FormGroup>
            <FormGroup>
              <br />
              <br />
              <a href="https://color.adobe.com/pt/create/color-wheel" target="_blank">
                <Comment>ACESSAR SITE</Comment>
              </a>
            </FormGroup>

            <button id="btnFormSubmit" style={{ display: "none" }}>
              enviar
            </button>
          </FormModal>
        </DialogContent>

        <DialogActions>
          <Button
            tabIndex={108}
            onClick={event => {
              handleClearForm(event);
              setModalOpen(false);
            }}
            color="secondary">
            Cancelar
          </Button>
          <Button tabIndex={107} color="primary" onClick={() => document.querySelector("#btnFormSubmit").click()}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
