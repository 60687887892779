import { createGlobalStyle } from "styled-components";

import AvenirLTStdBook from "./../fonts/AvenirLTStd-Book.woff";
import AvenirLTStdBlack from "./../fonts/AvenirLTStd-Black.woff";
import AvenirLTStdRoman from "./../fonts/AvenirLTStd-Roman.woff";
import ChunkFiveEx from "./../fonts/ChunkFiveEx.woff";

export default createGlobalStyle`

  @font-face {
      font-family: 'AvenirLTStd-Book';
      src: url(${AvenirLTStdBook}) format('woff');
      font-weight: normal;
      font-style: normal;
  }

  @font-face {
      font-family: 'AvenirLTStd-Black';
      src: url(${AvenirLTStdBlack}) format('woff');
      font-weight: normal;
      font-style: normal;
  }

  @font-face {
      font-family: 'AvenirLTStd-Roman';
      src: url(${AvenirLTStdRoman}) format('woff');
      font-weight: normal;
      font-style: normal;
  }

  @font-face {
    font-family: 'ChunkFiveEx';
    src: url(${ChunkFiveEx}) format('woff');
    font-weight: normal;
    font-style: normal;
  }


  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: none;
  }

  a{text-decoration: none}

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
      -webkit-text-fill-color: #707070 !important;
  }

  body{
    background: ${(props) => props.theme.colors.appBackground};

    font-family: sans-serif;
    font-size: 14px;
    color: #707070;

    max-height: 100vh;
    overflow: hidden;


    #root{
      display: flex;
      align-items: center;
      justify-content: center;

      min-height: 100vh;
    }
  }

  /* width */
  div::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  div::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  div::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.25);
  }

  /* Handle on hover */
  div::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.primary};
  }
`;
