export const TOKEN_API = "TOKEN_API";
export const PROFILE_ID = "PROFILE_ID";
export const USER_NAME = "USER_NAME";
export const USER_ID = "USER_ID";
export const USER_EMAIL = "USER_EMAIL";
export const USER_MODULES = "USER_MODULES";

export const isAuthenticated = () => localStorage.getItem(TOKEN_API) !== null;
export const getToken = () => localStorage.getItem(TOKEN_API);
export const getModules = () => localStorage.getItem(USER_MODULES).split(",");

export const login = (token, id, email, profile_id, name, modules) => {
  localStorage.setItem(TOKEN_API, token);
  localStorage.setItem(PROFILE_ID, profile_id);
  localStorage.setItem(USER_NAME, name);
  localStorage.setItem(USER_ID, id);
  localStorage.setItem(USER_EMAIL, email);
  localStorage.setItem(USER_MODULES, modules);
};

export const logout = history => {
  localStorage.removeItem(TOKEN_API);
  localStorage.removeItem(PROFILE_ID);
  localStorage.removeItem(USER_NAME);
  localStorage.removeItem(USER_ID);
  localStorage.removeItem(USER_EMAIL);
  localStorage.removeItem(USER_MODULES);

  history.go("/login");
};
