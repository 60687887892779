import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "styled-components";
import { CSVLink } from "react-csv";

/* import utils */
import { filterString, maskCurrencyReal, getDate, getDate2, maskCurrencyRealtoCalc } from "./../../util/functions";
import { NotifyContext } from "./../../util/notify";

/* import services */
import api from "./../../services/api";
import upload from "./../../services/s3";

import { useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";

/* import components */
import Legend from "./../../components/Legend";
import Search from "./../../components/Search";
import Input from "./../../components/Form/Input";
import Select from "./../../components/Form/Select";
import FormSelectMultiple from "./../../components/Form/SelectMultiple";
import File from "./../../components/Form/File";

/* import icons */
import IncomeIcon from "./../../assets/icons/income";

/* import styles */
import { Container, Cards, Card, Title, Subtitle, Comment, AddButton, FormModal, FormGroup } from "./styles";

export default function Registrations({ toggleTitles }) {
  toggleTitles("Cadastros", "Atualizações cadastrais pelo site");

  const { status: colors } = useContext(ThemeContext);
  const { notify } = useContext(NotifyContext);

  const [modalOpen, setModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchStatus, setSearchStatus] = useState("");

  const [headers, setHeaders] = useState([
    { label: "ID", key: "id" },
    { label: "Sigla", key: "category" },
    { label: "Categoria", key: "category_name" },
    { label: "CRO", key: "cro" },
    { label: "Nome", key: "name" },
    { label: "CPF", key: "cpf" },
    { label: "Telefone", key: "tel" },
    { label: "Celular", key: "cell" },
    { label: "Data de Nasc.", key: "date_birth" },
    { label: "Endereco Residencial", key: "home_address" },
    { label: "Numero Endereco Residencial", key: "home_address_number" },
    { label: "CEP Endereco Residencial", key: "home_address_cep" },
    { label: "Cidade Endereco Residencial", key: "home_address_city" },
    { label: "UF Endereco Residencial", key: "home_address_state" },
    { label: "Bairro Endereco Residencial", key: "home_address_neighborhood" },
    { label: "Tel Endereco Residencial", key: "home_address_tel" },
    { label: "E-mail Endereco Residencial", key: "home_address_email" },
    { label: "Endereco Comercial no Residencial?", key: "business_in_home" },
    { label: "Endereco Comercial", key: "business_address" },
    { label: "Numero Endereco Comercial", key: "business_address_number" },
    { label: "CEP Endereco Comercial", key: "business_address_cep" },
    { label: "Cidade Endereco Comercial", key: "business_address_city" },
    { label: "UF Endereco Comercial", key: "business_address_state" },
    { label: "Bairro Endereco Comercial", key: "business_address_neighborhood" },
    { label: "Tel Endereco Comercial", key: "business_address_tel" },
    { label: "E-mail Endereco Comercial", key: "business_address_email" },
    { label: "Endereco p/ correspondecia", key: "mailing_address" },
    { label: "Criado em", key: "created_at" },
  ]);
  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);

  async function loadData() {
    try {
      let { data } = await api.get(`/registrations`, { headers: { pagenumber: 1, pagelimit: 1000000 } });

      data = data.data.map(item => {
        if (item.business_in_home === true) {
          item.business_in_home = "SIM";
        } else {
          item.business_in_home = "NAO";
        }
        if (item.mailing_address === "business") {
          item.mailing_address = "Comercial";
        } else {
          item.mailing_address = "Residencial";
        }

        return item;
      });

      await setData(data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Container>
        <Cards>
          {data.map(({ id, name, cpf, cro, created_at }) => {
            return (
              <Card key={id}>
                <Title>
                  {name.toString().toUpperCase()}
                  <br />
                  <Comment>CPF: {cpf}</Comment>
                  <br />
                  <Comment>CRO: {cro}</Comment>
                </Title>
                <Comment>{getDate(created_at, "dd'/'MM'/'yyyy' 'HH':'mm")}</Comment>
              </Card>
            );
          })}
        </Cards>

        <CSVLink filename={`Atualizacao-cadastral-${getDate2(new Date(), "dd'-'MM'-'yyyy'-'HH'h-'mm'm'")}.csv`} data={data} headers={headers}>
          <AddButton>
            <IncomeIcon styles={{ transform: "rotate(-180deg)" }} />
            Baixar Excel
          </AddButton>
        </CSVLink>
      </Container>
    </>
  );
}
