import React, { useState, useEffect } from "react";

import Avatar from "./../Avatar";
import Nav from "./../Nav";

import avatar from "./../../assets/images/avatar.png";

import { Container, Content, Title, SubTitle } from "./styles";

export default function Header({ openNav, toggleNav, title, subTitle }) {
  const [userName, setUserName] = useState("");

  useEffect(() => {
    async function loadUser() {
      setUserName(await localStorage.getItem("USER_NAME"));
    }

    loadUser();
  }, []);

  return (
    <Container open={openNav}>
      <Nav handleOpen={toggleNav} isOpened={openNav} />

      <Content>
        <Title>{title}</Title>
        <SubTitle>{subTitle}</SubTitle>
      </Content>

      <Avatar name={userName} work="CRO-MT" image={avatar} />
    </Container>
  );
}
