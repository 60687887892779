import React, { useState, useEffect, useContext } from "react";

import { ThemeContext } from "styled-components";

/* import services */
import api from "./../../services/api";

/* import utils */
import { filterString, getDateByTimeZoneCba } from "./../../util/functions";
import { NotifyContext } from "./../../util/notify";

import Legend from "./../../components/Legend";

import { Grid, Button, Input } from "@material-ui/core";

import { useHistory } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

/* import styles */
import { Container } from "./styles";
import { format, parseISO } from "date-fns";

export default function Contact({ toggleTitles }) {
  toggleTitles("Notícias", "Gestão de notícias");

  const { status: colors } = useContext(ThemeContext);
  const { notify } = useContext(NotifyContext);
  const history = useHistory();

  const [searchValue, setSearchValue] = useState("");
  const [searchStatus, setSearchStatus] = useState("");

  const [noticias, setNoticias] = useState([]);
  const [initialData, setInitialData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(true);

  const handleFilter = async text => {
    const filtered = initialData.filter(
      item =>
        filterString(item.is_active ?? false, (searchStatus === "inative" ? false : true) ?? "") &&
        (filterString(item.id, text) ||
          filterString(item.title, text) ||
          filterString(item.summary ?? "", text) ||
          filterString(item.uri ?? "", text) ||
          filterString(getDateByTimeZoneCba(item.date, "dd'/'MM'/'yyyy"), text)),
    );
    setNoticias(filtered);
  };

  useEffect(() => {
    handleFilter(searchValue);
  }, [searchStatus]);

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      notify.show(true, "Carregando", "info");
      try {
        await api.get(`/news/panel`).then(({ data }) => {
          setInitialData(data);
          setNoticias(data);
          console.log(data);
          setLoading(false);
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
      notify.show(false, "Carregando", "info");
    }

    loadData();
  }, [load]);

  const [open, setOpen] = useState(false);
  const [newId, setNewId] = useState(null);

  const deleteNew = async () => {
    setLoading(true);
    try {
      await api.delete(`/news/${newId}`).then(({ data }) => {
        setLoading(false);
        setLoad(!load);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    handleClose();
  };

  const handleClickOpen = id => {
    setNewId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container>
      <Grid container spacing={3} style={{ margin: "1em 0" }}>
        <Grid item xs={4}>
          <Legend
            active={searchStatus}
            onClick={status => {
              setSearchStatus(status);
            }}
            items={[
              {
                key: "status",
                value: "active",
                title: "Filtrar Ativos",
                color: colors["active"],
              },
              {
                key: "status",
                value: "inative",
                title: "Filtrar Inativos",
                color: colors["inative"],
              },
            ]}
          />
        </Grid>
        <Grid item xs={4}>
          <Input
            style={{ width: "100%" }}
            placeholder="Pesquisar..."
            onChange={event => {
              setSearchValue(event.target.value);
              handleFilter(event.target.value);
            }}
            value={searchValue}
          />
        </Grid>
        <Grid item xs={4}>
          <Button variant="outlined" color="primary" onClick={() => history.push("/noticia/nova")}>
            Nova Notícia
          </Button>
        </Grid>
      </Grid>

      <DataGrid
        className="bgBranco"
        rows={noticias}
        loading={loading}
        columns={[
          { field: "title", headerName: "Título", flex: 1 },
          { field: "summary", headerName: "Descrição", resizable: true, flex: 2 },
          { field: "date", flex: 0.5, headerName: "Data", valueFormatter: params => format(parseISO(params.value), "dd/MM/yyyy") },
          {
            field: "id",
            headerName: "Ação",
            flex: 0.5,
            renderCell: params => {
              return (
                <>
                  <Button
                    variant="outlined"
                    style={{ marginRight: 4 }}
                    color="primary"
                    onClick={() => {
                      history.push(`/noticia/editar/${params.id}`);
                    }}>
                    Editar
                  </Button>
                  <Button
                    disabled={!params.row.is_active}
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      console.log(params);
                      handleClickOpen(params.id);
                    }}>
                    Deletar
                  </Button>
                </>
              );
            },
          },
        ]}
        // onRowClick={row => history.push(`/noticia/editar/${row.row.id}`)}
        pageSize={15}
        pagination
      />
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Deseja deletar essa noticia?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">A notícia será desativa, mas pudera ser ativa na página de edição.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={deleteNew} color="secondary" autoFocus>
            Deletar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
