import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";

/* import utils */
import { getDate } from "./../../../util/functions";
import { NotifyContext } from "./../../../util/notify";

/* import services */
import api from "./../../../services/api";

/* import components */

/* import icons */

/* import styles */
import { Container, Cards, Card, Title, Comment, Checkbox } from "./styles";

export default function Present({ toggleTitles }) {
  const { course_id } = useParams();

  const { notify } = useContext(NotifyContext);

  const [searchValue, setSearchValue] = useState("");
  const [searchStatus, setSearchStatus] = useState("");

  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);

  async function loadData() {
    try {
      let { data } = await api.get(`/courses/${course_id}/subscribes`, { headers: { pagenumber: 1, pagelimit: 1000000 } });
      await setData(data.course_subscribes);
      toggleTitles("Cursos", `Marcação de presença - ${data.name} `);
    } catch (error) {
      console.log(error);
    }
  }

  async function setPresent(site_user_id, present) {
    try {
      await api.post(`/courses/${course_id}/${site_user_id}/present/${present}`, {});
      notify.show(true, "Atualizado", "success");
      loadData();
    } catch (error) {
      notify.show(true, "Erro ao definir presença! Tente novamente", "error");
      console.log(error);
      loadData();
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Container>
        <Cards>
          {data.map(({ id, site_user, created_at, present }) => {
            return (
              <Card key={id}>
                <Title>
                  {site_user.name.toString().toUpperCase()}
                  <br />
                  <Comment>CPF: {site_user.cpf}</Comment>
                  <br />
                  <Comment>Inscrição realizada em: {getDate(created_at, "dd'/'MM'/'yyyy' às 'HH':'mm")}</Comment>
                </Title>
                <Comment>
                  <Checkbox type="checkbox" checked={present} onChange={event => setPresent(site_user.id, event.target.checked)} />
                </Comment>
              </Card>
            );
          })}
        </Cards>
      </Container>
    </>
  );
}
