import React, { useState, useContext, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import CSVReader from "react-csv-reader";
import jsonToTable from "json-to-table";
import { Icon, Label, Menu, Table } from "semantic-ui-react";

/* import utils */
import { NotifyContext } from "./../../../util/notify";

/* import services */
import api from "./../../../services/api";

/* import components */
import SaveButton from "./../../../components/Form/Buttons/Save";
import CancelButton from "./../../../components/Form/Buttons/Cancel";

/* import styles  */
import { Container, Form, FormGroup } from "./styles";
import "./styles.css";

export default function Import({ toggleTitles }) {
  toggleTitles("Certificados", "Histórico de integração");
  const { notify } = useContext(NotifyContext);
  const history = useHistory();
  const { certificate_update_history_id } = useParams();

  const [dataTable, setDataTable] = useState([]);

  const showTable = data => {
    notify.show(true, `Gerando visualização dos registros...`, "info");
    setDataTable(jsonToTable(data));
  };

  const handleMountLines = data => {
    return (
      <Table.Row>
        {data.map(cell => (
          <Table.Cell>{cell}</Table.Cell>
        ))}
      </Table.Row>
    );
  };

  useEffect(() => {
    async function loadHistory() {
      try {
        const { data } = await api.get(`/certificates/panel/${certificate_update_history_id}`, {});
        showTable(data);
      } catch (error) {
        console.log(error);
        notify.show(true, `Erro ao buscar histórico`, "error");
        history.goBack();
      }
    }

    if (!certificate_update_history_id) {
      notify.show(true, `Erro ao buscar histórico`, "error");
      history.goBack();
    }

    notify.show(true, `Consultando registros ...`, "info");
    loadHistory();
  }, []);

  return (
    <>
      <Container>
        <form>
          <Form>
            <FormGroup>
              {dataTable.length > 0 ? (
                <>
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        {dataTable[0].map(column => (
                          <Table.HeaderCell>{column}</Table.HeaderCell>
                        ))}
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>{dataTable.map((column, index) => (index > 0 ? handleMountLines(column) : <></>))}</Table.Body>
                  </Table>
                </>
              ) : (
                ""
              )}
            </FormGroup>
          </Form>

          <CancelButton {...{ id: "cancel", text: "Voltar", tabIndex: 1 }} onClick={event => history.goBack()} />
        </form>
      </Container>
    </>
  );
}
