import React from "react";

import { Button } from "./styles";

import SaveButtonIcon from "./../../../../assets/icons/saveButton";

export default function Save({ id, text, tabIndex, onClick, type = "button" }) {
  return (
    <Button style={{ zIndex: 1000 }} type={type} tabIndex={tabIndex} id={id} onClick={() => onClick()}>
      <SaveButtonIcon width={21} height={21} /> {text}
    </Button>
  );
}
