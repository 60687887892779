import React from "react";
import MultiSelect from "@khanacademy/react-multi-select";

import { Container, FormGroup, Label } from "./styles";

export default function FormSelectMultiple({ id, name, initialValue, onChange, label, tabIndex, required = false, readOnly = false, options = [] }) {
  return (
    <Container>
      <FormGroup>
        <Label for={`${id}`}>{label}</Label>

        <MultiSelect
          required={required}
          tabIndex={tabIndex}
          disabled={readOnly}
          id={`${id}`}
          selected={initialValue}
          onSelectedChanged={selected => {
            onChange(selected);
          }}
          options={options}
          overrideStrings={{
            selectSomeItems: "Selecione...",
            allItemsAreSelected: "Todos os itens estão selecionados",
            selectAll: "Selecionar todos",
            search: "Procurar",
          }}
        />
      </FormGroup>
    </Container>
  );
}
