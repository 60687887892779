import React from "react";

export default function AddUserIcon({
  color = "#000000",
  width = 92.308,
  height = 50,
  styles = {},
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 92.308 50">
      <g transform="translate(0 -140.25)">
        <g transform="translate(0 140.25)">
          <path
            d="M30.769,159.481H19.231V147.942H11.538v11.538H0v7.692H11.538v11.538h7.692V167.173H30.769Zm38.462,3.846a11.538,11.538,0,1,0,0-23.077c-1.154,0-2.308.385-3.462.385a19.7,19.7,0,0,1,0,22.308C66.923,162.942,68.077,163.327,69.231,163.327Zm-19.231,0a11.538,11.538,0,1,0-11.538-11.538A11.333,11.333,0,0,0,50,163.327Zm25.385,8.462c3.077,2.692,5.385,6.539,5.385,10.769v7.692H92.308v-7.692C92.308,176.788,83.077,172.942,75.385,171.788ZM50,171.019c-7.692,0-23.077,3.846-23.077,11.538v7.692H73.077v-7.692C73.077,174.865,57.692,171.019,50,171.019Z"
            transform="translate(0 -140.25)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}
