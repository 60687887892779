import styled from "styled-components";
import { Link } from "react-router-dom";

import EditIcon from "@material-ui/icons/Edit";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import CardMembership from "@material-ui/icons/CardMembership";
import DoneAll from "@material-ui/icons/DoneAll";
import CloudDownload from "@material-ui/icons/CloudDownload";

export const Container = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;

  position: relative;
  flex: 1;
`;

export const Cards = styled.div`
  flex: 1;

  padding-bottom: 65px;
  padding-top: 15px;
  padding-right: 5px;

  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr;

  max-height: calc(100vh - 300px);
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  transition: 0.4s;

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.25);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.colors.primary};
  }

  @media (max-width: 1366px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const Card = styled.div`
  background-color: #ffffff;

  border-bottom: 2px solid ${props => props.status};

  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 7px 1px;

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  transition: 0.4s;

  border-radius: 6px;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 18px 29px 1px;
  }
`;

export const Figure = styled.figure`
  width: 100%;
  height: 150px;
  position: relative;
  overflow: hidden;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
`;

export const Title = styled.h2`
  font-family: "AvenirLTStd-Book";
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 2px;
  color: ${props => props.theme.colors.primary};

  padding: 10px 15px;
`;

export const Subtitle = styled.p`
  font-family: "AvenirLTStd-Book";
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1px;
  color: #707070;
  text-align: justify;

  padding: 10px 15px;
`;

export const Comment = styled.span`
  font-family: "AvenirLTStd-Book";
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0px;
  color: #000;

  padding: 0 15px 15px;
`;

export const FormModal = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Actions = styled.div`
  display: flex;
  width: 170px;
  justify-content: space-around;
  align-items: center;
  align-self: flex-start;
  justify-self: flex-end;
  padding-top: 7px;
  content: close-quote;
  line-height: 0;

  position: absolute;
  right: 5px;
  top: 0;
`;

export const ActionEditIcon = styled(EditIcon)`
  color: #a4afb7;
  cursor: pointer;
  width: 0.9em !important;
  height: 0.9em !important;
  &:hover {
    color: #fcd000;
  }
`;

export const ActionPresentIcon = styled(VerifiedUser)`
  color: #a4afb7;
  cursor: pointer;
  width: 0.9em !important;
  height: 0.9em !important;
  &:hover {
    color: #000;
  }
`;

export const ActionCardMembershipIcon = styled(CardMembership)`
  color: #a4afb7;
  cursor: pointer;
  width: 0.9em !important;
  height: 0.9em !important;
  &:hover {
    color: #fcd000;
  }
`;

export const ActionDoneAllIcon = styled(DoneAll)`
  color: #a4afb7;
  cursor: pointer;
  width: 0.9em !important;
  height: 0.9em !important;
  &:hover {
    color: #6f130d;
  }
`;

export const ActionCloudDownloadIcon = styled(CloudDownload)`
  color: #a4afb7;
  cursor: pointer;
  width: 0.9em !important;
  height: 0.9em !important;
  &:hover {
    color: #fcd000;
  }
`;
