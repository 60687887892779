import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "styled-components";

/* import utils */
import { filterString, maskCurrencyReal, getDate, maskCurrencyRealtoCalc } from "./../../util/functions";
import { NotifyContext } from "./../../util/notify";

/* import services */
import api from "./../../services/api";

import { useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";

/* import components */
import Legend from "./../../components/Legend";
import Search from "./../../components/Search";
import Input from "./../../components/Form/Input";
import Select from "./../../components/Form/Select";

/* import icons */
import AddProductIcon from "./../../assets/icons/addProduct";

/* import styles */
import { Container, Cards, Card, Title, Subtitle, Comment, AddButton, FormModal, FormGroup } from "./styles";

export default function SiteConfig({ toggleTitles }) {
  toggleTitles("Configurações", "Parametrização do site");

  const { status: colors } = useContext(ThemeContext);
  const { notify } = useContext(NotifyContext);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [modalOpen, setModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchStatus, setSearchStatus] = useState("");

  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);

  const [titleModal, setTitleModal] = useState("Parametrização");
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [business_hours, setBusiness_hours] = useState("");
  const [address, setAddress] = useState("");
  const [phones, setPhones] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [whatsapp_text, setWhatsapp_text] = useState("");
  const [emails, setEmails] = useState("");
  const [facebook_page, setFacebook_page] = useState("");
  const [instagram_page, setInstagram_page] = useState("");
  const [youtube_page, setYoutube_page] = useState("");
  const [twitter_page, setTwitter_page] = useState("");
  const [linkedin_page, setLinkedin_page] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [google_maps_street_view, setGoogle_maps_street_view] = useState("");
  const [president_name, setPresident_name] = useState("");
  const [president_number_cro, setPresident_number_cro] = useState("");
  const [certificate_validity_day, setCertificate_validity_day] = useState("");
  const [amount_specialization_certificate, setAmount_specialization_certificate] = useState("");
  const [contact_email, setContact_email] = useState("");
  const [registration_email, setRegistration_email] = useState("");
  const [partner_email, setPartner_email] = useState("");
  const [ads_email, setAds_email] = useState("");

  const handleFilter = async text => {
    const filtered = initialData.filter(item => filterString(item.id, text) || filterString(item.name, text));
    setData(filtered);
  };

  const handleSave = async event => {
    event.preventDefault();

    try {
      await api.put(`/site_config/${id}`, {
        name,
        business_hours,
        address,
        phones,
        emails,
        facebook_page,
        instagram_page,
        youtube_page,
        twitter_page,
        linkedin_page,
        latitude,
        longitude,
        google_maps_street_view,
        president_name,
        president_number_cro,
        certificate_validity_day,
        amount_specialization_certificate,
        contact_email,
        registration_email,
        partner_email,
        ads_email,
        whatsapp,
        whatsapp_text,
      });
      notify.show(true, "Registro atualizado com sucesso", "success");

      loadData();
      setModalOpen(true);
    } catch (error) {
      console.log(error);
      notify.show(true, "Erro ao salvar registro! Tente novamente", "error");
    }
  };

  async function loadData() {
    notify.show(true, "Buscando registros", "info");

    try {
      const { data } = await api.get(`/site_config/1`, {});
      await setInitialData(data);
      await setData(data);

      setId(data.id);
      setName(data.name);
      setBusiness_hours(data.business_hours);
      setAddress(data.address);
      setPhones(data.phones);
      setEmails(data.emails);
      setFacebook_page(data.facebook_page);
      setInstagram_page(data.instagram_page);
      setYoutube_page(data.youtube_page);
      setTwitter_page(data.twitter_page);
      setLinkedin_page(data.linkedin_page);
      setLatitude(data.latitude);
      setLongitude(data.longitude);
      setGoogle_maps_street_view(data.google_maps_street_view);
      setPresident_name(data.president_name);
      setPresident_number_cro(data.president_number_cro);
      setCertificate_validity_day(data.certificate_validity_day);
      setAmount_specialization_certificate(data.amount_specialization_certificate);
      setContact_email(data.contact_email);
      setRegistration_email(data.registration_email);
      setPartner_email(data.partner_email);
      setAds_email(data.ads_email);
      setWhatsapp(data.whatsapp);
      setWhatsapp_text(data.whatsapp_text);

      setModalOpen(true);
      notify.show(false, "Buscando registros", "info");
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleFilter(searchValue);
  }, [searchStatus]);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Container>
        {/*<Legend
          active={searchStatus}
          onClick={status => {
            setSearchStatus(status);
          }}
          items={[
            {
              key: "status",
              value: "active",
              title: "Filtrar Ativos",
              color: colors["active"],
            },
            {
              key: "status",
              value: "inative",
              title: "Filtrar Inativos",
              color: colors["inative"],
            },
          ]}
        />*/}

        <Cards>
          <Card key={id} onClick={() => setModalOpen(true)}>
            {/*status={colors[is_active ? "active" : "inative"]}*/}
            <Title>Editar parametrização única</Title>
            {/*<Subtitle>{uri}</Subtitle>
                <Comment>{navigation.name.toUpperCase()}</Comment>*/}
          </Card>
        </Cards>

        <Search
          InitialValue={searchValue}
          onChange={text => {
            setSearchValue(text);
            handleFilter(text);
          }}
        />
      </Container>

      <Dialog
        fullScreen={fullScreen}
        open={modalOpen}
        onClose={event => {
          setModalOpen(false);
        }}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{titleModal}</DialogTitle>
        <DialogContent>
          <FormModal onSubmit={handleSave}>
            <FormGroup>
              <Input
                id="business_hours"
                name="business_hours"
                label="Horário comercial"
                tabIndex={101}
                type="text"
                initialValue={business_hours}
                onChange={text => {
                  setBusiness_hours(text);
                }}
                required={true}
              />

              <Input
                id="address"
                name="address"
                label="Endereço"
                tabIndex={102}
                type="text"
                initialValue={address}
                onChange={text => {
                  setAddress(text);
                }}
                required={true}
              />

              <Input
                id="phones"
                name="phones"
                label="Telefones (separados por barra / )"
                tabIndex={103}
                type="text"
                initialValue={phones}
                onChange={text => {
                  setPhones(text);
                }}
              />

              <Input
                id="whatsapp"
                name="whatsapp"
                label="WhatsApp"
                tabIndex={104}
                type="text"
                initialValue={whatsapp}
                onChange={text => {
                  setWhatsapp(text);
                }}
              />

              <Input
                id="whatsapp"
                name="whatsapp"
                label="Texto padrão whatsapp"
                tabIndex={104}
                type="text"
                initialValue={whatsapp_text}
                onChange={text => {
                  setWhatsapp_text(text);
                }}
              />

              <Input
                id="emails"
                name="emails"
                label="E-mails (separados por ponto e vírgula ; )"
                tabIndex={104}
                type="text"
                initialValue={emails}
                onChange={text => {
                  setEmails(text);
                }}
                required={true}
              />

              <Input
                id="contact_email"
                name="contact_email"
                label="E-mail formulário de contato"
                tabIndex={105}
                type="text"
                initialValue={contact_email}
                onChange={text => {
                  setContact_email(text);
                }}
                required={true}
              />

              <Input
                id="registration_email"
                name="registration_email"
                label="E-mail formulário de Atualização"
                tabIndex={106}
                type="text"
                initialValue={registration_email}
                onChange={text => {
                  setRegistration_email(text);
                }}
                required={true}
              />

              <Input
                id="partner_email"
                name="partner_email"
                label="E-mail formulário de Parceiros"
                tabIndex={107}
                type="text"
                initialValue={partner_email}
                onChange={text => {
                  setPartner_email(text);
                }}
                required={true}
              />

              <Input
                id="ads_email"
                name="ads_email"
                label="E-mail formulário de Anúncios"
                tabIndex={108}
                type="text"
                initialValue={ads_email}
                onChange={text => {
                  setAds_email(text);
                }}
                required={true}
              />

              <Input
                id="president_name"
                name="president_name"
                label="Nome do Presidente"
                tabIndex={109}
                type="text"
                initialValue={president_name}
                onChange={text => {
                  setPresident_name(text);
                }}
                required={true}
              />

              <Input
                id="president_number_cro"
                name="president_number_cro"
                label="Nº CRO do Presidente"
                tabIndex={110}
                type="text"
                initialValue={president_number_cro}
                onChange={text => {
                  setPresident_number_cro(text);
                }}
                required={true}
              />

              <Input
                id="certificate_validity_day"
                name="certificate_validity_day"
                label="Validade dos certificados (dias)"
                tabIndex={111}
                type="number"
                minValue={1}
                initialValue={certificate_validity_day}
                onChange={text => {
                  setCertificate_validity_day(text);
                }}
                onKeyUp={text => {
                  setCertificate_validity_day(text);
                }}
                required={true}
              />

              <Input
                id="amount_specialization_certificate"
                name="amount_specialization_certificate"
                label="Qtde de especializações nos certificados"
                tabIndex={112}
                type="number"
                minValue={1}
                maxValue={10}
                initialValue={amount_specialization_certificate}
                onChange={text => {
                  setAmount_specialization_certificate(text);
                }}
                onKeyUp={text => {
                  setAmount_specialization_certificate(text);
                }}
                required={true}
              />

              <Input
                id="facebook_page"
                name="facebook_page"
                label="Link página no facebook"
                tabIndex={113}
                type="text"
                initialValue={facebook_page}
                onChange={text => {
                  setFacebook_page(text);
                }}
              />

              <Input
                id="instagram_page"
                name="instagram_page"
                label="Link página no instagram"
                tabIndex={114}
                type="text"
                initialValue={instagram_page}
                onChange={text => {
                  setInstagram_page(text);
                }}
              />

              <Input
                id="youtube_page"
                name="youtube_page"
                label="Link canal no youtube"
                tabIndex={115}
                type="text"
                initialValue={youtube_page}
                onChange={text => {
                  setYoutube_page(text);
                }}
              />

              <Input
                id="twitter_page"
                name="twitter_page"
                label="Link perfil no twitter"
                tabIndex={116}
                type="text"
                initialValue={twitter_page}
                onChange={text => {
                  setTwitter_page(text);
                }}
              />

              <Input
                id="linkedin_page"
                name="linkedin_page"
                label="Link no linkedin"
                tabIndex={117}
                type="text"
                initialValue={linkedin_page}
                onChange={text => {
                  setLinkedin_page(text);
                }}
              />

              <Input
                id="latitude"
                name="latitude"
                label="Latitude (mapa)"
                tabIndex={118}
                type="text"
                initialValue={latitude}
                onChange={text => {
                  setLatitude(text);
                }}
                required={true}
              />

              <Input
                id="longitude"
                name="longitude"
                label="Longitude (mapa)"
                tabIndex={119}
                type="text"
                initialValue={longitude}
                onChange={text => {
                  setLongitude(text);
                }}
                required={true}
              />
            </FormGroup>

            <button id="btnFormSubmit" style={{ display: "none" }}>
              enviar
            </button>
          </FormModal>
        </DialogContent>

        <DialogActions>
          <Button
            tabIndex={121}
            onClick={event => {
              setModalOpen(false);
            }}
            color="secondary">
            Cancelar
          </Button>
          <Button tabIndex={120} color="primary" onClick={() => document.querySelector("#btnFormSubmit").click()}>
            Atualizar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
