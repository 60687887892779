import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "styled-components";

/* import utils */
import { filterString, maskCurrencyReal, getDate, maskCurrencyRealtoCalc } from "./../../util/functions";
import { NotifyContext } from "./../../util/notify";

/* import services */
import api from "./../../services/api";
import upload from "./../../services/s3";

import { useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";

/* import components */
import Legend from "./../../components/Legend";
import Search from "./../../components/Search";
import Input from "./../../components/Form/Input";
import Select from "./../../components/Form/Select";
import FormSelectMultiple from "./../../components/Form/SelectMultiple";
import File from "./../../components/Form/File";

/* import icons */
import IncomeIcon from "./../../assets/icons/income";

/* import styles */
import { Container, Cards, Card, Title, Subtitle, Comment, AddButton, FormModal, FormGroup } from "./styles";

export default function Plenary({ toggleTitles }) {
  toggleTitles("Equipe técnica", "Gerenciamento das equipes");

  const { status: colors } = useContext(ThemeContext);
  const { notify } = useContext(NotifyContext);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const client_id = process.env.REACT_APP_CLIENT_ID;
  const site_id = process.env.REACT_APP_SITE_ID;

  const [modalOpen, setModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchStatus, setSearchStatus] = useState("");

  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);

  const [titleModal, setTitleModal] = useState("Novo Membro");

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [occupation, setOccupation] = useState("");
  const [department, setDepartment] = useState("");
  const [phones, setPhones] = useState("");
  const [email, setEmail] = useState("");
  const [position, setPosition] = useState("");
  const [is_active, setIs_active] = useState(true);
  const [image_path, setImage_path] = useState("");
  const [images, setImages] = useState([]);
  const [whatsapp, setWhatsapp] = useState("");

  const handleFilter = async text => {
    const filtered = initialData.filter(
      item =>
        filterString(item.is_active ?? false, (searchStatus === "inative" ? false : true) ?? "") &&
        (filterString(item.id, text) ||
          filterString(item.name, text) ||
          filterString(item.occupation, text) ||
          filterString(item.department, text) ||
          filterString(item.phones ?? "", text) ||
          filterString(item.email ?? "", text)),
    );
    setData(filtered);
  };

  const handleClearForm = event => {
    event.preventDefault();

    setTitleModal("Novo Membro");

    setId("");
    setName("");
    setOccupation("");
    setDepartment("");
    setPhones("");
    setEmail("");
    setIs_active(true);
    setPosition("");
    setImage_path("");
    setImages([]);
    setWhatsapp("");
  };

  const handleNewData = () => {
    setTitleModal("Novo Membro");

    setId("");
    setName("");
    setOccupation("");
    setDepartment("");
    setPhones("");
    setEmail("");
    setIs_active(true);
    setPosition("");
    setImage_path("");
    setImages([]);
    setWhatsapp("");

    setModalOpen(true);
  };

  const handleOpenModalEdit = async (p_id, p_name, p_occupation, p_department, p_phones, p_email, p_is_active, p_image_path, p_position, p_whatsapp) => {
    setTitleModal("Editar Membro");

    setId(p_id);
    setName(p_name);
    setOccupation(p_occupation);
    setDepartment(p_department);
    setPhones(p_phones);
    setEmail(p_email);
    setIs_active(p_is_active);
    setImage_path(p_image_path);
    setPosition(p_position);
    setImages([{ file: p_image_path }]);
    setWhatsapp(p_whatsapp);

    setModalOpen(true);
  };

  const handleSave = async event => {
    event.preventDefault();

    if (images.length <= 0) {
      notify.show(true, "Uma imagem é obrigatória", "info");
      return;
    }

    notify.show(true, "Salvando...", "info");

    const imagesUpload = await upload(client_id, site_id, images);
    let image;

    if (imagesUpload && imagesUpload[0].fileUrl) {
      image = imagesUpload[0].fileUrl;
    } else {
      notify.show(true, "Não foi possivel realizar o upload da imagem! Tente novamente.", "error");
      return;
    }

    try {
      if (!id) {
        await api.post(`/teams/`, { image_path: image, name, occupation, department, phones, email, is_active, position, whatsapp });
        notify.show(true, "Registro adicionado com sucesso", "success");
      } else {
        await api.put(`/teams/${id}`, { image_path: image, name, occupation, department, phones, email, is_active, position, whatsapp });
        notify.show(true, "Registro atualizado com sucesso", "success");
      }

      loadData();
      setModalOpen(false);
    } catch (error) {
      console.log(error);
      notify.show(true, "Erro ao salvar registro! Tente novamente", "error");
    }
  };

  async function loadData() {
    try {
      const { data } = await api.get(`/teams/panel`, {});
      await setInitialData(data);
      await setData(data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleFilter(searchValue);
  }, [searchStatus]);

  useEffect(() => {
    loadData();
    document.querySelector("#input").focus();
  }, []);

  return (
    <>
      <Container>
        <Legend
          active={searchStatus}
          onClick={status => {
            setSearchStatus(status);
          }}
          items={[
            {
              key: "status",
              value: "active",
              title: "Filtrar Ativos",
              color: colors["active"],
            },
            {
              key: "status",
              value: "inative",
              title: "Filtrar Inativos",
              color: colors["inative"],
            },
          ]}
        />

        <Cards>
          {data.map(({ id, name, occupation, department, phones, email, is_active, image_path, position, whatsapp }) => {
            return (
              <Card
                onClick={() => {
                  handleOpenModalEdit(id, name, occupation, department, phones, email, is_active, image_path, position, whatsapp);
                }}
                status={colors[is_active ? "active" : "inative"]}
                key={id}>
                <Title>
                  {name}
                  <br />
                  <Comment>{occupation}</Comment>
                </Title>
                <Comment>{department.toString().toUpperCase()}</Comment>
              </Card>
            );
          })}
        </Cards>

        <Search
          InitialValue={searchValue}
          onChange={text => {
            setSearchValue(text);
            handleFilter(text);
          }}
        />

        <AddButton onClick={() => handleNewData()}>
          <IncomeIcon /> Novo Membro
        </AddButton>
      </Container>

      <Dialog
        fullScreen={fullScreen}
        open={modalOpen}
        onClose={event => {
          handleClearForm(event);
          setModalOpen(false);
        }}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{titleModal}</DialogTitle>
        <DialogContent>
          <FormModal onSubmit={handleSave} autoComplete="off">
            <FormGroup>
              <Input
                id="position"
                name="position"
                label="Posição"
                tabIndex={105}
                type="number"
                initialValue={position}
                onChange={text => {
                  setPosition(text);
                }}
                onKeyUp={text => {
                  setPosition(text);
                }}
                required={true}
                minValue={0}
              />

              <Input
                id="name"
                name="name"
                label="Nome"
                tabIndex={101}
                type="text"
                initialValue={name}
                onChange={text => {
                  setName(text);
                }}
                required={true}
              />

              <Input
                id="occupation"
                name="occupation"
                label="Função/Cargo"
                tabIndex={102}
                type="text"
                initialValue={occupation}
                onChange={text => {
                  setOccupation(text);
                }}
                required={true}
              />

              <Input
                id="department"
                name="department"
                label="Departamento"
                tabIndex={103}
                type="text"
                initialValue={department}
                onChange={text => {
                  setDepartment(text);
                }}
                required={true}
              />

              <Input
                id="phones"
                name="phones"
                label="Telefones (separados por barra / )"
                tabIndex={104}
                type="text"
                initialValue={phones}
                onChange={text => {
                  setPhones(text);
                }}
              />

              <Input
                id="whatsapp"
                name="whatsapp"
                label="WhatsApp"
                tabIndex={105}
                type="text"
                initialValue={whatsapp}
                onChange={text => {
                  setWhatsapp(text);
                }}
              />

              <Input
                id="email"
                name="email"
                label="E-mail"
                tabIndex={106}
                type="text"
                initialValue={email}
                onChange={text => {
                  setEmail(text);
                }}
              />

              <Select
                id="is_active"
                name="is_active"
                label="Situação"
                tabIndex={107}
                initialValue={is_active}
                options={[
                  { value: true, text: "Ativo" },
                  { value: false, text: "Inativo" },
                ]}
                onChange={text => {
                  console.log(text);
                  setIs_active(text);
                }}
                multiple={false}
              />

              <File
                id="image_path"
                initialFile={image_path}
                onChange={files => {
                  setImages(files);
                }}
              />
            </FormGroup>

            <button id="btnFormSubmit" style={{ display: "none" }}>
              enviar
            </button>
          </FormModal>
        </DialogContent>

        <DialogActions>
          <Button
            tabIndex={109}
            onClick={event => {
              handleClearForm(event);
              setModalOpen(false);
            }}
            color="secondary">
            Cancelar
          </Button>
          <Button tabIndex={108} color="primary" onClick={() => document.querySelector("#btnFormSubmit").click()}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
