import React from "react";

export default function SearchIcon({
  color = "#929292",
  width = 29,
  height = 22,
  styles = {},
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="22"
      style={styles}
      viewBox="0 0 29 22">
      <g transform="translate(-2 -5)">
        <path
          d="M17.309,20.016a4.5,4.5,0,1,1,.707-.707l5.348,5.348-.707.707-5.348-5.348ZM17,9,15,5H4A2,2,0,0,0,2,6.991V25.009A2,2,0,0,0,3.993,27H29.007A1.994,1.994,0,0,0,31,25V11a2,2,0,0,0-2.006-2ZM14.5,20A3.5,3.5,0,1,0,11,16.5,3.5,3.5,0,0,0,14.5,20Z"
          fill={color}
        />
      </g>
    </svg>
  );
}
