import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "styled-components";

/* import utils */
import { filterString, maskCurrencyReal, getDate, maskCurrencyRealtoCalc } from "./../../util/functions";
import { NotifyContext } from "./../../util/notify";

/* import services */
import api from "./../../services/api";
import upload from "./../../services/s3";

import { useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";

/* import components */
import Legend from "./../../components/Legend";
import Search from "./../../components/Search";
import Input from "./../../components/Form/Input";
import Select from "./../../components/Form/Select";
import FormSelectMultiple from "./../../components/Form/SelectMultiple";
import File from "./../../components/Form/File";
import { format } from "date-fns";
import ImageGallery from "react-image-gallery";
import ImageUploading from "react-images-uploading";

/* import icons */
import IncomeIcon from "./../../assets/icons/income";

/* import styles */
import { Container, Cards, Card, Title, Subtitle, Comment, AddButton, FormModal, FormGroup, ImagesGrid, ImageUploadContainer } from "./styles";
import ptBR from "date-fns/locale/pt-BR";

export default function Events({ toggleTitles }) {
  toggleTitles("Eventos", "Gerenciamento de galeria de eventos");

  const { status: colors } = useContext(ThemeContext);
  const { notify } = useContext(NotifyContext);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const client_id = process.env.REACT_APP_CLIENT_ID;
  const site_id = process.env.REACT_APP_SITE_ID;

  const [modalOpen, setModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchStatus, setSearchStatus] = useState("");

  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);

  const [titleModal, setTitleModal] = useState("Nova Galeria");

  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [is_active, setIs_active] = useState(true);
  const [image_path, setImage_path] = useState("");
  const [date, setDate] = useState([]);
  const [images, setImages] = useState([]);
  const [imagesArray, setImagesArray] = useState([]);

  const handleFilter = async text => {
    const filtered = initialData.filter(
      item => filterString(item.is_active ?? false, (searchStatus === "inative" ? false : true) ?? "") && (filterString(item.id, text) || filterString(item.title, text)),
    );
    setData(filtered);
  };

  const handleClearForm = event => {
    event.preventDefault();

    setTitleModal("Nova Galeria");

    setId("");
    setTitle("");
    setSummary("");
    setIs_active(true);
    setImage_path("");
    setImages([]);
    setDate("");
  };

  const handleNewData = () => {
    setTitleModal("Nova Galeria");

    setId("");
    setTitle("");
    setSummary("");
    setIs_active(true);
    setImage_path("");
    setImages([]);
    setDate("");

    setModalOpen(true);
  };

  const handleOpenModalEdit = async (p_id, p_title, p_summary, p_is_active, p_image_path, p_date, p_images) => {
    setTitleModal("Editar Galeria");

    setId(p_id);
    setTitle(p_title);
    setSummary(p_summary || "");
    setIs_active(p_is_active);
    setImage_path(p_image_path);
    setImages([{ file: p_image_path }]);
    setDate(format(new Date(p_date), "yyyy-MM-dd"));
    setImagesArray(
      p_images.map((p_image) => {
        return { ...p_image, file: p_image.image_path };
      }),
    );

    console.log(p_images);
    setModalOpen(true);
  };

  const handleSave = async event => {
    event.preventDefault();

    if (images.length <= 0) {
      notify.show(true, "Uma imagem é obrigatória", "info");
      return;
    }

    console.log(images);
    console.log(imagesArray);

    notify.show(true, "Salvando...", "info");

    const imagesUpload = await upload(client_id, site_id, images);
    let image;

    const imagesArrayUpload = await upload(client_id, site_id, imagesArray);
    let imageArray;

    if (imagesUpload && imagesUpload[0].fileUrl) {
      image = imagesUpload[0].fileUrl;
    } else {
      notify.show(true, "Não foi possivel realizar o upload da imagem! Tente novamente.", "error");
      return;
    }

    if (imagesArrayUpload) {
      imageArray = imagesArrayUpload.map(image => {
        return image.fileUrl;
      });
    } else {
      notify.show(true, "Não foi possivel realizar o upload da imagem! Tente novamente.", "error");
      return;
    }

    try {
      if (!id) {
        await api.post(`/events/`, { image_path: image, title, summary, is_active });
        notify.show(true, "Registro adicionado com sucesso", "success");
      } else {
        await api.put(`/events/${id}`, { image_path: image, title, summary, is_active, images: imageArray });
        notify.show(true, "Registro atualizado com sucesso", "success");
      }

      loadData();
      setModalOpen(false);
    } catch (error) {
      console.log(error);
      notify.show(true, "Erro ao salvar registro! Tente novamente", "error");
    }
  };

  async function loadData() {
    try {
      const { data } = await api.get(`/events`, {});
      await setInitialData(data.data);
      await setData(data.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleFilter(searchValue);
  }, [searchStatus]);

  useEffect(() => {
    loadData();
    document.querySelector("#input").focus();
  }, []);

  const maxNumber = 69;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImagesArray(imageList);
  };

  function onRemove(item) {
    let newArray = [...imagesArray];
    var filtered = newArray.filter(function(value, index) {
      return index !== item;
    });
    setImagesArray(filtered);
  }

  return (
    <>
      <Container>
        <Legend
          active={searchStatus}
          onClick={status => {
            setSearchStatus(status);
          }}
          items={[
            {
              key: "status",
              value: "active",
              title: "Filtrar Ativos",
              color: colors["active"],
            },
            {
              key: "status",
              value: "inative",
              title: "Filtrar Inativos",
              color: colors["inative"],
            },
          ]}
        />

        <Cards>
          {data.map(({ summary, title, id, is_active, image_path, date, images }) => {
            return (
              <Card
                onClick={() => {
                  handleOpenModalEdit(id, title, summary, is_active, image_path, date, images);
                }}
                status={colors[is_active ? "active" : "inative"]}
                key={id}>
                <Title>
                  {title}
                  <br />
                  <Comment>{summary}</Comment>
                </Title>
                <Comment>{format(new Date(date), "dd 'de' MMMM 'de' yyyy", { locale: ptBR })}</Comment>
              </Card>
            );
          })}
        </Cards>

        <Search
          InitialValue={searchValue}
          onChange={text => {
            setSearchValue(text);
            handleFilter(text);
          }}
        />

        <AddButton onClick={() => handleNewData()}>
          <IncomeIcon /> Nova Galeria
        </AddButton>
      </Container>

      <Dialog
        fullScreen={fullScreen}
        open={modalOpen}
        onClose={event => {
          setModalOpen(false);
          handleClearForm(event);
        }}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{titleModal}</DialogTitle>
        <DialogContent>
          <FormModal onSubmit={handleSave} autoComplete="off">
            <FormGroup>
              <Input
                id="title"
                name="title"
                label="Titulo"
                tabIndex={101}
                type="text"
                initialValue={title}
                onChange={text => {
                  setTitle(text);
                }}
                required={true}
              />

              <Input
                id="summary"
                name="summary"
                label="Sumario"
                tabIndex={102}
                type="text"
                initialValue={summary}
                onChange={text => {
                  setSummary(text);
                }}
              />

              <Select
                id="is_active"
                name="is_active"
                label="Situação"
                tabIndex={106}
                initialValue={is_active}
                options={[
                  { value: true, text: "Ativo" },
                  { value: false, text: "Inativo" },
                ]}
                onChange={text => {
                  console.log(text);
                  setIs_active(text);
                }}
                multiple={false}
              />

              <Input
                id="date"
                name="date"
                label="Data do evento"
                tabIndex={3}
                type="date"
                initialValue={date}
                onChange={text => {
                  setDate(text);
                }}
                required={true}
              />

              <File
                label="Capa"
                id="image_path"
                initialFile={image_path}
                onChange={files => {
                  setImages(files);
                }}
              />
            </FormGroup>
            <ImageUploadContainer>
              <ImageUploading multiple value={imagesArray} onChange={onChange} maxNumber={maxNumber} dataURLKey="image_path">
                {({ imageList, onImageUpload, isDragging, dragProps }) => (
                  // write your building UI
                  <div className="upload__image-wrapper">
                    <button className="upload__button-image-upload" type="button" style={isDragging ? { color: "red" } : undefined} onClick={onImageUpload} {...dragProps}>
                      Adicionar fotos
                    </button>
                    &nbsp;
                    <ImagesGrid>
                      {imageList.map((image, index) => (
                        <div key={image.id} className="image-item">
                          <img src={image["image_path"]} alt="" width="100" className="image-item__image-wrapper" />
                          <div className="image-item__btn-wrapper">
                            <button type="button" onClick={() => onRemove(index)}>
                              Remove
                            </button>
                          </div>
                        </div>
                      ))}
                    </ImagesGrid>
                  </div>
                )}
              </ImageUploading>
            </ImageUploadContainer>
            <button id="btnFormSubmit" style={{ display: "none" }}>
              enviar
            </button>
          </FormModal>
        </DialogContent>

        <DialogActions>
          <Button
            tabIndex={109}
            onClick={event => {
              handleClearForm(event);
              setModalOpen(false);
            }}
            color="secondary">
            Cancelar
          </Button>
          <Button tabIndex={108} color="primary" onClick={() => document.querySelector("#btnFormSubmit").click()}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
