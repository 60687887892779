import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "styled-components";

/* import utils */
import { filterString, maskCurrencyReal, getDate, maskCurrencyRealtoCalc } from "./../../util/functions";
import { NotifyContext } from "./../../util/notify";

/* import services */
import api from "./../../services/api";

import { useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";

/* import components */
import Legend from "./../../components/Legend";
import Search from "./../../components/Search";
import Input from "./../../components/Form/Input";
import Select from "./../../components/Form/Select";

/* import icons */
import SubNavigationIcon from "./../../assets/icons/subnavigation";

/* import styles */
import { Container, Cards, Card, Title, Subtitle, Comment, AddButton, FormModal, FormGroup } from "./styles";

export default function Navigation({ toggleTitles }) {
  toggleTitles("Menus", "Gerenciamento de menus do site");

  const { status: colors } = useContext(ThemeContext);
  const { notify } = useContext(NotifyContext);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [modalSubNavigationOpen, setModalSubNavigationOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchStatus, setSearchStatus] = useState("");

  const [navigations, setNavigations] = useState([]);
  const [sub_navigations, setSub_navigations] = useState([]);
  const [sub_navigationsInitial, setSub_navigationsInitial] = useState([]);

  const [titleModal, setTitleModal] = useState("Novo SubMenu");
  const [navigation_id, setNavigation_id] = useState("");
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [uri, setUri] = useState("");
  const [target, setTarget] = useState("");
  const [title, setTitle] = useState("");
  const [position, setPosition] = useState("");
  const [is_active, setIs_active] = useState(true);

  const handleFilter = async text => {
    const filtered = sub_navigationsInitial.filter(
      item =>
        filterString(item.is_active ?? false, (searchStatus === "inative" ? false : true) ?? "") &&
        (filterString(item.id, text) ||
          filterString(item.navigation.name, text) ||
          filterString(item.name, text) ||
          filterString(item.uri, text) ||
          filterString(item.target ?? "", text) ||
          filterString(item.title, text) ||
          filterString(item.position, text) ||
          filterString(item.is_active ?? false, text)),
    );
    setSub_navigations(filtered);
  };

  const handleClearForm = event => {
    event.preventDefault();

    setTitleModal("Novo SubMenu");

    setId("");
    setNavigation_id("");
    setName("");
    setUri("");
    setTarget("");
    setTitle("");
    setPosition("");
    setIs_active(true);
  };

  const handleNewSubNavigation = () => {
    setTitleModal("Novo SubMenu");

    setNavigation_id("");
    setId("");
    setName("");
    setUri("");
    setTarget("");
    setTitle("");
    setPosition("");
    setIs_active(true);

    setModalSubNavigationOpen(true);
  };

  const handleOpenModalEdit = async (p_id, p_navigation_id, p_name, p_uri, p_target, p_title, p_position, p_is_active) => {
    setTitleModal("Editar SubMenu");

    setId(p_id);
    setNavigation_id(p_navigation_id);
    setName(p_name);
    setUri(p_uri);
    setTarget(p_target);
    setTitle(p_title);
    setPosition(p_position);
    setIs_active(p_is_active);

    setModalSubNavigationOpen(true);
  };

  const handleSave = async event => {
    event.preventDefault();

    try {
      if (!id) {
        await api.post(`/navigations/${navigation_id}/sub_navigation/`, { name, uri, target, title, position, is_active });
        notify.show(true, "Submenu adicionado com sucesso", "success");
      } else {
        await api.put(`/navigations/${navigation_id}/sub_navigation/${id}`, { name, uri, target, title, position, is_active });
        notify.show(true, "Submenu atualizado com sucesso", "success");
      }

      loadSub_navigations();
      setModalSubNavigationOpen(false);
    } catch (error) {
      console.log(error);
      notify.show(true, "Erro ao salvar submenu! Tente novamente", "error");
    }
  };

  async function loadSub_navigations() {
    try {
      const { data } = await api.get(`/sub_navigations/`, {});
      await setSub_navigationsInitial(data);
      await setSub_navigations(data);
    } catch (error) {
      console.log(error);
    }
  }

  async function loadNavigations() {
    try {
      const { data } = await api.get(`/navigations/`, {});
      await setNavigations(data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleFilter(searchValue);
  }, [searchStatus]);

  useEffect(() => {
    loadSub_navigations();
    loadNavigations();
    document.querySelector("#input").focus();
  }, []);

  return (
    <>
      <Container>
        <Legend
          active={searchStatus}
          onClick={status => {
            setSearchStatus(status);
          }}
          items={[
            {
              key: "status",
              value: "active",
              title: "Filtrar Ativos",
              color: colors["active"],
            },
            {
              key: "status",
              value: "inative",
              title: "Filtrar Inativos",
              color: colors["inative"],
            },
          ]}
        />

        <Cards>
          {sub_navigations.map(({ navigation_id, id, name, uri, target, title, position, is_active, navigation }) => {
            return (
              <Card
                onClick={() => {
                  handleOpenModalEdit(id, navigation_id, name, uri, target, title, position, is_active);
                }}
                status={colors[is_active ? "active" : "inative"]}
                key={id}>
                <Title>{name}</Title>
                <Subtitle>{uri}</Subtitle>
                <Comment>{navigation.name.toUpperCase()}</Comment>
              </Card>
            );
          })}
        </Cards>

        <Search
          InitialValue={searchValue}
          onChange={text => {
            setSearchValue(text);
            handleFilter(text);
          }}
        />

        <AddButton onClick={() => handleNewSubNavigation()}>
          <SubNavigationIcon width="21" heigth="21" /> Novo Submenu
        </AddButton>
      </Container>

      <Dialog
        fullScreen={fullScreen}
        open={modalSubNavigationOpen}
        onClose={event => {
          handleClearForm(event);
          setModalSubNavigationOpen(false);
        }}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{titleModal}</DialogTitle>
        <DialogContent>
          <FormModal onSubmit={handleSave}>
            <FormGroup>
              <Select
                id="navigation_id"
                name="navigation_id"
                label="Menu Principal"
                tabIndex={100}
                initialValue={navigation_id}
                options={[{ value: "", text: "Selecione" }, ...navigations.map(item => ({ value: item.id, text: item.name }))]}
                multiple={false}
                required={true}
                onChange={text => {
                  setNavigation_id(text);
                }}
              />

              <Input
                id="name"
                name="name"
                label="Nome"
                tabIndex={101}
                type="text"
                initialValue={name}
                onChange={text => {
                  setName(text);
                }}
                required={true}
              />

              <Input
                id="uri"
                name="uri"
                label="Link"
                tabIndex={102}
                type="text"
                initialValue={uri}
                onChange={text => {
                  setUri(text);
                }}
                required={true}
              />

              <Select
                id="target"
                name="target"
                label="Abrir em"
                tabIndex={103}
                initialValue={target}
                options={[
                  { value: "", text: "Mesma página" },
                  { value: "_blank", text: "Nova aba" },
                ]}
                multiple={false}
                onChange={text => {
                  setTarget(text);
                }}
              />

              <Input
                id="title"
                name="title"
                label="Título (importante para SEO)"
                tabIndex={104}
                type="text"
                initialValue={title}
                onChange={text => {
                  setTitle(text);
                }}
                required={true}
              />

              <Input
                id="position"
                name="position"
                label="Posição"
                tabIndex={105}
                type="number"
                initialValue={position}
                onChange={text => {
                  setPosition(text);
                }}
                onKeyUp={text => {
                  setPosition(text);
                }}
                required={true}
                minValue={0}
              />

              <Select
                id="is_active"
                name="is_active"
                label="Situação"
                tabIndex={106}
                initialValue={is_active}
                options={[
                  { value: true, text: "Ativo" },
                  { value: false, text: "Inativo" },
                ]}
                onChange={text => {
                  console.log(text);
                  setIs_active(text);
                }}
                multiple={false}
              />
            </FormGroup>

            <button id="btnFormSubmit" style={{ display: "none" }}>
              enviar
            </button>
          </FormModal>
        </DialogContent>

        <DialogActions>
          <Button
            tabIndex={108}
            onClick={event => {
              handleClearForm(event);
              setModalSubNavigationOpen(false);
            }}
            color="secondary">
            Cancelar
          </Button>
          <Button tabIndex={107} color="primary" onClick={() => document.querySelector("#btnFormSubmit").click()}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
