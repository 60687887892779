import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;

  position: relative;
  flex: 1;
`;

export const Form = styled.div`
  width: 100%;
  max-height: calc(100vh - 200px);
  min-height: calc(100vh - 200px);

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  background-color: #ffffff;

  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 2px 0px;

  padding: 20px;

  overflow-y: auto;
  overflow-x: hidden;

  .react-csv-input {
    width: 100%;
  }

  .csv-input {
    width: 100%;
    border: 0;
    border-radius: 3px;
    padding: 10px;

    font-family: "AvenirLTStd-Roman";
    font-size: 14px;
    line-height: 19px;
    color: #100f03;

    background: transparent;
    border-bottom: 1px solid #cecece;

    transition: 0.4s;

    &:active {
      border-bottom: 1px solid #707070;
    }
    &:focus {
      border-bottom: 1px solid #707070;
    }
  }
`;

export const FormGroup = styled.div`
  width: 100%;
  margin-top: 10px;
  max-width: 100%;
  overflow: auto;
`;
