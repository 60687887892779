import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: fit-content;
  position: relative;
  align-self: center;
`;

export const Image = styled.img`
  width: 45px;
  height: 45px;

  border-radius: 45px;

  margin-left: 20px;

  cursor: pointer;
`;

export const UserName = styled.span`
  font-family: "AvenirLTStd-Book";
  color: ${props => props.theme.colors.title};
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  cursor: pointer;

  small {
  }
`;

export const Menu = styled.nav`
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 80px 1px;

  position: absolute;
  bottom: -104px;
  right: -5px;
  padding-top: ${props => (props.open ? `15px` : `0px`)};

  width: 110%;
  max-height: ${props => (props.open ? `400px` : `0px`)};
  overflow: ${props => (props.open ? `unset` : `hidden`)};

  z-index: 1;

  &:after {
    content: "";
    position: absolute;
    right: 22px;
    top: -5px;

    width: 10px;
    height: 10px;

    background: #fff;

    transform: rotate(45deg);
  }
`;

export const Items = styled.ul`
  list-style: none;
`;

export const Item = styled.li`
  font-family: "AvenirLTStd-Roman";
  color: #100f03;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1px;

  padding: 8px 19px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  z-index: 9;
  position: relative;

  cursor: pointer;

  transition: 0.4s;

  &.exit {
    background: ${props => props.theme.colors.primary};
    color: #ffffff;
    margin-top: 13px;
  }

  svg {
    margin-right: 10px;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.5);

    &.exit {
      background: red;
      color: #ffffff;
    }
  }
`;
