import React, { useState, useEffect, useContext } from "react";

import { ThemeContext } from "styled-components";

/* import services */
import api from "./../../services/api";

/* import utils */
import { filterString } from "./../../util/functions";
import { NotifyContext } from "./../../util/notify";

import { useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Legend from "./../../components/Legend";
import Search from "./../../components/Search";
import Input from "./../../components/Form/Input";
import Select from "./../../components/Form/Select";
import Textarea from "./../../components/Form/Textarea";

/* import styles */
import { Container, Cards, Card, Title, Subtitle, Comment, FormModal, FormGroup, Figure, Image } from "./styles";

export default function Partner({ toggleTitles }) {
  toggleTitles("Anuncios", "Gerenciamento dos anúncios");

  const { status: colors } = useContext(ThemeContext);
  const { notify } = useContext(NotifyContext);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [modalOpen, setModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchStatus, setSearchStatus] = useState("");

  const [paginate, setPaginate] = useState({});
  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);

  const [titleModal, setTitleModal] = useState("Anúncio");

  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [name, setName] = useState("");
  const [cro, setCro] = useState("");
  const [tel, setTel] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [ads_type_id, setAds_type_id] = useState("");
  const [type, setType] = useState("");
  const [image_path, setImage_path] = useState("");
  const [is_active, setIs_active] = useState("");

  const handleFilter = async text => {
    const filtered = initialData.filter(
      item =>
        filterString(item.is_active ?? false, (searchStatus === "inative" ? false : true) ?? "") &&
        (filterString(item.id, text) ||
          filterString(item.name, text) ||
          filterString(item.title ?? "", text) ||
          filterString(item.cro ?? "", text) ||
          filterString(item.tel ?? "", text) ||
          filterString(item.email ?? "", text) ||
          filterString(item.description ?? "", text) ||
          filterString(item.type.name ?? "", text)),
    );
    setData(filtered);
  };

  const handleOpenModalEdit = (p_id, p_title, p_name, p_cro, p_tel, p_email, p_description, p_ads_type_id, p_type, p_image_path, p_is_active) => {
    setTitleModal("Anúncio");

    setId(p_id);
    setTitle(p_title);
    setName(p_name);
    setCro(p_cro);
    setTel(p_tel);
    setEmail(p_email);
    setDescription(p_description);
    setAds_type_id(p_ads_type_id);
    setType(p_type);
    setImage_path(p_image_path);
    setIs_active(p_is_active);

    setModalOpen(true);
  };

  async function loadData() {
    try {
      const { data } = await api.get(`/ads/panel`, {});
      await setInitialData(data.data);
      await setData(data.data);
      setPaginate({ lastPage: 0, page: 0, perPage: 0, total: "0" });
    } catch (error) {
      console.log(error);
    }
  }

  const handleSave = async () => {
    notify.show(true, "Salvando...", "info");

    try {
      await api.put(`/ads/${id}`, { is_active });
      notify.show(true, "Registro atualizado com sucesso", "success");

      loadData();
      setModalOpen(false);
    } catch (error) {
      console.log(error);
      notify.show(true, "Erro ao salvar registro! Tente novamente", "error");
    }
  };

  useEffect(() => {
    handleFilter(searchValue);
  }, [searchStatus]);

  useEffect(() => {
    loadData();
    document.querySelector("#input").focus();
  }, []);

  return (
    <>
      <Container>
        <Legend
          active={searchStatus}
          onClick={status => {
            setSearchStatus(status);
          }}
          items={[
            {
              key: "status",
              value: "active",
              title: "Filtrar Ativos",
              color: colors["active"],
            },
            {
              key: "status",
              value: "inative",
              title: "Filtrar Inativos",
              color: colors["inative"],
            },
          ]}
        />
        <Cards>
          {data.map(({ id, title, name, cro, tel, email, description, ads_type_id, type, image_path, is_active }) => (
            <Card
              key={id}
              onClick={() => {
                handleOpenModalEdit(
                  id,
                  title,
                  name,
                  cro,
                  tel,
                  email,
                  description,
                  ads_type_id,
                  type.name.toString().toUpperCase() ?? "",
                  image_path,
                  is_active,
                );
              }}
              status={colors[is_active ? "active" : "inative"]}>
              <Figure>
                <Image src={image_path} />
              </Figure>
              <Title>
                {name} / {cro}
              </Title>
              <Subtitle>
                {title}
                <br />
                <br />
              </Subtitle>
              <Comment>{type.name.toString().toUpperCase() ?? ""}</Comment>
            </Card>
          ))}
        </Cards>

        <Search
          InitialValue={searchValue}
          onChange={text => {
            setSearchValue(text);
            handleFilter(text);
          }}
          btnAdd={false}
        />
      </Container>

      <Dialog
        fullScreen={fullScreen}
        open={modalOpen}
        onClose={event => {
          setModalOpen(false);
        }}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{titleModal}</DialogTitle>
        <DialogContent>
          <FormModal onSubmit={() => {}} autoComplete="off">
            <FormGroup>
              <Select
                id="is_active"
                name="is_active"
                label="Situação"
                tabIndex={103}
                initialValue={is_active}
                options={[
                  { value: true, text: "Ativo" },
                  { value: false, text: "Inativo" },
                ]}
                onChange={text => {
                  setIs_active(text);
                }}
                multiple={false}
              />
              <Input id="title" name="title" label="Título" tabIndex={101} type="text" initialValue={title} onChange={text => {}} readOnly={true} />
              <Input id="name" name="name" label="Nome" tabIndex={101} type="text" initialValue={name} onChange={text => {}} readOnly={true} />
              <Input id="cro" name="cro" label="Nº CRO" tabIndex={102} type="text" initialValue={cro} onChange={text => {}} readOnly={true} />
              <Input id="type" name="type" label="Tipo" tabIndex={102} type="text" initialValue={type} onChange={text => {}} readOnly={true} />
              <Input id="tel" name="tel" label="Telefone" tabIndex={103} type="text" initialValue={tel} onChange={text => {}} readOnly={true} />
              <Input id="email" name="email" label="E-mail" tabIndex={103} type="text" initialValue={email} onChange={text => {}} readOnly={true} />
              <Textarea
                id="description"
                name="description"
                label="Descriação"
                tabIndex={104}
                type="text"
                initialValue={description}
                onChange={text => {}}
                readOnly={true}
              />
            </FormGroup>
          </FormModal>
        </DialogContent>

        <DialogActions>
          <Button
            tabIndex={109}
            onClick={event => {
              setModalOpen(false);
            }}
            color="secondary">
            Fechar
          </Button>
          <Button tabIndex={104} color="primary" onClick={() => handleSave()}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
