import React, { useState, useEffect, useContext } from "react";

import { Link } from "react-router-dom";
import { ThemeContext } from "styled-components";
import ReactExport from "react-export-excel";

/* import services */
import api from "./../../services/api";

/* import utils */
import { filterString, getDate, getDateByTimeZoneCba } from "./../../util/functions";
import { NotifyContext } from "./../../util/notify";

import { useTheme } from "@material-ui/core/styles";

import Legend from "./../../components/Legend";
import Search from "./../../components/Search";

/* import icons */
import AddPageIcon from "./../../assets/icons/addPage";

/* import styles */
import { Container, Cards, Card, Title, Subtitle, Comment } from "./styles";
import { Actions, ActionEditIcon, ActionPresentIcon, ActionCardMembershipIcon, ActionDoneAllIcon } from "./styles";
import { ActionCloudDownloadIcon } from "./styles";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function Contact({ toggleTitles }) {
  toggleTitles("Cursos", "Agenda de cursos");

  const { status: colors } = useContext(ThemeContext);
  const { notify } = useContext(NotifyContext);

  const theme = useTheme();

  const [searchValue, setSearchValue] = useState("");
  const [searchStatus, setSearchStatus] = useState("");

  const [paginate, setPaginate] = useState({});
  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);

  const handleFilter = async text => {
    const filtered = initialData.filter(
      item =>
        filterString(item.is_active ?? false, (searchStatus === "inative" ? false : true) ?? "") &&
        (filterString(item.id, text) ||
          filterString(item.name, text) ||
          filterString(item.address ?? "", text) ||
          filterString(item.local ?? "", text) ||
          filterString(item.instructors ?? "", text) ||
          filterString(getDate(item.start_date, "dd'/'MM'/'yyyy"), text) ||
          filterString(item.start_date ? getDate(item.start_date, "dd'/'MM'/'yyyy") : "", text) ||
          filterString(item.uri ?? "", text)),
    );
    setData(filtered);
  };

  async function loadData() {
    notify.show(true, "Carregando", "info");
    try {
      const { data } = await api.get(`/courses/panel`, { headers: { pagelimit: 10000, pagenumber: 1 } });
      await setInitialData(data);
      await setData(data);
      setPaginate({ lastPage: 0, page: 0, perPage: 0, total: "0" });
    } catch (error) {
      console.log(error);
    }
    notify.show(false, "Carregando", "info");
  }

  useEffect(() => {
    handleFilter(searchValue);
  }, [searchStatus]);

  useEffect(() => {
    loadData();
    document.querySelector("#input").focus();
  }, []);

  return (
    <>
      <Container>
        <Legend
          active={searchStatus}
          onClick={status => {
            setSearchStatus(status);
          }}
          items={[
            {
              key: "status",
              value: "active",
              title: "Filtrar Ativos",
              color: colors["active"],
            },
            {
              key: "status",
              value: "inative",
              title: "Filtrar Inativos",
              color: colors["inative"],
            },
          ]}
        />
        <Cards>
          {data.map(({ id, name, start_date, end_date, local, summary, uri, is_active, is_virtual, course_subscribes }) => (
            <>
              <Card key={id} status={colors[is_active ? "active" : "inative"]}>
                <Actions>
                  <Link to={`/curso/editar/${id}`}>
                    <ActionEditIcon />
                  </Link>
                  <Link to={`/curso/presenca/${id}`} title="Definir presença">
                    <ActionDoneAllIcon />
                  </Link>
                  <a href={`/curso/inscricoes/${id}`} title="Lista de inscritos" target="_blank">
                    <ActionCardMembershipIcon />
                  </a>
                  <a href={`/curso/inscricoes/${id}/true`} title="Lista de Presentes" target="_blank">
                    <ActionPresentIcon />
                  </a>
                  <ExcelFile title="Baixar Lista de Inscritos" element={<ActionCloudDownloadIcon />} filename={`${name} - Relatorio de inscritos`}>
                    <ExcelSheet data={course_subscribes} name={`${name} - Relatorio de inscritos`}>
                      <ExcelColumn label="Nome/Usuário" value={col => col.site_user.name} />
                      <ExcelColumn label="CPF" value={col => col.site_user.cpf} />
                      <ExcelColumn label="Presente?" value={col => (col.present ? "SIM" : "NÃO")} />
                    </ExcelSheet>
                  </ExcelFile>
                </Actions>

                <Title>{name}</Title>
                <Subtitle>
                  {local}
                  <br />
                  <br />
                  {is_virtual ? "Curso virtual" : ""}
                </Subtitle>
                <Comment>
                  {id > 50 ? (
                    <>
                      {getDateByTimeZoneCba(start_date, "dd'/'MM'/'yyyy 'às' HH':'mm ")}
                      {end_date ? getDateByTimeZoneCba(end_date, "' até 'dd'/'MM'/'yyyy 'às' HH':'mm") : ""}
                    </>
                  ) : (
                    <>
                      {getDate(start_date, "dd'/'MM'/'yyyy 'às' HH':'mm ")}
                      {end_date ? getDate(end_date, "' até 'dd'/'MM'/'yyyy 'às' HH':'mm") : ""}
                    </>
                  )}
                </Comment>
              </Card>
            </>
          ))}
        </Cards>

        <Search
          InitialValue={searchValue}
          onChange={text => {
            setSearchValue(text);
            handleFilter(text);
          }}
          btnAdd
          btnAddTo="/curso/novo"
          btnAddIcon={<AddPageIcon />}
          btnAddtitle="Adicionar Curso"
        />
      </Container>
    </>
  );
}
