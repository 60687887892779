import React from "react";

export default function LoginIcon({
  color,
  width = 29.016,
  height = 29.016,
  styles = {},
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      viewBox="0 0 29.016 29.016"
      style={styles}>
      <path
        d="M187.592,0H173.084a2.42,2.42,0,0,0-2.418,2.418V6.649a.6.6,0,1,0,1.209,0V2.418a1.211,1.211,0,0,1,1.209-1.209h14.508A1.211,1.211,0,0,1,188.8,2.418V26.6a1.211,1.211,0,0,1-1.209,1.209H173.084a1.211,1.211,0,0,1-1.209-1.209V22.366a.6.6,0,1,0-1.209,0V26.6a2.42,2.42,0,0,0,2.418,2.418h14.508A2.42,2.42,0,0,0,190.01,26.6V2.418A2.42,2.42,0,0,0,187.592,0Z"
        transform="translate(-160.994)"
        fill={color}
      />
      <path
        d="M13.495,141.46a.6.6,0,0,0,.817.891l7.254-6.649a.6.6,0,0,0,0-.891l-7.254-6.649a.6.6,0,1,0-.817.891l6.109,5.6H.6a.6.6,0,1,0,0,1.209h19Z"
        transform="translate(0 -120.749)"
        fill={color}
      />
    </svg>
  );
}
