import React, { useState, useEffect, useContext } from "react";

import { ThemeContext } from "styled-components";

/* import services */
import api from "./../../services/api";

/* import utils */
import { filterString } from "./../../util/functions";
import { NotifyContext } from "./../../util/notify";

import { useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Search from "./../../components/Search";
import Input from "./../../components/Form/Input";
import Textarea from "./../../components/Form/Textarea";

/* import styles */
import { Container, Cards, Card, Title, Subtitle, Comment, FormModal, FormGroup } from "./styles";

export default function Contact({ toggleTitles }) {
  toggleTitles("Contatos", "Recebidos pelo formulário do site");

  const { status: colors } = useContext(ThemeContext);
  const { notify } = useContext(NotifyContext);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [modalOpen, setModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchStatus, setSearchStatus] = useState("");

  const [paginate, setPaginate] = useState({});
  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);

  const [titleModal, setTitleModal] = useState("Contato");

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [message, setMessage] = useState("");

  const handleFilter = async text => {
    const filtered = initialData.filter(
      item =>
        filterString(item.id, text) || filterString(item.name, text) || filterString(item.email ?? "", text) || filterString(item.message ?? "", text) || filterString(item.telephone ?? "", text),
    );
    setData(filtered);
  };

  const handleOpenModalEdit = (p_id, p_name, p_email, p_message, p_telephone) => {
    setTitleModal("Contato");

    setId(p_id);
    setName(p_name);
    setEmail(p_email);
    setMessage(p_message);
    setTelephone(p_telephone);

    setModalOpen(true);
  };

  async function loadData() {
    try {
      const { data } = await api.get(`/contacts/`, {});
      await setInitialData(data.data);
      await setData(data.data);
      setPaginate({ lastPage: 0, page: 0, perPage: 0, total: "0" });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleFilter(searchValue);
  }, [searchStatus]);

  useEffect(() => {
    loadData();
    document.querySelector("#input").focus();
  }, []);

  return (
    <>
      <Container>
        {/*<Legend
        active={searchStatus}
        onClick={status => {
          setSearchStatus(status);
        }}
        items={[
          {
            key: "status",
            value: "active",
            title: "Filtrar Ativos",
            color: colors["active"],
          },
          {
            key: "status",
            value: "inative",
            title: "Filtrar Inativos",
            color: colors["inative"],
          },
        ]}
      />*/}
        <Cards>
          {data.map(({ id, name, email, message, telephone }) => (
            <Card
              key={id}
              onClick={() => {
                handleOpenModalEdit(id, name, email, message, telephone);
              }}>
              <Title>{name}</Title>
              <Subtitle>
                {email}
                <br />
                <br />
              </Subtitle>
              <Comment>{telephone}</Comment>
            </Card>
          ))}
        </Cards>

        <Search
          InitialValue={searchValue}
          onChange={text => {
            setSearchValue(text);
            handleFilter(text);
          }}
          btnAdd={false}
        />
      </Container>

      <Dialog
        fullScreen={fullScreen}
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{titleModal}</DialogTitle>
        <DialogContent>
          <FormModal autoComplete="off">
            <FormGroup>
              <Input id="name" name="name" label="Nome" tabIndex={101} type="text" initialValue={name} onChange={() => {}} readOnly={true} />
              <Input id="email" name="email" label="E-mail" tabIndex={102} type="email" initialValue={email} onChange={() => {}} readOnly={true} />
              <Input id="telephone" name="telephone" label="Telefone" tabIndex={103} type="text" initialValue={telephone} onChange={() => {}} readOnly={true} />
              <Textarea id="message" name="message" label="Mensagem" tabIndex={104} type="text" initialValue={message} onChange={() => {}} readOnly={true} />
            </FormGroup>
          </FormModal>
        </DialogContent>

        <DialogActions>
          <Button
            tabIndex={109}
            onClick={() => {
              setModalOpen(false);
            }}
            color="secondary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
