import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { logout } from "./../../services/auth";

import { Container, Image, UserName, Menu, Items, Item } from "./styles";

import AlarmIcon from "./../../assets/icons/alarm";
import CogIcon from "./../../assets/icons/cog";
import ExitIcon from "./../../assets/icons/exit";

export default function Avatar({ name, work, image }) {
  const history = useHistory();
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <Container>
      <UserName onClick={() => setOpenMenu(!openMenu)} open={openMenu}>
        {name}
        <small>{work}</small>
      </UserName>
      <Image
        src={image}
        alt=""
        title=""
        onClick={() => setOpenMenu(!openMenu)}
      />

      <Menu open={openMenu}>
        <Items>
          <Link to="/perfil">
            <Item>
              <CogIcon width="15" height="15" /> Meu Perfil
            </Item>
          </Link>
          <Item className="exit" onClick={() => logout(history)}>
            <ExitIcon /> Sair
          </Item>
        </Items>
      </Menu>
    </Container>
  );
}
