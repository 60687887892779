import React from "react";

export default function HamburguerIcon({
  color = "#707070",
  width = 25,
  height = 17.578,
  styles = {},
  onClick,
}) {
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="17.578"
      viewBox="0 0 25 17.578">
      <g transform="translate(0 -76)">
        <g transform="translate(0 83.813)">
          <g transform="translate(0 0)">
            <path
              d="M24.023,236H.977a.977.977,0,0,0,0,1.953H24.023a.977.977,0,0,0,0-1.953Z"
              transform="translate(0 -236)"
              fill={color}
            />
          </g>
        </g>
        <g transform="translate(0 76)">
          <g transform="translate(0 0)">
            <path
              d="M24.023,76H.977a.977.977,0,0,0,0,1.953H24.023a.977.977,0,0,0,0-1.953Z"
              transform="translate(0 -76)"
              fill={color}
            />
          </g>
        </g>
        <g transform="translate(0 91.625)">
          <path
            d="M24.023,396H.977a.977.977,0,0,0,0,1.953H24.023a.977.977,0,0,0,0-1.953Z"
            transform="translate(0 -396)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}
