import React, { useState, useEffect, memo } from "react";

import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";

/* import utils */
import { NotifyContext } from "./../../../util/notify";
import { createPreviewImage, compress } from "./../../../util/thumbnail";

/* import icons */
import camera from "./../../../assets/icons/cameraText.svg";

/* import styles */
import { Container, FormGroup, Thumbnail, ThumbnailIcon, ThumbnailInput, Label } from "./styles";

const FormFile = ({ id, onChange = () => {}, height, initialFile = "", label = "", bg = "#6f130d", maxWidthOrHeight = 800 }) => {
  const [image_path, setImage_path] = useState(initialFile);
  const [compressedImages, setCompressedImages] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    async function compressImage() {
      console.log(maxWidthOrHeight);
      setCompressedImages(await compress([image_path], maxWidthOrHeight));
      setLoading(false);
    }
    compressImage();
  }, [image_path]);

  useEffect(() => {
    console.log("file", initialFile);
    setImage_path(initialFile);
  }, [initialFile]);

  return (
    <Container>
      <FormGroup>
        {label ? <Label for={`${id}`}>{label}</Label> : ""}
        <Thumbnail
          bg={bg}
          height={height}
          style={{
            backgroundImage: `url("${createPreviewImage(image_path)}")`,
          }}>
          <ThumbnailInput
            id={id}
            accept="image/x-png,image/gif,image/jpeg,image/svg+xml"
            type="file"
            onChange={async event => {
              setImage_path(event.target.files[0]);
              onChange(await compress([event.target.files[0]]));
            }}
          />
          <ThumbnailIcon style={{ display: image_path ? "none" : "" }} src={camera} alt="Selecione uma imagem" />
          <Fade in={loading}>
            <CircularProgress style={{ color: "#fcd000" }} />
          </Fade>
        </Thumbnail>
      </FormGroup>
    </Container>
  );
};

export default memo(FormFile);
