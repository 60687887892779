import React from "react";

export default function SaveButtonIcon({
  color = "#265697",
  width = 294.842,
  height = 294.842,
  styles = {},
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 294.842 294.842"
      style={styles}>
      <g transform="translate(-382 -115)">
        <g transform="translate(382 115)">
          <path
            d="M278.527,79.946a148.728,148.728,0,0,0-43.538-51.132,6,6,0,1,0-7.135,9.649,136.676,136.676,0,0,1,40.008,46.983,133.93,133.93,0,0,1,14.98,61.976c0,74.671-60.75,135.421-135.421,135.421S12,222.093,12,147.421,72.75,12,147.421,12a6,6,0,0,0,0-12C66.133,0,0,66.133,0,147.421S66.133,294.842,147.421,294.842s147.421-66.133,147.421-147.421A147.831,147.831,0,0,0,278.527,79.946Z"
            fill={color}
          />
          <g transform="translate(0 4)">
            <rect
              width="104"
              height="10"
              rx="5"
              transform="translate(60.074 142.348) rotate(30)"
              fill={color}
            />
            <rect
              width="148"
              height="10"
              rx="5"
              transform="translate(239.927 89.694) rotate(130)"
              fill={color}
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
