import styled from "styled-components";

export const Container = styled.section`
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 80px 1px;

  width: 980px;
  height: 600px;

  border-radius: 5px;

  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

export const Background = styled.aside`
  overflow: hidden;
`;

export const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  transition: 0.4s;

  &:hover {
    transform: scale(1.7) rotate(12deg);
  }
`;

export const Article = styled.article`
  width: 800px;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
`;

export const Content = styled.div`
  position: relative;
  margin: 65px;

  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
`;

export const Welcome = styled.span`
  font-family: "AvenirLTStd-Book";
  font-size: 12px;
  line-height: 12px;
  color: #707070;

  position: absolute;
  right: 0;
  top: 0;
`;

export const Logo = styled.img`
  width: 150px;

  align-self: center;
`;

export const Client = styled.h1`
  font-family: "AvenirLTStd-Black";
  font-size: 18px;
  line-height: 58px;
  color: #707070;
  text-transform: uppercase;

  align-self: center;
`;

export const Form = styled.form`
  margin-top: 10px;

  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
`;

export const FormGroup = styled.div`
  background: #fff;

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  padding: 5px 20px;
  margin-bottom: 5px;

  transition: 0.4s;
  &::after {
    content: "";
    width: 4px;
    height: 100%;

    background: ${props => (props.active ? `#6f130d` : `#fff`)};

    position: absolute;
    left: 0;
    top: 0;
  }

  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 2px 0px;

  ${props => (props.active ? `box-shadow: rgba(0,0,0,0.15) 0px 0px 35px 1px; position: relative; z-index: 9;` : ``)};
`;

export const Label = styled.label`
  font-family: "AvenirLTStd-Book";
  font-size: 12px;
  line-height: 19px;
  color: #707070;
  text-transform: uppercase;
`;

export const Input = styled.input`
  height: 28px;
  width: 100%;
  border: 0;

  font-family: "AvenirLTStd-Roman";
  font-size: 14px;
  line-height: 19px;
  color: #707070;
`;

export const Select = styled.select`
  height: 28px;
  width: 100%;
  border: 0;

  font-family: "AvenirLTStd-Roman";
  font-size: 14px;
  line-height: 19px;
  color: #707070;
`;

export const Button = styled.button`
  font-family: "AvenirLTStd-Black";
  font-size: 16px;
  line-height: 16px;
  color: #707070;
  letter-spacing: 2px;
  text-transform: uppercase;

  border: 0;
  background: transparent;

  margin: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;

  cursor: pointer;

  svg {
    margin-left: 10px;
  }

  &:hover {
    opacity: 0.5;
  }
`;

export const Forgot = styled.a`
  font-family: "AvenirLTStd-Book";
  font-size: 10px;
  line-height: 12px;
  color: #707070;
  letter-spacing: 2px;
  text-transform: uppercase;

  border: 0;
  background: transparent;

  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;

  cursor: pointer;

  svg {
    margin-left: 10px;
  }

  &:hover {
    opacity: 0.5;
  }
`;
