import React, { useState, useEffect } from "react";

import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";

/* import utils */
import { NotifyContext } from "./../../../util/notify";
import { createPreviewImage, compress } from "./../../../util/thumbnail";

/* import icons */
import camera from "./../../../assets/icons/cameraText.svg";

/* import styles */
import { Container, FormGroup, Label, Input } from "./styles";

export default function FormFile({ id, onChange = () => {}, height, initialFile = "", label = "" }) {
  const [image_path, setImage_path] = useState(initialFile);
  const [compressedImages, setCompressedImages] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    async function compressImage() {
      setCompressedImages(await compress([image_path]));
      setLoading(false);
    }
    compressImage();
  }, [image_path]);

  return (
    <Container>
      <FormGroup>
        {label ? <Label for={`${id}`}>{label}</Label> : ""}

        <Input
          id={`${id}`}
          accept="image/x-png,image/gif,image/jpeg,image/svg+xml"
          type="file"
          onChange={async event => {
            setImage_path(event.target.files[0]);
            onChange(await compress([event.target.files[0]]));
          }}
        />
      </FormGroup>
    </Container>
  );
}
