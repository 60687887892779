import React from "react";

export default function ExitIcon({
  color = "#FFF",
  width = 16,
  height = 16,
  styles = {},
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      style={styles}
      viewBox="0 0 16 16">
      <path
        d="M10.333,12H9.667a.333.333,0,0,0-.333.333v2.333h-8V1.333h8V3.667A.333.333,0,0,0,9.667,4h.667a.333.333,0,0,0,.333-.333V1.333A1.333,1.333,0,0,0,9.333,0h-8A1.333,1.333,0,0,0,0,1.333V14.667A1.333,1.333,0,0,0,1.333,16h8a1.333,1.333,0,0,0,1.333-1.333V12.333A.333.333,0,0,0,10.333,12Z"
        fill={color}
      />
      <path
        d="M139.892,131.748l-4-3.667a.333.333,0,0,0-.559.246v.667a.333.333,0,0,0,.11.248l2.317,2.086h-9.428a.333.333,0,0,0-.333.333v.667a.333.333,0,0,0,.333.333h9.428l-2.317,2.086a.333.333,0,0,0-.11.248v.667a.333.333,0,0,0,.2.305.329.329,0,0,0,.134.028.334.334,0,0,0,.225-.088l4-3.667a.334.334,0,0,0,0-.492Z"
        transform="translate(-124 -123.994)"
        fill={color}
      />
    </svg>
  );
}
