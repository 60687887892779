import styled from "styled-components";

export const Container = styled.header`
  background: ${props => props.theme.colors.primary};

  width: 100%;

  padding: ${props =>
    props.open ? `15px 15px 15px ${props.theme.sizes.nav}px` : `15px 15px 15px ${props.theme.sizes.minNav}px`};

  display: flex;
  justify-content: space-between;

  transition: 0.6s;
`;

export const Content = styled.div``;

export const Title = styled.h1`
  font-family: "AvenirLTStd-Book";
  color: ${props => props.theme.colors.title};
  font-size: 32px;
  line-height: 47px;
  letter-spacing: 3px;
  margin-left: 25px;
`;

export const SubTitle = styled.h2`
  font-family: "AvenirLTStd-Book";
  color: ${props => props.theme.colors.title};
  font-size: 15px;
  line-height: 23px;
  letter-spacing: 2px;
  margin-top: 30px;
  padding-bottom: 10px;
  margin-left: 25px;
`;
