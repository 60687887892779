import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;

  position: relative;
  flex: 1;
`;

export const Form = styled.div`
  width: 100%;
  max-height: calc(100vh - 200px);
  min-height: calc(100vh - 200px);

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  background-color: #ffffff;

  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 2px 0px;

  padding: 20px;

  overflow-y: auto;
  overflow-x: hidden;
`;
