import React from "react";

import { Container, Item, Icon, Label } from "./styles";

export default function legend({ active, items, onClick = () => {} }) {
  if (items) {
    return (
      <Container>
        {items.map(({ title, color, key, value }) => (
          <Item active={active === value ? true : false} to={`?${key}=${value}`} title={`Filtrar somente os ${title}`} onClick={() => onClick(value)}>
            <Icon color={color} />
            <Label color={color}>{title}</Label>
          </Item>
        ))}
      </Container>
    );
  } else {
    return <></>;
  }
}
