import React, { useEffect, useState } from "react";
import Parser from "html-react-parser";

import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document/";

// Import translations for the German language.
import "@ckeditor/ckeditor5-build-decoupled-document/build/translations/pt-br";

import { Container, FormGroup, Label } from "./styles";
import { bucketName, digitalOceanSpaces, spacesEndpoint } from "../../../services/s3";

const client_id = process.env.REACT_APP_CLIENT_ID;
const site_id = process.env.REACT_APP_SITE_ID;

function Editor({ id, name, initialValue, onChange = () => {}, label, readOnly = false, onInit = () => {}, teste }) {
  let [editorComponent, setEditorComponent] = useState({ getData: () => {} });

  useEffect(() => {
    DecoupledEditor.create(document.querySelector(".document-editor__editable"), {
      extraPlugins: [MyCustomUploadAdapterPlugin],
      cloudServices: {
        uploadUrl: `${process.env.REACT_APP_API}`,
      },
      image: {
        // You need to configure the image toolbar, too, so it uses the new style buttons.
        toolbar: ["imageStyle:alignLeft", "imageStyle:full", "imageStyle:alignRight"],
        styles: [
          // This option is equal to a situation where no style is applied.
          "full",

          // This represents an image aligned to the left.
          "alignLeft",

          // This represents an image aligned to the right.
          "alignRight",
        ],
        resizeUnit: "px",
      },
    })
      .then(editor => {
        // The toolbar needs to be explicitly appended.
        editor.isReadOnly = readOnly;

        editor.setData(initialValue);
        editor.locale = { language: "pt-BR" };

        document.querySelector(".document-editor__toolbar").appendChild(editor.ui.view.toolbar.element);

        window.editor = editor;
        setEditorComponent(editor);
      })
      .catch(error => {
        console.error("There was a problem initializing the editor.", error);
      });

    onInit(editorComponent);
  }, []);

  useEffect(() => {
    if (initialValue && initialValue !== "<p></p>" && initialValue !== "") {
      editorComponent.setData(initialValue);
      //editorComponent.execute("imageInsert", { source: "http://www.cromt.org.br/uploads/popup/900x507/5ce67447feb387e2fd453bcc99acf37e.png" });
    }
  }, [initialValue]);

  onInit(editorComponent);

  return (
    <Container>
      <FormGroup>
        <Label>{label}</Label>
        <div class="document-editor">
          <div class="document-editor__toolbar"></div>
          <div class="document-editor__editable-container">
            <div class="document-editor__editable" children={teste}></div>
          </div>
        </div>
      </FormGroup>
    </Container>
  );
}

export default Editor;

function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = loader => {
    return new MyUploadAdapter(loader);
  };
}

class MyUploadAdapter {
  constructor(props) {
    // CKEditor 5's FileLoader instance.
    this.loader = props;
    // URL where to send files.
    this.url = `http://localhost:3333/upload`;
  }

  // Starts the upload process.
  upload() {
    return new Promise((resolve, reject) => {
      this._initRequest();
      this._initListeners(resolve, reject);
      this._sendRequest();
    });
  }

  // Aborts the upload process.
  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  // Example implementation using XMLHttpRequest.
  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest());

    xhr.open("POST", this.url, true);
    xhr.responseType = "json";
    xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(resolve, reject) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = "Couldn't upload file:" + ` ${loader.file.name}.`;

    xhr.addEventListener("error", () => reject(genericErrorText));
    xhr.addEventListener("abort", () => reject());
    xhr.addEventListener("load", () => {
      const response = xhr.response;
      if (!response || response.error) {
        return reject(response && response.error ? response.error.message : genericErrorText);
      }

      // If the upload is successful, resolve the upload promise with an object containing
      // at least the "default" URL, pointing to the image on the server.
      resolve({
        default: response.s3Url,
      });
    });

    if (xhr.upload) {
      xhr.upload.addEventListener("progress", evt => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  // Prepares the data and sends the request.
  _sendRequest() {
    const data = new FormData();

    this.loader.file.then(result => {
      console.log(result);
      data.append("file", result);
      this.xhr.send(data);
    });
  }
}
