import React, { useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import { Container, Main } from "./styles";

/* import components */
import Header from "./../components/Header";

/* import pages */
import Home from "./Home";

import Navigation from "./Navigation";
import Profile from "./Profile";
import SiteConfig from "./SiteConfig";
import Users from "./Users";
import Services from "./Services";
import Plenary from "./Plenary";
import President from "./President";
import Team from "./Team";
import Representative from "./Representative";
import Registrations from "./Registrations";
import Regional from "./Regional";
import Video from "./Video";
import Banner from "./Banner";
import Popup from "./Popup";
import Magazine from "./Magazine";
import Contact from "./Contact";
import Ads from "./Ads";

import Files from "./Files";
import FileType from "./FileType";

import Partner from "./Partner";
import PartnerSegment from "./PartnerSegment";

import Pages from "./Pages";
import PageCreate from "./Pages/Create";
import PageUpdate from "./Pages/Update";

import Agendamentos from "./Agendamentos";
import Sede from "./Sede";

import News from "./News";
import NewsCreate from "./News/Create";
import NewsUpdate from "./News/Update";

import Schedule from "./Schedule";
import ScheduleCreate from "./Schedule/Create";
import ScheduleUpdate from "./Schedule/Update";
import ScheduleType from "./ScheduleType";

import Certificate from "./Certificate";
import CertificateImport from "./Certificate/Import";
import CertificateDetails from "./Certificate/History";

import Course from "./Course";
import CourseCreate from "./Course/Create";
import CourseUpdate from "./Course/Update";
import CoursePresent from "./Course/Present";
import Events from "./Events";

function Routes() {
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");

  const toggleTitles = (pTitle = "", pSubtitle = "") => {
    setTitle(pTitle);
    setSubtitle(pSubtitle);
  };

  const [openNav, setOpenNav] = useState(true);
  const toggleNav = () => {
    setOpenNav(!openNav);
  };

  return (
    <BrowserRouter forceRefresh={false}>
      <Switch>
        <Container>
          <Header openNav={openNav} toggleNav={toggleNav} title={title} subTitle={subtitle} />

          <Main openNav={openNav}>
            <Route path={"/dashboard"} exact>
              <Home toggleTitles={toggleTitles} />
            </Route>

            <Route path="/menus" exact>
              <Navigation toggleTitles={toggleTitles} />
            </Route>

            <Route path="/perfis" exact>
              <Profile toggleTitles={toggleTitles} />
            </Route>

            <Route path="/agendamentos" exact>
              <Agendamentos toggleTitles={toggleTitles} />
            </Route>

            <Route path="/sede" exact>
              <Sede toggleTitles={toggleTitles} />
            </Route>

            <Route path="/parametros" exact>
              <SiteConfig toggleTitles={toggleTitles} />
            </Route>

            <Route path="/usuarios" exact>
              <Users toggleTitles={toggleTitles} />
            </Route>

            <Route path="/servicos" exact>
              <Services toggleTitles={toggleTitles} />
            </Route>

            <Route path={"/eventos"} exact>
              <Events toggleTitles={toggleTitles} />
            </Route>

            <Route path="/plenario" exact>
              <Plenary toggleTitles={toggleTitles} />
            </Route>

            <Route path="/presidentes" exact>
              <President toggleTitles={toggleTitles} />
            </Route>

            <Route path="/equipes" exact>
              <Team toggleTitles={toggleTitles} />
            </Route>

            <Route path="/representantes" exact>
              <Representative toggleTitles={toggleTitles} />
            </Route>

            <Route path="/cadastros" exact>
              <Registrations toggleTitles={toggleTitles} />
            </Route>

            <Route path="/delegacias" exact>
              <Regional toggleTitles={toggleTitles} />
            </Route>

            <Route path="/videos" exact>
              <Video toggleTitles={toggleTitles} />
            </Route>

            <Route path="/banners" exact>
              <Banner toggleTitles={toggleTitles} />
            </Route>

            <Route path="/popups" exact>
              <Popup toggleTitles={toggleTitles} />
            </Route>

            <Route path="/revistas" exact>
              <Magazine toggleTitles={toggleTitles} />
            </Route>

            <Route path="/arquivos" exact>
              <Files toggleTitles={toggleTitles} />
            </Route>

            <Route path="/arquivo-categoria" exact>
              <FileType toggleTitles={toggleTitles} />
            </Route>

            <Route path="/contatos" exact>
              <Contact toggleTitles={toggleTitles} />
            </Route>

            <Route path="/parceiros" exact>
              <Partner toggleTitles={toggleTitles} />
            </Route>

            <Route path="/parceiro-segmentos" exact>
              <PartnerSegment toggleTitles={toggleTitles} />
            </Route>

            <Route path="/anuncios" exact>
              <Ads toggleTitles={toggleTitles} />
            </Route>

            <Route path="/paginas" exact>
              <Pages toggleTitles={toggleTitles} />
            </Route>

            <Route path="/pagina/nova" exact>
              <PageCreate toggleTitles={toggleTitles} />
            </Route>

            <Route path="/pagina/editar/:page_id" exact>
              <PageUpdate toggleTitles={toggleTitles} />
            </Route>

            <Route path="/noticias" exact>
              <News toggleTitles={toggleTitles} />
            </Route>

            <Route path="/noticia/nova" exact>
              <NewsCreate toggleTitles={toggleTitles} />
            </Route>

            <Route path="/noticia/editar/:news_id" exact>
              <NewsUpdate toggleTitles={toggleTitles} />
            </Route>

            <Route path="/agendas" exact>
              <Schedule toggleTitles={toggleTitles} />
            </Route>

            <Route path="/agenda/nova" exact>
              <ScheduleCreate toggleTitles={toggleTitles} />
            </Route>

            <Route path="/agenda/editar/:schedule_id" exact>
              <ScheduleUpdate toggleTitles={toggleTitles} />
            </Route>

            <Route path="/agenda-tipos" exact>
              <ScheduleType toggleTitles={toggleTitles} />
            </Route>

            <Route path="/cursos" exact>
              <Course toggleTitles={toggleTitles} />
            </Route>

            <Route path="/curso/novo" exact>
              <CourseCreate toggleTitles={toggleTitles} />
            </Route>

            <Route path="/curso/editar/:course_id" exact>
              <CourseUpdate toggleTitles={toggleTitles} />
            </Route>

            <Route path="/curso/presenca/:course_id" exact>
              <CoursePresent toggleTitles={toggleTitles} />
            </Route>

            <Route path="/certificados" exact>
              <Certificate toggleTitles={toggleTitles} />
            </Route>

            <Route path="/certificados/importacao" exact>
              <CertificateImport toggleTitles={toggleTitles} />
            </Route>

            <Route path="/certificados/historico/:certificate_update_history_id" exact>
              <CertificateDetails toggleTitles={toggleTitles} />
            </Route>
          </Main>
        </Container>
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
