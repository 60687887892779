import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "styled-components";

/* import utils */
import { filterString, maskCurrencyReal, getDate, maskCurrencyRealtoCalc } from "./../../util/functions";
import { NotifyContext } from "./../../util/notify";

/* import services */
import api from "./../../services/api";
import upload from "./../../services/s3";

import { useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";

/* import components */
import Legend from "./../../components/Legend";
import Search from "./../../components/Search";
import Input from "./../../components/Form/Input";
import Select from "./../../components/Form/Select";
import FormSelectMultiple from "./../../components/Form/SelectMultiple";
import File from "./../../components/Form/File";

/* import icons */
import IncomeIcon from "./../../assets/icons/income";

import { Container, Cards, Card, Title, Subtitle, Comment, AddButton, FormModal, FormGroup } from "./styles";

function Sede({ toggleTitles }) {
  toggleTitles("Sede", "Parametrização das sedes regionais");

  const { status: colors } = useContext(ThemeContext);
  const { notify } = useContext(NotifyContext);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [modalOpen, setModalOpen] = useState(false);

  const [data, setData] = useState([]);

  const [titleModal, setTitleModal] = useState("Nova delegacia");

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const handleClearForm = event => {
    event.preventDefault();

    setTitleModal("Nova Sede");

    setId("");
    setName("");
    setEmail("");
  };

  const handleNewData = () => {
    setTitleModal("Nova Sede");

    setId("");
    setName("");
    setEmail("");

    setModalOpen(true);
  };

  const handleOpenModalEdit = async (p_id, p_email, p_name) => {
    setTitleModal("Editar Sede");

    setId(p_id);
    setName(p_name);
    setEmail(p_email);

    setModalOpen(true);
  };

  const handleSave = async event => {
    event.preventDefault();
    console.log(name);
    console.log(email);
    try {
      if (!id) {
        await api.post(`/sede`, { name, email });
        notify.show(true, "Registro adicionado com sucesso", "success");
      } else {
        await api.put(`/sede`, { name, email, id });
        notify.show(true, "Registro atualizado com sucesso", "success");
      }

      loadData();
      setModalOpen(false);
    } catch (error) {
      console.log(error);
      notify.show(true, "Erro ao salvar registro! Tente novamente", "error");
    }
  };

  async function loadData() {
    try {
      const { data } = await api.get("/sede");
      await setData(data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Container>
        <Cards>
          {data.map(({ id, email, name }) => {
            return (
              <Card
                onClick={() => {
                  handleOpenModalEdit(id, email, name);
                }}
                status={colors["active"]}
                key={id}>
                <Title>
                  {name}
                  <br />
                  <Comment>{email}</Comment>
                </Title>
              </Card>
            );
          })}
        </Cards>

        <AddButton onClick={() => handleNewData()}>
          <IncomeIcon /> Nova Sede
        </AddButton>
      </Container>

      <Dialog
        fullScreen={fullScreen}
        open={modalOpen}
        onClose={event => {
          handleClearForm(event);
          setModalOpen(false);
        }}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{titleModal}</DialogTitle>
        <DialogContent>
          <FormModal onSubmit={handleSave} autoComplete="off">
            <FormGroup>
              <Input id="name" name="name" label="Sede" tabIndex={101} type="text" initialValue={name} onChange={text => setName(text)} required={true} />

              <Input id="email" name="email" label="Email" tabIndex={102} type="text" initialValue={email} onChange={text => setEmail(text)} required={true} />
            </FormGroup>

            <button id="btnFormSubmit" style={{ display: "none" }}>
              enviar
            </button>
          </FormModal>
        </DialogContent>

        <DialogActions>
          <Button
            tabIndex={111}
            onClick={event => {
              handleClearForm(event);
              setModalOpen(false);
            }}
            color="secondary">
            Cancelar
          </Button>
          <Button tabIndex={110} color="primary" onClick={() => document.querySelector("#btnFormSubmit").click()}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Sede;
