import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "styled-components";

/* import utils */
import { filterString, maskCurrencyReal, getDate, maskCurrencyRealtoCalc } from "./../../util/functions";
import { NotifyContext } from "./../../util/notify";

/* import services */
import api from "./../../services/api";
import upload from "./../../services/s3";

import { useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";

/* import components */
import Legend from "./../../components/Legend";
import Search from "./../../components/Search";
import Input from "./../../components/Form/Input";
import Select from "./../../components/Form/Select";
import FormSelectMultiple from "./../../components/Form/SelectMultiple";
import File from "./../../components/Form/File";
import FormArchive from "./../../components/Form/Archive";

/* import icons */
import IncomeIcon from "./../../assets/icons/income";

/* import styles */
import { Container, Cards, Card, Title, Subtitle, Comment, AddButton, FormModal, FormGroup } from "./styles";

export default function Files({ toggleTitles }) {
  toggleTitles("Arquivos", "Gestão de downloads");

  const { status: colors } = useContext(ThemeContext);
  const { notify } = useContext(NotifyContext);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const client_id = process.env.REACT_APP_CLIENT_ID;
  const site_id = process.env.REACT_APP_SITE_ID;

  const [modalOpen, setModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchStatus, setSearchStatus] = useState("");

  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);

  const [titleModal, setTitleModal] = useState("Nova Edição");

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [is_active, setIs_active] = useState(true);
  const [file_path, setFile_path] = useState("");
  const [files, setFiles] = useState([]);
  const [file_type_id, setFile_type_id] = useState("");
  const [file_types, setFile_types] = useState([]);

  const handleFilter = async text => {
    const filtered = initialData.filter(
      item =>
        filterString(item.is_active ?? false, (searchStatus === "inative" ? false : true) ?? "") &&
        (filterString(item.id, text) || filterString(item.name, text)),
    );
    setData(filtered);
  };

  const handleClearForm = event => {
    event.preventDefault();

    setTitleModal("Novo arquivo");

    setId("");
    setName("");
    setIs_active(true);
    setFile_path("");
    setFiles([]);
    setFile_type_id("");
  };

  const handleNewData = () => {
    setTitleModal("Novo arquivo");

    setId("");
    setName("");
    setIs_active(true);
    setFile_path("");
    setFiles([]);
    setFile_type_id("");

    setModalOpen(true);
  };

  const handleOpenModalEdit = async (p_id, p_name, p_file_path, p_is_active, p_file_type_id) => {
    setTitleModal("Editar Edição");

    setId(p_id);
    setName(p_name);
    setFile_path(p_file_path);
    setIs_active(p_is_active);
    setFiles([{ file: p_file_path }]);
    setFile_type_id(p_file_type_id);

    setModalOpen(true);
  };

  const handleSave = async event => {
    event.preventDefault();

    if (files.length <= 0) {
      notify.show(true, "Selecione o arquivo em PDF", "info");
      return;
    }

    notify.show(true, "Salvando...", "info");

    const fileUpload = await upload(client_id, site_id, files);
    let file;

    if (fileUpload && fileUpload[0].fileUrl) {
      file = fileUpload[0].fileUrl;
    } else {
      notify.show(true, "Não foi possivel realizar o upload do arquivo! Tente novamente.", "error");
      return;
    }

    try {
      if (!id) {
        if (!file) {
          notify.show(true, "Defina um arquivo", "info");
          return false;
        }

        await api.post(`/files/`, { file_path: file, name, is_active, file_type_id });
        notify.show(true, "Registro adicionado com sucesso", "success");
      } else {
        await api.put(`/files/${id}`, { file_path: file, name, is_active, file_type_id });
        notify.show(true, "Registro atualizado com sucesso", "success");
      }

      loadData();
      setModalOpen(false);
    } catch (error) {
      console.log(error);
      notify.show(true, "Erro ao salvar registro! Tente novamente", "error");
    }
  };

  async function loadData() {
    try {
      const { data } = await api.get(`/files/panel`, {});
      await setInitialData(data);
      await setData(data);
    } catch (error) {
      console.log(error);
    }
  }

  async function loadTypes() {
    try {
      const { data } = await api.get("/filetypes/", {});
      setFile_types(data);
    } catch (error) {
      notify.show(true, "Erro ao consutar categorias de arquivos", "error");
    }
  }

  useEffect(() => {
    handleFilter(searchValue);
  }, [searchStatus]);

  useEffect(() => {
    loadTypes();
    loadData();
    document.querySelector("#input").focus();
  }, []);

  return (
    <>
      <Container>
        <Legend
          active={searchStatus}
          onClick={status => {
            setSearchStatus(status);
          }}
          items={[
            {
              key: "status",
              value: "active",
              title: "Filtrar Ativos",
              color: colors["active"],
            },
            {
              key: "status",
              value: "inative",
              title: "Filtrar Inativos",
              color: colors["inative"],
            },
          ]}
        />

        <Cards>
          {data.map(({ id, name, file_path, is_active, file_type_id }) => {
            return (
              <Card
                onClick={() => {
                  handleOpenModalEdit(id, name, file_path, is_active, file_type_id);
                }}
                status={colors[is_active ? "active" : "inative"]}
                key={id}>
                <Title>
                  {name}
                  <br />
                  <Comment>{file_path}</Comment>
                </Title>
              </Card>
            );
          })}
        </Cards>

        <Search
          InitialValue={searchValue}
          onChange={text => {
            setSearchValue(text);
            handleFilter(text);
          }}
        />

        <AddButton onClick={() => handleNewData()}>
          <IncomeIcon /> Novo arquivo
        </AddButton>
      </Container>

      <Dialog
        fullScreen={fullScreen}
        open={modalOpen}
        onClose={event => {
          handleClearForm(event);
          setModalOpen(false);
        }}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{titleModal}</DialogTitle>
        <DialogContent>
          <FormModal onSubmit={handleSave} autoComplete="off">
            <FormGroup>
              <Input
                id="name"
                name="name"
                label="Nome"
                tabIndex={101}
                type="text"
                initialValue={name}
                onChange={text => {
                  setName(text);
                }}
                required={true}
              />

              <FormArchive
                id="file_path"
                name="file_path"
                label="Arquivo em pdf (máx 5mb)"
                accept="application/pdf"
                tabIndex={102}
                onChange={files => {
                  setFiles(files);
                }}
                required={false}
              />

              <Select
                id="file_type_id"
                name="file_type_id"
                label="Categoria do arquivo"
                tabIndex={103}
                initialValue={file_type_id}
                onChange={text => {
                  setFile_type_id(text);
                }}
                options={[{ value: "", text: "Sem categoria" }, ...file_types.map(item => ({ value: item.id, text: item.name }))]}
                multiple={false}
              />

              <Select
                id="is_active"
                name="is_active"
                label="Situação"
                tabIndex={104}
                initialValue={is_active}
                options={[
                  { value: true, text: "Ativo" },
                  { value: false, text: "Inativo" },
                ]}
                onChange={text => {
                  setIs_active(text);
                }}
                multiple={false}
              />
            </FormGroup>

            <button id="btnFormSubmit" style={{ display: "none" }}>
              enviar
            </button>
          </FormModal>
        </DialogContent>

        <DialogActions>
          <Button
            tabIndex={105}
            onClick={event => {
              handleClearForm(event);
              setModalOpen(false);
            }}
            color="secondary">
            Cancelar
          </Button>
          <Button tabIndex={104} color="primary" onClick={() => document.querySelector("#btnFormSubmit").click()}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
