import React, { useState, useContext, useEffect } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

/* import utils */
import { NotifyContext } from "./../../../util/notify";

/* import services */
import api from "./../../../services/api";
import upload from "./../../../services/s3";

/* import components */
import Input from "./../../../components/Form/Input";
import Editor from "./../../../components/Form/Editor";
import Select from "./../../../components/Form/Select";
import FileImage from "./../../../components/Form/FileImage";

import SaveButton from "./../../../components/Form/Buttons/Save";
import CancelButton from "./../../../components/Form/Buttons/Cancel";

/* import styles  */
import { Container, Form } from "./styles";

export default function Update({ toggleTitles }) {
  toggleTitles("Páginas", "Criar nova Página");
  const { notify } = useContext(NotifyContext);

  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [content, setContent] = useState("");
  const [uri, setUri] = useState("");
  const [page, setPage] = useState("");
  const [is_static, setIs_static] = useState(true);
  const [is_active, setIs_active] = useState(true);

  const [image_path, setImage_path] = useState("");
  const [images, setImages] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const [componentEditor, setComponentEditor] = useState();

  const uploadS3 = async () => {
    if (images.length > 0) {
      notify.show(true, "Fazendo upload da imagem para ser usada", "info");

      const client_id = process.env.REACT_APP_CLIENT_ID;
      const site_id = process.env.REACT_APP_SITE_ID;

      const imagesUpload = await upload(client_id, site_id, images, "ckeditor/");

      if (imagesUpload && imagesUpload[0].fileUrl) {
        await setImage_path(imagesUpload[0].fileUrl);
        await setModalOpen(true);
        notify.show(true, "Upload realizado com suscesso", "success");
      } else {
        notify.show(true, "Não foi possivel realizar o upload da imagem! Tente novamente.", "error");
        return;
      }
    }
  };

  const handleClear = event => {
    event.preventDefault();

    setId("");
    setTitle("");
    setDescription("");
    setContent("");
    componentEditor.setData("");
    setUri("");
    setPage("");
    setIs_static(true);
    setIs_active(true);

    notify.show(true, "Campos limpos", "info");
  };

  const handleSave = async event => {
    event.preventDefault();
    notify.show(true, "Salvando ...", "info");

    try {
      await api.post("/pages", { title, description, content: componentEditor.getData(), is_static, is_active });
      notify.show(true, "Registro adicionado com sucesso", "success");
      document.location.reload();
    } catch (error) {
      console.log(error);
      notify.show(true, "Erro ao adicionar registro", "error");
    }
  };

  useEffect(() => {
    uploadS3();
  }, [images]);

  return (
    <>
      <Container>
        <form onSubmit={handleSave}>
          <Form>
            <Input
              id="title"
              name="title"
              label="Título (importante para o SEO)"
              tabIndex={1}
              type="text"
              initialValue={title}
              onChange={text => {
                setTitle(text);
              }}
              required={true}
              maxLength={59}
            />

            <Input
              id="description"
              name="description"
              label="Descrição (importante para o SEO)"
              tabIndex={2}
              type="text"
              initialValue={description}
              onChange={text => {
                setDescription(text);
              }}
              required={true}
              maxLength={160}
            />

            <Select
              id="is_active"
              name="is_active"
              label="Situção"
              tabIndex={3}
              initialValue={is_active}
              onChange={text => {
                setIs_active(text);
              }}
              options={[
                { value: true, text: "Ativa" },
                { value: false, text: "Inativa" },
              ]}
              multiple={false}
              required={true}
            />

            <FileImage
              id="image_path"
              initialFile={image_path}
              onChange={files => {
                setImages(files);
              }}
              label="Subir uma imagem para o servidor"
            />

            <Editor label="Conteúdo" id="content" initialValue={content} onInit={component => setComponentEditor(component)} />
          </Form>
          <SaveButton type="submit" {...{ id: "save", text: "Salvar", tabIndex: 9 }} onClick={() => {}} />

          <CancelButton {...{ id: "cancel", text: "Limpar", tabIndex: 10 }} onClick={event => handleClear(event)} />
        </form>
      </Container>

      <Dialog
        open={modalOpen}
        onClose={event => {
          setModalOpen(false);
        }}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">Copiar imagem </DialogTitle>
        <DialogContent style={{ textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <img src={image_path} style={{ maxWidth: "400px" }} alt="Copiar imagem" />
          <br />
          <label>Copie a imagem usando o botão direito no mouse</label>
          <label>Copie a imagem e cole no editor</label>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={event => {
              setModalOpen(false);
            }}
            color="secondary">
            Cancelar
          </Button>
          <Button
            tabIndex={107}
            color="primary"
            onClick={() => {
              setModalOpen(false);
            }}>
            Já copiei
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
