import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import api from "./../../../services/api";
import { getDate } from "./../../../util/functions";

import "./styles.css";

function Subscribe() {
  const { course_id, prensent_only = "-" } = useParams();
  const [course, setCourse] = useState({ course_subscribes: [] });
  const [presents, setPresents] = useState("");

  const loadSubscribe = async () => {
    const { data } = await api.get(`/courses/${course_id}/subscribes?prensent_only=${prensent_only}`);
    setCourse(data);

    window.print();
  };

  useEffect(() => {
    loadSubscribe();

    setPresents(prensent_only);
  }, []);

  return (
    <div className="body">
      <fieldset>
        <legend>
          <h3>CRO MT - Conselho Regional de Odontologia do Mato Grosso</h3>
        </legend>

        <div></div>
        <table cellPadding="0" cellsPacing="0">
          <tbody>
            <tr>
              <td width="10%">
                <strong>Curso: </strong>
              </td>
              <td>{course.name}</td>
            </tr>
            <tr>
              <td>
                <strong>Local:</strong>
              </td>
              <td>{course.local}</td>
            </tr>
            <tr>
              <td>
                <strong>Data: </strong>
              </td>
              <td>{getDate(course.start_date ?? "", "dd'/'MM'/'yyyy HH':'mm")}</td>
            </tr>
          </tbody>
        </table>

        <div></div>
        <div style={{ textAlign: "center" }}>
          <strong>Lista de Inscritos{presents === "false" ? " - Não presentes" : presents === "true" ? " - Presentes" : ""}</strong>
        </div>

        <form>
          <br />
          <table style={{ width: "100%" }} cellPadding="0" cellsPacing="0">
            <tbody>
              <tr style={{ backgroundColor: "#914046", color: "#fff" }}>
                <th style={{ width: "5%" }}></th>
                <th align="left" style={{ width: "25%", paddingLeft: "5px" }}>
                  Nome
                </th>
                <th align="left" style={{ width: "25%", paddingLeft: "5px" }}>
                  CPF
                </th>
                <th align="center" style={{ width: "45%", paddingLeft: "5px" }}>
                  Assinatura
                </th>
              </tr>

              {course.course_subscribes.map((subscribe, index) => (
                <tr>
                  <td style={{ borderBottom: "0.5px solid rgba(0,0,0,0.1)", padding: "5px" }}>{index + 1}</td>
                  <td style={{ borderBottom: "0.5px solid rgba(0,0,0,0.1)", padding: "5px" }}>{subscribe.site_user.name.toString().toUpperCase()}</td>
                  <td style={{ borderBottom: "0.5px solid rgba(0,0,0,0.1)", padding: "5px" }}>{subscribe.site_user.cpf}</td>
                  <td style={{ borderBottom: "1px solid #000" }}></td>
                </tr>
              ))}
            </tbody>
          </table>
        </form>
        <br />
        <br />
        <br />
        <a href="javascript:window.print()">Imprimir</a>
      </fieldset>
    </div>
  );
}

export default Subscribe;
