import styled from "styled-components";

export const Button = styled.button`
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 15px 1px;
  position: absolute;
  left: 0px;
  bottom: 0px;

  border: 0;
  border-radius: 10px;
  background: #ffffff;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 15px;

  font-family: "AvenirLTStd-Book";
  font-size: 13px;
  line-height: 19px;
  color: #707070;
  text-transform: uppercase;

  cursor: pointer;

  svg {
    transition: 0.4s;
    margin-right: 11px;
  }

  transition: 0.4s;

  &:hover {
    svg {
      margin-right: 20px;
      width: 27px;
      height: 27px;

      transform: rotate(360deg);
    }
  }
`;
