import React from "react";

export default function CogIcon({
  color = "#100f03",
  width = 10.874,
  height = 10.874,
  styles = {},
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 10.874 10.874">
      <path
        d="M136.209,133.609a2.6,2.6,0,1,0,2.6,2.6A2.6,2.6,0,0,0,136.209,133.609Zm0,4.549a1.95,1.95,0,1,1,1.95-1.95A1.952,1.952,0,0,1,136.209,138.158Zm0,0"
        transform="translate(-130.772 -130.772)"
        fill={color}
      />
      <path
        d="M10.618,4.2l-.836-.182a4.548,4.548,0,0,0-.269-.649l.463-.72a.325.325,0,0,0-.044-.405l-1.3-1.3A.325.325,0,0,0,8.225.9l-.72.463a4.548,4.548,0,0,0-.649-.269L6.675.256A.325.325,0,0,0,6.358,0H4.516A.325.325,0,0,0,4.2.256l-.182.836a4.548,4.548,0,0,0-.649.269L2.649.9a.325.325,0,0,0-.405.044l-1.3,1.3A.325.325,0,0,0,.9,2.649l.463.72a4.548,4.548,0,0,0-.269.649L.256,4.2A.325.325,0,0,0,0,4.516V6.358a.325.325,0,0,0,.256.317l.836.182a4.548,4.548,0,0,0,.269.649L.9,8.225a.325.325,0,0,0,.044.405l1.3,1.3a.325.325,0,0,0,.405.044l.72-.463a4.548,4.548,0,0,0,.649.269l.182.836a.325.325,0,0,0,.317.256H6.358a.325.325,0,0,0,.317-.256l.182-.836a4.548,4.548,0,0,0,.649-.269l.72.463a.325.325,0,0,0,.405-.044l1.3-1.3a.325.325,0,0,0,.044-.405l-.463-.72a4.548,4.548,0,0,0,.269-.649l.836-.182a.325.325,0,0,0,.256-.317V4.516A.325.325,0,0,0,10.618,4.2Zm-.394,1.9-.766.167a.325.325,0,0,0-.244.23,3.9,3.9,0,0,1-.36.868.325.325,0,0,0,.01.335l.424.66-.932.932L7.7,8.864a.325.325,0,0,0-.335-.01,3.9,3.9,0,0,1-.868.36.325.325,0,0,0-.23.244l-.167.766H4.778l-.167-.766a.325.325,0,0,0-.23-.244,3.9,3.9,0,0,1-.868-.36.325.325,0,0,0-.335.01l-.66.424-.932-.932.424-.66a.325.325,0,0,0,.01-.335,3.9,3.9,0,0,1-.36-.868.325.325,0,0,0-.244-.23L.65,6.1V4.778l.766-.167a.325.325,0,0,0,.244-.23,3.9,3.9,0,0,1,.36-.868.325.325,0,0,0-.01-.335l-.424-.66.932-.932.66.424a.325.325,0,0,0,.335.01,3.9,3.9,0,0,1,.868-.36.325.325,0,0,0,.23-.244L4.778.65H6.1l.167.766a.325.325,0,0,0,.23.244,3.9,3.9,0,0,1,.868.36.325.325,0,0,0,.335-.01l.66-.424.932.932-.424.66a.325.325,0,0,0-.01.335,3.9,3.9,0,0,1,.36.868.325.325,0,0,0,.244.23l.766.167Zm0,0"
        fill={color}
      />
    </svg>
  );
}
