import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;

  position: relative;
  flex: 1;
`;

export const Cards = styled.div`
  flex: 1;

  padding-bottom: 65px;
  padding-top: 15px;
  padding-right: 5px;

  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  max-height: calc(100vh - 300px);
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  transition: 0.4s;

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.25);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.colors.primary};
  }

  @media (max-width: 1366px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const Card = styled(Link)`
  background-color: #ffffff;

  border-bottom: 2px solid ${props => props.status};

  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 7px 1px;

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  transition: 0.4s;

  border-radius: 6px;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 18px 29px 1px;
  }
`;

export const Figure = styled.figure`
  width: 100%;
  height: 150px;
  position: relative;
  overflow: hidden;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
`;

export const Title = styled.h2`
  font-family: "AvenirLTStd-Book";
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 2px;
  color: ${props => props.theme.colors.primary};

  padding: 10px 15px;
`;

export const Subtitle = styled.p`
  font-family: "AvenirLTStd-Book";
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1px;
  color: #707070;
  text-align: justify;

  padding: 10px 15px;
  flex: 1;
`;

export const Comment = styled.span`
  font-family: "AvenirLTStd-Book";
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1px;
  color: #707070;

  padding: 0 15px 15px;
`;

export const FormModal = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Icon = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`;

export const AddButton = styled.button`
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 15px 1px;
  position: absolute;
  right: 0px;
  bottom: 80px;

  border: 0;
  border-radius: 10px;
  background: #ffffff;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 15px;

  font-family: "AvenirLTStd-Book";
  font-size: 13px;
  line-height: 19px;
  color: #707070;
  text-transform: uppercase;

  cursor: pointer;

  z-index: 3;

  svg {
    transition: 0.4s;
    margin-right: 11px;
  }

  transition: 0.4s;

  &:hover {
    svg {
      margin-right: 20px;
    }
  }
`;
