export default {
  colors: {
    primary: "#6f130d",
    appBackground: "#F4F4FD",
    title: "#FFFFFF",
  },
  sizes: {
    nav: 350,
    minNav: 60,
  },
  status: {
    active: "#1818CE",
    inative: "#707070",

    open: "#1818CE",
    closed: "#106F2A",
    canceled: "#707070",

    in: "#106F2A",
    out: "#BF3126",
  },
};
