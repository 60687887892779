import React from "react";

export default function IncomeIcon({
  color = "#000000",
  width = 21,
  height = 21,
  styles = {},
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      style={styles}
      viewBox="0 0 30 30">
      <g transform="translate(-814 -294)">
        <g transform="translate(814 294)" fill="#fff">
          <path
            d="M 29.25 29.25 L 0.75 29.25 L 0.75 0.75 L 29.25 0.75 L 29.25 29.25 Z"
            stroke="none"
          />
          <path
            d="M 1.5 1.5 L 1.5 28.5 L 28.5 28.5 L 28.5 1.5 L 1.5 1.5 M 0 0 L 30 0 L 30 30 L 0 30 L 0 0 Z"
            stroke="none"
          />
        </g>
        <g transform="translate(836.224 316.886) rotate(180)">
          <g transform="translate(0 0.786)">
            <path
              d="M13.586,85.266,7.613,91.154,1.639,85.265a.965.965,0,0,0-1.357,0,.944.944,0,0,0,0,1.344l6.652,6.557a.994.994,0,0,0,1.358,0l6.652-6.558a.944.944,0,0,0,0-1.344A.969.969,0,0,0,13.586,85.266Z"
              transform="translate(-0.001 -78.334)"
              fill="#106f2a"
            />
            <path
              d="M6.933,8.966a.994.994,0,0,0,1.358,0l6.652-6.557a.944.944,0,0,0,0-1.344.965.965,0,0,0-1.357,0L7.612,6.953,1.638,1.065a.965.965,0,0,0-1.357,0,.944.944,0,0,0,0,1.344Z"
              transform="translate(0 -0.786)"
              fill="#106f2a"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
