import React from "react";

import { Container, FormGroup, Label, Input } from "./styles";

export default function FormArchive({ id, name, accept, onChange = () => {}, label, tabIndex, required = false, readOnly = false, maxSize = 5 }) {
  const handleCheckSizeFile = file => {
    const size = file.size / 1024 / 1024;
    if (size > maxSize) {
      alert(`Erro! Arquivo selecinado é muito grande.\nPermitido no máximo ${maxSize}MB `);
      return [{ blob: null, file: "" }];
    } else {
      return [{ blob: null, file: file }];
    }
  };

  return (
    <Container>
      <FormGroup
        onClick={() => {
          document.querySelector(`#${id}`).focus();
        }}>
        <Label for={`${id}`}>{label}</Label>
        <Input
          required={required}
          readOnly={readOnly}
          tabIndex={tabIndex}
          id={`${id}`}
          accept={accept}
          type="file"
          onChange={async event => onChange(await handleCheckSizeFile(event.target.files[0]))}
        />
      </FormGroup>
    </Container>
  );
}
