import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  position: absolute;
  left: 0;
  bottom: 0;

  width: 100%;
`;

export const FormGroup = styled.div`
  background: #fff;

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;

  padding: 5px 20px;
  margin-bottom: 5px;

  transition: 0.4s;
  &::after {
    content: "";
    width: 4px;
    height: 100%;

    background: ${(props) => props.theme.colors.primary};

    position: absolute;
    left: 0;
    top: 0;
  }

  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 2px 0px;

  ${(props) =>
    props.active
      ? `box-shadow: rgba(0,0,0,0.15) 0px 0px 35px 1px; position: relative; z-index: 9;`
      : ``};
`;

export const Label = styled.label`
  font-family: "AvenirLTStd-Book";
  font-size: 13px;
  line-height: 19px;
  color: #707070;
  text-transform: uppercase;

  width: 100%;
`;

export const Input = styled.input`
  height: 28px;
  width: 100%;
  border: 0;

  font-family: "AvenirLTStd-Roman";
  font-size: 16px;
  line-height: 19px;
  color: #707070;
`;

export const Add = styled.button`
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 15px 1px;
  position: absolute;
  right: 0px;
  top: -67px;

  border: 0;
  border-radius: 10px;
  background: #ffffff;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 15px;

  font-family: "AvenirLTStd-Book";
  font-size: 13px;
  line-height: 19px;
  color: #707070;
  text-transform: uppercase;

  cursor: pointer;

  svg {
    transition: 0.4s;
    margin-right: 11px;
  }

  transition: 0.4s;

  &:hover {
    svg {
      margin-right: 20px;
    }
  }
`;
