import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "styled-components";

/* import utils */
import { filterString, maskCurrencyReal, getDate, maskCurrencyRealtoCalc } from "./../../util/functions";
import { NotifyContext } from "./../../util/notify";

/* import services */
import api from "./../../services/api";

import { useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";

/* import components */
import Legend from "./../../components/Legend";
import Search from "./../../components/Search";
import Input from "./../../components/Form/Input";
import Select from "./../../components/Form/Select";
import FormSelectMultiple from "./../../components/Form/SelectMultiple";

/* import icons */
import AddUserIcon from "./../../assets/icons/addUser";

/* import styles */
import { Container, Cards, Card, Title, Subtitle, Comment, AddButton, FormModal, FormGroup } from "./styles";

export default function User({ toggleTitles }) {
  toggleTitles("Usuários", "Gerenciamento dos usuários");

  const { status: colors } = useContext(ThemeContext);
  const { notify } = useContext(NotifyContext);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [modalOpen, setModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchStatus, setSearchStatus] = useState("");

  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);

  const [titleModal, setTitleModal] = useState("Novo Usuário");

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [profile_id, setProfile_id] = useState("");
  const [is_active, setIs_active] = useState(true);
  const [profiles, setProfiles] = useState([]);
  const [modules, setModules] = useState([]);
  const [userModules, setUserModules] = useState([]);

  const handleFilter = async text => {
    const filtered = initialData.filter(
      item =>
        filterString(item.is_active ?? false, (searchStatus === "inative" ? false : true) ?? "") &&
        (filterString(item.id, text) ||
          filterString(item.name, text) ||
          filterString(item.email, text) ||
          filterString(item.profile ? item.profile.name : "", text)),
    );
    setData(filtered);
  };

  const handleClearForm = event => {
    event.preventDefault();

    setTitleModal("Novo Usuário");

    setId("");
    setName("");
    setEmail("");
    setPassword("");
    setProfile_id("");
    setIs_active(true);
    setUserModules([]);
  };

  const handleNewData = () => {
    setTitleModal("Novo Usuário");

    setId("");
    setName("");
    setEmail("");
    setPassword("");
    setProfile_id("");
    setIs_active(true);
    setUserModules([]);

    setModalOpen(true);
  };

  const handleOpenModalEdit = async p_id => {
    setTitleModal("Editar Usuário");

    notify.show(true, "Buscando registro", "info");

    try {
      const { data } = await api.get(`/users/${p_id}`, {});

      setId(p_id);
      setName(data.name);
      setEmail(data.email);
      setPassword(data.password);
      setProfile_id(data.profile_id);
      setIs_active(data.is_active);
      setUserModules(data.modules);

      setModalOpen(true);
    } catch (error) {
      console.log(error);
      notify.show(true, "Erro ao buscando registro", "error");
    }
  };

  const handleSave = async event => {
    event.preventDefault();

    try {
      if (!id) {
        if (!password) {
          notify.show(true, "Defina uma senha de acesso para o usuário", "success");
          return false;
        }

        await api.post(`/users/`, { name, email, password, profile_id, is_active, modules: userModules });
        notify.show(true, "Registro adicionado com sucesso", "success");
      } else {
        await api.put(`/users/${id}`, { name, email, password, profile_id, is_active, modules: userModules });
        notify.show(true, "Registro atualizado com sucesso", "success");
      }

      loadData();
      setModalOpen(false);
    } catch (error) {
      console.log(error);
      notify.show(true, "Erro ao salvar registro! Tente novamente", "error");
    }
  };

  async function loadData() {
    try {
      const { data } = await api.get(`/users/`, {});
      await setInitialData(data);
      await setData(data);
    } catch (error) {
      console.log(error);
    }
  }

  async function loadProfile() {
    try {
      const { data } = await api.get(`/profiles/`, {});
      await setProfiles(data);
    } catch (error) {
      console.log(error);
    }
  }

  async function loadModules() {
    try {
      const { data } = await api.get(`/modules/`, {});
      await setModules(data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleFilter(searchValue);
  }, [searchStatus]);

  useEffect(() => {
    loadProfile();
    loadModules();
    loadData();
    document.querySelector("#input").focus();
  }, []);

  return (
    <>
      <Container>
        <Legend
          active={searchStatus}
          onClick={status => {
            setSearchStatus(status);
          }}
          items={[
            {
              key: "status",
              value: "active",
              title: "Filtrar Ativos",
              color: colors["active"],
            },
            {
              key: "status",
              value: "inative",
              title: "Filtrar Inativos",
              color: colors["inative"],
            },
          ]}
        />

        <Cards>
          {data.map(({ id, name, is_active, email, profile }) => {
            return (
              <Card
                onClick={() => {
                  handleOpenModalEdit(id, name);
                }}
                status={colors[is_active ? "active" : "inative"]}
                key={id}>
                <Title>{name}</Title>
                <Subtitle>{email}</Subtitle>
                <Comment>{profile.name.toUpperCase()}</Comment>
              </Card>
            );
          })}
        </Cards>

        <Search
          InitialValue={searchValue}
          onChange={text => {
            setSearchValue(text);
            handleFilter(text);
          }}
        />

        <AddButton onClick={() => handleNewData()}>
          <AddUserIcon width={25} height={25} /> Novo Usuário
        </AddButton>
      </Container>

      <Dialog
        fullScreen={fullScreen}
        open={modalOpen}
        onClose={event => {
          handleClearForm(event);
          setModalOpen(false);
        }}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{titleModal}</DialogTitle>
        <DialogContent>
          <FormModal onSubmit={handleSave} autoComplete="off">
            <FormGroup>
              <FormSelectMultiple
                id="modules"
                name="modules"
                label="Módulos"
                tabIndex={100}
                initialValue={userModules}
                onChange={text => {
                  setUserModules(text);
                  console.log(text);
                }}
                options={modules.map(item => ({ value: item.id, label: item.name }))}
                multiple={false}
                required={true}
              />

              <Input
                id="name"
                name="name"
                label="Nome"
                tabIndex={101}
                type="text"
                initialValue={name}
                onChange={text => {
                  setName(text);
                }}
                required={true}
              />

              <Input
                id="email"
                name="email"
                label="E-mail"
                tabIndex={102}
                type="email"
                initialValue={email}
                onChange={text => {
                  setEmail(text);
                }}
                required={true}
              />

              <Input
                id="password"
                name="password"
                label="Senha"
                tabIndex={103}
                type="password"
                initialValue={password}
                onChange={text => {
                  setPassword(text);
                }}
              />

              <Select
                id="profile_id"
                name="profile_id"
                label="Perfil"
                tabIndex={105}
                initialValue={profile_id}
                onChange={text => {
                  setProfile_id(text);
                }}
                options={[{ value: "", text: "Selecione" }, ...profiles.map(item => ({ value: item.id, text: item.name }))]}
                multiple={false}
                required={true}
              />

              <Select
                id="is_active"
                name="is_active"
                label="Situção"
                tabIndex={107}
                initialValue={is_active}
                onChange={text => {
                  setIs_active(text);
                }}
                options={[
                  { value: "", text: "Selecione" },
                  { value: true, text: "Ativo" },
                  { value: false, text: "Inativo" },
                ]}
                multiple={false}
                required={true}
              />
            </FormGroup>

            <button id="btnFormSubmit" style={{ display: "none" }}>
              enviar
            </button>
          </FormModal>
        </DialogContent>

        <DialogActions>
          <Button
            tabIndex={109}
            onClick={event => {
              handleClearForm(event);
              setModalOpen(false);
            }}
            color="secondary">
            Cancelar
          </Button>
          <Button tabIndex={108} color="primary" onClick={() => document.querySelector("#btnFormSubmit").click()}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
