import React, { useState, useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

/* import services */
import api from "./../../services/api";
import { login } from "./../../services/auth";

/* import utils */
import { NotifyContext } from "./../../util/notify";

/* import images */
import logo from "./../../assets/logo/login.png";
import bg from "./../../assets/images/bg-login2.png";

/* import icons */
import LoginIcon from "./../../assets/icons/login";

import {
  Container,
  Background,
  BackgroundImage,
  Article,
  Content,
  Welcome,
  Logo,
  Client,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Forgot,
} from "./styles";

export default function Recovery() {
  const { params } = useParams();

  const [activeGroupInput, setActiveGroupInput] = useState(1);
  const [password, setPassword] = useState("");
  const [user, setUser] = useState({});
  const [text, setText] = useState(process.env.REACT_APP_CLIENT_NAME);

  const history = useHistory();
  const { notify } = useContext(NotifyContext);

  const handleSubmit = async event => {
    event.preventDefault();

    setText("Carregando...");

    try {
      const user = JSON.parse(atob(params));

      await api.put(`/password/recovery/`, { password, user_id: user.user_id, email: user.email });
      notify.show(true, "Nova senha definida com sucesso!", "success");
    } catch (error) {
      notify.show(true, "Erro ao definir senha! Tente novamente", "error");
      console.log(error.response.status);
      setText(process.env.REACT_APP_CLIENT_NAME);
    }
  };

  useEffect(() => {
    setUser(JSON.parse(atob(params)));
  }, []);

  return (
    <Container>
      <Background>
        <BackgroundImage src={bg} />
      </Background>

      <Article>
        <Content>
          <Welcome>Recupere sua senha!</Welcome>
          <Logo src={logo} />
          <Client>{text}</Client>

          <Form onSubmit={handleSubmit} autoComplete="off">
            <FormGroup>
              <Label for="email">E-mail</Label>
              <Input id="email" type="email" value={user.email} readOnly disabled />
            </FormGroup>

            <FormGroup active={activeGroupInput === 1 ? true : false} onClick={() => setActiveGroupInput(1)}>
              <Label for="password">Nova Senha</Label>
              <Input
                id="password"
                type="password"
                value={password}
                onChange={event => {
                  setPassword(event.target.value);
                }}
              />
            </FormGroup>

            {/*<Forgot>Esqueci minha senha</Forgot>*/}
            <Button>
              CONTINUAR <LoginIcon width={20} height={20} color="#100F03" />
            </Button>
          </Form>
        </Content>
      </Article>
    </Container>
  );
}
