import React from "react";

import { Button } from "./styles";

import CancelButtonIcon from "./../../../../assets/icons/cancelButton";

export default function Cancel({ id, text, tabIndex, onClick }) {
  return (
    <Button tabIndex={tabIndex} id={id} onClick={(event) => onClick(event)}>
      <CancelButtonIcon width={21} height={21} /> {text}
    </Button>
  );
}
