import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Parser from "html-react-parser";
import SvgIcon from "@material-ui/core/SvgIcon";

/* import services */
import api from "./../../services/api";
import { logout } from "./../../services/auth";
import { activeUrl } from "./../../util/functions";

/* import icons */
import HamburguerIcon from "./../../assets/icons/hamburguer";
import ExitIcon from "./../../assets/icons/exit";

/* import styles */
import { Container, Header, Items, Item, Label } from "./styles";

/* modules  */

export default function Nav({ handleOpen, isOpened }) {
  const history = useHistory();
  const [modules, setModules] = useState([]);

  useEffect(() => {
    async function loadModules() {
      const user_id = localStorage.getItem("USER_ID");

      const { data } = await api.get(`/users/${user_id}/modules`, {});
      if (data) {
        setModules(data);
      }
    }
    loadModules();
  }, []);

  useEffect(() => {
    if (modules.length > 0) {
      if (history.location.pathname === "/") {
        if (document.querySelector(".navbar")) {
          if (document.querySelector(".navbar").querySelector("a")) {
            document
              .querySelector(".navbar")
              .querySelector("a")
              .click();
          }
        }
      }
    }
  }, [modules]);

  return (
    <Container open={isOpened}>
      <Header>
        <HamburguerIcon onClick={() => handleOpen()} />
      </Header>

      <Items className="navbar">
        {modules.map(module => {
          return (
            <Item key={module.id} to={module.target} active={activeUrl(module.target, history)} title={module.title}>
              <SvgIcon children={Parser(module.icon)}></SvgIcon>
              <Label navOpen={isOpened}>{module.name}</Label>
            </Item>
          );
        })}

        <Item className="exit" onClick={() => logout(history)} navOpen={isOpened}>
          <ExitIcon color="#FFFFFF" width="25" height="25" styles={{ transform: "rotate(180deg)", transition: "0.6s" }} />
          <Label navOpen={isOpened}>SAIR</Label>
        </Item>
      </Items>
    </Container>
  );
}
