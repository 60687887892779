import React, { memo } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import { isAuthenticated } from "./services/auth";

import Login from "./pages/Login";
import Recovery from "./pages/Recovery";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Default from "./pages/";

import CourseSubscribe from "./pages/Course/Subscribe";

const Routes = () => (
  <BrowserRouter forceRefresh={false}>
    <Switch>
      <Route exact path="/login" component={props => (isAuthenticated() === false ? <Login {...props} /> : <Redirect to="/" />)} />
      <Route exact path="/password/recovery/:params" component={Recovery} />
      <Route exact path="/politica-de-privacidade" component={PrivacyPolicy} />

      <Route path="/curso/inscricoes/:course_id" exact>
        <CourseSubscribe />
      </Route>

      <Route path="/curso/inscricoes/:course_id/:prensent_only" exact>
        <CourseSubscribe />
      </Route>

      <Route component={props => (isAuthenticated() === false ? <Redirect to="/login" {...props} /> : <Default {...props} />)} />
    </Switch>
  </BrowserRouter>
);

export default memo(Routes);
