import styled from "styled-components";

export const Container = styled.section`
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 80px 1px;

  width: 980px;
  height: 600px;

  border-radius: 5px;

  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

export const Article = styled.article`
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
`;

export const Content = styled.div`
  position: relative;
  margin: 65px;

  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
`;

export const Welcome = styled.span`
  font-family: "AvenirLTStd-Book";
  font-size: 12px;
  line-height: 12px;
  color: #707070;

  position: absolute;
  right: 0;
  top: 0;
`;

export const Form = styled.form`
  margin-top: 10px;

  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
`;

export const Label = styled.label`
  font-family: "AvenirLTStd-Book";
  font-size: 12px;
  line-height: 19px;
  color: #707070;
  text-transform: uppercase;
`;

export const Button = styled.button`
  font-family: "AvenirLTStd-Black";
  font-size: 16px;
  line-height: 16px;
  color: #707070;
  letter-spacing: 2px;
  text-transform: uppercase;

  border: 0;
  background: transparent;

  margin: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;

  cursor: pointer;

  svg {
    margin-left: 10px;
  }

  &:hover {
    opacity: 0.5;
  }
`;

export const ContentText = styled.div``;
