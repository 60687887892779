import React from "react";

import { Container, FormGroup, Label, Input } from "./styles";

export default function FormInput({
  id,
  name,
  type,
  initialValue,
  onChange = () => {},
  onKeyUp = () => {},
  label,
  tabIndex,
  required = false,
  readOnly = false,
  minValue = "",
  maxValue = "",
  stepValue = "",
  maxLength = "",
}) {
  return (
    <Container>
      <FormGroup
        onClick={() => {
          document.querySelector(`#${id}`).focus();
        }}>
        <Label for={`${id}`}>{label}</Label>
        <Input
          required={required}
          readOnly={readOnly}
          tabIndex={tabIndex}
          id={`${id}`}
          type={type}
          value={initialValue}
          min={minValue}
          max={maxValue}
          step={stepValue}
          maxLength={maxLength}
          onChange={event => onChange(event.target.value)}
          onKeyUp={event => onKeyUp(event.target.value)}
          onMouseUp={event => onKeyUp(event.target.value)}
        />
      </FormGroup>
    </Container>
  );
}
