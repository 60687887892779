import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
`;

export const FormGroup = styled.div`
  background: #fff;

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;

  padding: 10px 20px;

  transition: 0.4s;

  ${props => (props.active ? `box-shadow: rgba(0,0,0,0.15) 0px 0px 35px 1px; position: relative; z-index: 9;` : ``)};

  .multi-select {
    width: 100%;
  }
`;

export const Label = styled.label`
  font-family: "AvenirLTStd-Black";
  font-size: 12px;
  line-height: 19px;
  color: #100f03;
  width: 100%;
`;

export const Select = styled.select`
  height: 39px;
  width: 100%;
  border: 0;
  border-radius: 3px;
  padding: 10px;

  font-family: "AvenirLTStd-Roman";
  font-size: 14px;
  line-height: 19px;
  color: "#100F03";

  background: transparent;
  border-bottom: 1px solid #cecece;

  transition: 0.4s;

  &:active {
    border-bottom: 1px solid #707070;
  }
  &:focus {
    border-bottom: 1px solid #707070;
  }
`;

export const Option = styled.option`
  font-family: "AvenirLTStd-Roman";
  font-size: 14px;
  line-height: 19px;
  color: "#100F03";
`;
