import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";

/* import services */
import api from "./../../services/api";
import { login } from "./../../services/auth";

/* import utils */
import { NotifyContext } from "./../../util/notify";

/* import images */
import logo from "./../../assets/logo/login.png";
import bg from "./../../assets/images/bg-login2.png";

/* import icons */
import LoginIcon from "./../../assets/icons/login";

import { Container, Background, BackgroundImage, Article, Content, Welcome, Logo, Client, Form, FormGroup, Label, Input, Button } from "./styles";

export default function Login() {
  const [activeGroupInput, setActiveGroupInput] = useState(1);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [text, setText] = useState(process.env.REACT_APP_CLIENT_NAME);

  const history = useHistory();
  const { notify } = useContext(NotifyContext);

  const handleSubmit = async event => {
    event.preventDefault();
    setText("Carregando...");

    try {
      const response = await api.post("/sessions", { email, password });
      if (response.data && response.data.user) {
        await login(response.data.token, response.data.user.id, response.data.user.email, response.data.user.profile_id, response.data.user.name, response.data.user.modules);
        setText("Entrando...");
        history.push("/");
      }
    } catch (e) {
      console.log(e);
      notify.show(true, "Usuário ou Senha Inválidos", "error");
      setText(process.env.REACT_APP_CLIENT_NAME);
    }
  };

  return (
    <Container>
      <Background>
        <BackgroundImage src={bg} />
      </Background>

      <Article>
        <Content>
          <Welcome>Bem Vindo!</Welcome>
          <Logo src={logo} />
          <Client>{text}</Client>

          <Form onSubmit={handleSubmit} autoComplete="off">
            <FormGroup active={activeGroupInput === 1 ? true : false} onClick={() => setActiveGroupInput(1)}>
              <Label for="email">E-mail</Label>
              <Input
                id="email"
                type="email"
                value={email}
                onChange={event => {
                  setEmail(event.target.value);
                }}
              />
            </FormGroup>

            <FormGroup active={activeGroupInput === 2 ? true : false} onClick={() => setActiveGroupInput(2)}>
              <Label for="password">Senha</Label>
              <Input
                id="password"
                type="password"
                value={password}
                onChange={event => {
                  setPassword(event.target.value);
                }}
              />
            </FormGroup>

            {/*<Forgot>Esqueci minha senha</Forgot>*/}
            <Button>
              Entrar <LoginIcon width={20} height={20} color="#100F03" />
            </Button>
          </Form>
        </Content>
      </Article>
    </Container>
  );
}
