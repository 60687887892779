import React, { useState, useEffect, useContext } from "react";

import { ThemeContext } from "styled-components";

/* import services */
import api from "./../../services/api";

/* import utils */
import { filterString, getDate } from "./../../util/functions";
import { NotifyContext } from "./../../util/notify";

import { useTheme } from "@material-ui/core/styles";

import Legend from "./../../components/Legend";
import Search from "./../../components/Search";

/* import icons */
import AddPageIcon from "./../../assets/icons/addPage";

/* import styles */
import { Container, Cards, Card, Title, Subtitle, Comment, Figure, Image } from "./styles";

export default function Contact({ toggleTitles }) {
  toggleTitles("Agenda", "Agenda de eventos");

  const { status: colors } = useContext(ThemeContext);
  const { notify } = useContext(NotifyContext);

  const theme = useTheme();

  const [modalOpen, setModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchStatus, setSearchStatus] = useState("");

  const [paginate, setPaginate] = useState({});
  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [addres, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [place, setPlace] = useState("");
  const [summary, setSummary] = useState("");
  const [uri, setUri] = useState("");
  const [is_active, setIs_active] = useState("");
  const [schedule_type_id, setSchedule_type_id] = useState("");
  const [image_path, setImage_path] = useState("");
  const [schedule_types, setSchedule_types] = useState([]);

  const handleFilter = async text => {
    const filtered = initialData.filter(
      item =>
        filterString(item.is_active ?? false, (searchStatus === "inative" ? false : true) ?? "") &&
        (filterString(item.id, text) ||
          filterString(item.name, text) ||
          filterString(item.address ?? "", text) ||
          filterString(item.city ?? "", text) ||
          filterString(item.place ?? "", text) ||
          filterString(getDate(item.start_date, "dd'/'MM'/'yyyy"), text) ||
          filterString(item.start_date ? getDate(item.start_date, "dd'/'MM'/'yyyy") : "", text) ||
          filterString(item.uri ?? "", text)),
    );
    setData(filtered);
  };

  async function loadData() {
    notify.show(true, "Carregando", "info");
    try {
      const { data } = await api.get(`/schedule/panel`, {});
      await setInitialData(data.data);
      await setData(data.data);
      setPaginate({ lastPage: 0, page: 0, perPage: 0, total: "0" });
    } catch (error) {
      console.log(error);
    }
    notify.show(false, "Carregando", "info");
  }

  useEffect(() => {
    handleFilter(searchValue);
  }, [searchStatus]);

  useEffect(() => {
    loadData();
    document.querySelector("#input").focus();
  }, []);

  return (
    <>
      <Container>
        <Legend
          active={searchStatus}
          onClick={status => {
            setSearchStatus(status);
          }}
          items={[
            {
              key: "status",
              value: "active",
              title: "Filtrar Ativos",
              color: colors["active"],
            },
            {
              key: "status",
              value: "inative",
              title: "Filtrar Inativos",
              color: colors["inative"],
            },
          ]}
        />
        <Cards>
          {data.map(({ id, name, start_date, end_date, summary, is_active, image_path }) => (
            <Card key={id} to={`/agenda/editar/${id}`} status={colors[is_active ? "active" : "inative"]}>
              <Figure>
                <Image src={image_path} />
              </Figure>

              <Title>{name}</Title>
              <Subtitle>
                {summary}
                <br />
                <br />
                <br />
              </Subtitle>
              <Comment>
                {getDate(start_date, "dd'/'MM'/'yyyy")}
                {end_date ? getDate(start_date, "' à 'dd'/'MM'/'yyyy") : ""}
              </Comment>
            </Card>
          ))}
        </Cards>

        <Search
          InitialValue={searchValue}
          onChange={text => {
            setSearchValue(text);
            handleFilter(text);
          }}
          btnAdd
          btnAddTo="/agenda/nova"
          btnAddIcon={<AddPageIcon />}
          btnAddtitle="Novo Evento"
        />
      </Container>
    </>
  );
}
