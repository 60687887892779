import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  max-width: 900px;
`;

export const FilterTitle = styled.p`
  font-size: 18px;
  color: #000;
  margin-bottom: 20px;
`;

export const FilterContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Filter = styled.div`
  margin-right: 15px;

  display: flex;
  flex-direction: column;
  flex: 1;
  & input {
    height: 52px;
    padding: 5px;
    font-size: 16px;
    font-family: "AvenirLTStd-Book";
    border: none;
    border-bottom: 1px solid #dddddd;
  }
`;

export const Label = styled.label`
  color: #862019;
  font-size: 18px;
  font-family: "AvenirLTStd-Roman";
  font-weight: bold;
  margin-bottom: 10px;
`;

export const FilterHorario = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const HorarioButton = styled.button`
  margin-right: 10px;
  margin-top: 10px;
  height: 40px;
  max-width: 140px;
  min-width: 100px;
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
  color: #862019;
  font-family: "AvenirLTStd-Book";
  font-weight: bold;
  &.active,
  &:hover {
    background-color: #862019;
    color: #f1f1f1;
    cursor: pointer;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  height: inherit;
`;

export const CardAgendamento = styled.div`
  max-width: 300px;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 3%;
  margin: 10px;
`;

export const CardTitle = styled.h2`
  color: #862019;
`;

export const CardRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const CardLabel = styled.label`
  font-size: 16px;
  font-family: "AvenirLTStd-Roman";
  margin-bottom: 5px;
`;

export const CardText = styled.text`
  color: #000000;
  font-family: "AvenirLTStd-Roman";
  font-weight: bold;
  font-size: 18;
`;
