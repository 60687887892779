import React from "react";

import { Container, FormGroup, Label, Select, Option } from "./styles";

export default function FormSelect({
  id,
  name,
  initialValue,
  onChange,
  label,
  tabIndex,
  multiple = false,
  required = false,
  readOnly = false,
  options = [],
}) {
  return (
    <Container>
      <FormGroup
        onClick={() => {
          document.querySelector(`#${id}`).focus();
        }}>
        <Label for={`${id}`}>{label}</Label>
        <Select
          required={required}
          tabIndex={tabIndex}
          readOnly={readOnly}
          disabled={readOnly}
          id={`${id}`}
          multiple={multiple}
          value={initialValue}
          onChange={(event) => onChange(event.target.value)}>
          {options.map(({ value, text }) => (
            <Option key={value} value={value}>
              {text}
            </Option>
          ))}
        </Select>
      </FormGroup>
    </Container>
  );
}
