import React, { useContext } from "react";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

export const NotifyContext = React.createContext(() => {});

export default function Notify({ open, message, type }) {
  const { notify } = useContext(NotifyContext);

  return (
    <Snackbar
      open={open}
      autoHideDuration={26000}
      onClose={() => {
        notify.hide();
      }}>
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={() => {
          notify.hide();
        }}
        severity={type}>
        {message}
      </MuiAlert>
    </Snackbar>
  );
}
