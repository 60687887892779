import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";

/* import utils */
import { NotifyContext } from "./../../../util/notify";
import { maskInteger } from "./../../../util/functions";

/* import services */
import api from "./../../../services/api";
import upload from "./../../../services/s3";

/* import components */
import Input from "./../../../components/Form/Input";
import Select from "./../../../components/Form/Select";
import File from "./../../../components/Form/File";
import Editor from "./../../../components/Form/Editor";

import SaveButton from "./../../../components/Form/Buttons/Save";
import CancelButton from "./../../../components/Form/Buttons/Cancel";

/* import styles  */
import { Container, Form } from "./styles";

export default function Update({ toggleTitles }) {
  const client_id = process.env.REACT_APP_CLIENT_ID;
  const site_id = process.env.REACT_APP_SITE_ID;

  toggleTitles("Curso", "Adicionar novo curso");
  const { notify } = useContext(NotifyContext);
  const history = useHistory();

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [summary, setSummary] = useState("");
  const [local, setLocal] = useState("");
  const [address, setAddress] = useState("");
  const [instructors, setInstructors] = useState("");
  const [curriculum, setCurriculum] = useState("");
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [total_hours, setTotal_hours] = useState("");
  const [capacity, setCapacity] = useState("");
  const [uri, setUri] = useState("");
  const [is_active, setIs_active] = useState(true);
  const [is_virtual, setIs_virtual] = useState(false);

  const [certificate_path, setCertificate_path] = useState("");
  const [certificates, setCertificates] = useState([]);

  const [image_path, setImage_path] = useState("");
  const [images, setImages] = useState([]);

  const [componentEditor, setComponentEditor] = useState();

  const handleSave = async event => {
    event.preventDefault();

    let certificate = "";

    if (images.length <= 0) {
      notify.show(true, "Uma imagem é obrigatória", "info");
      return;
    }

    notify.show(true, "Salvando...", "info");

    const imagesUpload = await upload(client_id, site_id, images, "cursos/");
    let image;

    if (imagesUpload && imagesUpload[0].fileUrl) {
      image = imagesUpload[0].fileUrl;
    } else {
      notify.show(true, "Não foi possivel realizar o upload da imagem! Tente novamente.", "error");
      return;
    }

    if (certificates.length > 0) {
      notify.show(true, "Salvando Certificado...", "info");

      const certificatesUpload = await upload(client_id, site_id, certificates, "cursos/certificados/");

      if (certificatesUpload && certificatesUpload[0].fileUrl) {
        certificate = certificatesUpload[0].fileUrl;
      } else {
        notify.show(true, "Não foi possivel realizar o upload da certificado! Tente novamente.", "error");
        return;
      }
    }

    try {
      await api.post("/courses", {
        name,
        summary,
        local,
        address,
        instructors,
        curriculum: componentEditor.getData(),
        start_date,
        end_date,
        total_hours,
        capacity,
        is_active,
        certificate_path: certificate,
        image_path: image,
        is_virtual,
      });
      notify.show(true, "Registro adicionado com sucesso", "success");
      document.location.reload();
    } catch (error) {
      console.log(error);
      notify.show(true, "Erro ao adicionar registro", "error");
    }
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSave}>
          <Form>
            <Select
              id="is_virtual"
              name="is_virtual"
              label="É um curso virtual?"
              tabIndex={0}
              initialValue={is_virtual}
              onChange={text => {
                setIs_virtual(text);
              }}
              options={[
                { value: "false", text: "NÃO" },
                { value: "true", text: "SIM" },
              ]}
              multiple={false}
            />

            <Input
              id="local"
              name="local"
              label={is_virtual ? "Link da Plataforma" : "Local"}
              tabIndex={1}
              type="text"
              initialValue={local}
              onChange={text => {
                setLocal(text);
              }}
            />

            <Input
              id="name"
              name="name"
              label="Nome do Curso (importante para o SEO)"
              tabIndex={2}
              type="text"
              initialValue={name}
              onChange={text => {
                setName(text);
              }}
              required={true}
              maxLength={255}
            />

            <Input
              id="summary"
              name="summary"
              label="Chamada/Resumo (importante para o SEO)"
              tabIndex={3}
              type="text"
              initialValue={summary}
              onChange={text => {
                setSummary(text);
              }}
              required={true}
              maxLength={255}
            />

            <Input
              id="start_date"
              name="start_date"
              label="Data e hora de início"
              tabIndex={4}
              type="datetime-local"
              initialValue={start_date}
              onChange={text => {
                setStart_date(text);
              }}
              required={true}
            />

            <Input
              id="end_date"
              name="end_date"
              label="Data e hora de encerramento"
              tabIndex={5}
              type="datetime-local"
              initialValue={end_date}
              onChange={text => {
                setEnd_date(text);
              }}
            />

            <Input
              id="address"
              name="address"
              label="Endereço"
              tabIndex={6}
              type="text"
              initialValue={address}
              onChange={text => {
                setAddress(text);
              }}
            />

            <Input
              id="total_hours"
              name="total_hours"
              label="Carga horária"
              tabIndex={8}
              type="number"
              initialValue={total_hours}
              onChange={text => {
                setTotal_hours(maskInteger(text));
              }}
              onKeyUp={text => {
                setTotal_hours(maskInteger(text));
              }}
            />

            <Input
              id="capacity"
              name="capacity"
              label="Capacidade"
              tabIndex={9}
              type="number"
              initialValue={capacity}
              onChange={text => {
                setCapacity(maskInteger(text));
              }}
              onKeyUp={text => {
                setCapacity(maskInteger(text));
              }}
            />

            <File
              id="image_path"
              initialFile={image_path}
              onChange={files => {
                setImages(files);
              }}
              label="Imagem da capa (800 x 600)"
              tabIndex={10}
              bg="#FFFFFF"
            />

            <File
              id="certificate_path"
              initialFile={certificate_path}
              onChange={files => {
                setCertificates(files);
              }}
              label="Certificado (A4/Paisagem = 29.7 X  21.0 cm)"
              tabIndex={10}
              maxWidthOrHeight={1122.519685039}
              bg="#FFFFFF"
            />

            <Select
              id="is_active"
              name="is_active"
              label="Situção"
              tabIndex={11}
              initialValue={is_active}
              onChange={text => {
                setIs_active(text);
              }}
              options={[
                { value: true, text: "Ativo" },
                { value: false, text: "Inativo" },
              ]}
              multiple={false}
              required={true}
            />

            <Input
              id="instructors"
              name="instructors"
              label="Instrutor(es)"
              tabIndex={12}
              type="text"
              initialValue={instructors}
              onChange={text => {
                setInstructors(text);
              }}
            />

            <Editor label="Currículo do(s) instrutor(es)" id="curriculum" initialValue={curriculum} onInit={component => setComponentEditor(component)} />
          </Form>

          <SaveButton {...{ id: "save", text: "Salvar", tabIndex: 14 }} onClick={() => {}} />
          <CancelButton {...{ id: "cancel", text: "Voltar", tabIndex: 15 }} onClick={event => history.goBack()} />
        </form>
      </Container>
    </>
  );
}
