import React, { useState, useEffect, useContext } from "react";

import { ThemeContext } from "styled-components";

/* import services */
import api from "./../../services/api";
import upload from "./../../services/s3";

/* import utils */
import { filterString, maskTelephone89Digitos, maskCep } from "./../../util/functions";
import { NotifyContext } from "./../../util/notify";

import { useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Legend from "./../../components/Legend";
import Search from "./../../components/Search";
import Input from "./../../components/Form/Input";
import Select from "./../../components/Form/Select";
import Textarea from "./../../components/Form/Textarea";
import File from "./../../components/Form/File";

/* import icons */
import IncomeIcon from "./../../assets/icons/income";

/* import styles */
import { Container, Cards, Card, Title, Subtitle, Comment, FormModal, FormGroup, Figure, Image, AddButton } from "./styles";

export default function Partner({ toggleTitles }) {
  toggleTitles("Parceiros", "Solicitação de parcerias");

  const { status: colors } = useContext(ThemeContext);
  const { notify } = useContext(NotifyContext);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const client_id = process.env.REACT_APP_CLIENT_ID;
  const site_id = process.env.REACT_APP_SITE_ID;

  const [modalOpen, setModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchStatus, setSearchStatus] = useState("");

  const [paginate, setPaginate] = useState({});
  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);

  const [titleModal, setTitleModal] = useState("Nova Parceria");

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [address_number, setAddress_number] = useState("");
  const [address_cep, setAddress_cep] = useState("");
  const [address_city, setAddress_city] = useState("");
  const [address_state, setAddress_state] = useState("");
  const [address_neighborhood, setAddress_neighborhood] = useState("");
  const [phones, setPhones] = useState("");
  const [site, setSite] = useState("");
  const [benefits, setBenefits] = useState("");
  const [partner_segment_id, setPartner_segment_id] = useState("");
  const [segment, setSegment] = useState("");
  const [segments, setSegments] = useState([]);
  const [image_path, setImage_path] = useState("");
  const [images, setImages] = useState([]);
  const [is_active, setIs_active] = useState("");
  const [instagram, setInstagram] = useState("");

  const handleFilter = async text => {
    const filtered = initialData.filter(
      item =>
        filterString(item.is_active ?? false, (searchStatus === "inative" ? false : true) ?? "") &&
        (filterString(item.id, text) ||
          filterString(item.name, text) ||
          filterString(item.address ?? "", text) ||
          filterString(item.address_number ?? "", text) ||
          filterString(item.address_cep ?? "", text) ||
          filterString(item.address_city ?? "", text) ||
          filterString(item.address_state ?? "", text) ||
          filterString(item.address_neighborhood ?? "", text) ||
          filterString(item.phones ?? "", text) ||
          filterString(item.site ?? "", text) ||
          filterString(item.benefits ?? "", text) ||
          filterString(item.phones ?? "", text) ||
          filterString(item.segment.name ?? "", text)),
    );
    setData(filtered);
  };

  const handleNewItem = () => {
    setTitleModal("Nova Parceria");

    setId("");
    setName("");
    setAddress("");
    setAddress_number("");
    setAddress_cep("");
    setAddress_city("");
    setAddress_state("");
    setAddress_neighborhood("");
    setPhones("");
    setSite("");
    setBenefits("");
    setPartner_segment_id("");
    setImage_path("");
    setImages([]);
    setIs_active(true);
    setInstagram("");

    setModalOpen(true);
  };

  const handleOpenModalEdit = (
    p_id,
    p_name,
    p_address,
    p_address_number,
    p_address_cep,
    p_address_city,
    p_address_state,
    p_address_neighborhood,
    p_phones,
    p_site,
    p_benefits,
    p_partner_segment_id,
    p_segment,
    p_image_path,
    p_is_active,
    p_instagram,
  ) => {
    setTitleModal("Parceria");

    setId(p_id);
    setName(p_name);
    setAddress(p_address);
    setAddress_number(p_address_number);
    setAddress_cep(p_address_cep);
    setAddress_city(p_address_city);
    setAddress_state(p_address_state);
    setAddress_neighborhood(p_address_neighborhood);
    setPhones(p_phones);
    setSite(p_site);
    setBenefits(p_benefits);
    setPartner_segment_id(p_partner_segment_id);
    setSegment(p_segment);
    setImage_path(p_image_path);
    setIs_active(p_is_active);
    setInstagram(p_instagram);
    setImages([{ file: p_image_path }]);

    setModalOpen(true);
  };

  async function loadData() {
    try {
      const { data } = await api.get(`/partners/panel`, {});
      await setInitialData(data.data);
      await setData(data.data);
      setPaginate({ lastPage: 0, page: 0, perPage: 0, total: "0" });
    } catch (error) {
      console.log(error);
    }
  }

  const handleSave = async event => {
    event.preventDefault();

    if (images.length <= 0) {
      notify.show(true, "Uma imagem é obrigatória", "info");
      return;
    }

    notify.show(true, "Salvando...", "info");

    const imagesUpload = await upload(client_id, site_id, images);
    let image;

    if (imagesUpload && imagesUpload[0].fileUrl) {
      image = imagesUpload[0].fileUrl;
    } else {
      notify.show(true, "Não foi possivel realizar o upload da imagem! Tente novamente.", "error");
      return;
    }

    try {
      if (!id) {
        await api.post(`/partners/`, {
          name,
          address,
          address_number,
          address_cep,
          address_city,
          address_state,
          address_neighborhood,
          phones,
          site,
          benefits,
          partner_segment_id,
          image_path: image,
          is_active,
          origin: "panel",
          instagram,
        });
        notify.show(true, "Registro adicionado com sucesso", "success");
      } else {
        await api.put(`/partners/${id}`, {
          name,
          address,
          address_number,
          address_cep,
          address_city,
          address_state,
          address_neighborhood,
          phones,
          site,
          benefits,
          partner_segment_id,
          image_path: image,
          is_active,
          instagram,
        });
        notify.show(true, "Registro atualizado com sucesso", "success");
      }
      loadData();
      setModalOpen(false);
    } catch (error) {
      console.log(error);
      notify.show(true, "Erro ao salvar registro! Tente novamente", "error");
    }
  };

  useEffect(() => {
    handleFilter(searchValue);
  }, [searchStatus]);

  /* effect load segments  */
  useEffect(() => {
    async function loadSegments() {
      const { data } = await api.get("/partners/segments");
      setSegments(data);
    }
    loadSegments();
  }, []);

  useEffect(() => {
    loadData();
    document.querySelector("#input").focus();
  }, []);

  return (
    <>
      <Container>
        <Legend
          active={searchStatus}
          onClick={status => {
            setSearchStatus(status);
          }}
          items={[
            {
              key: "status",
              value: "active",
              title: "Filtrar Ativos",
              color: colors["active"],
            },
            {
              key: "status",
              value: "inative",
              title: "Filtrar Inativos",
              color: colors["inative"],
            },
          ]}
        />
        <Cards>
          {data.map(
            ({
              id,
              name,
              address,
              address_number,
              address_cep,
              address_city,
              address_state,
              address_neighborhood,
              phones,
              site,
              benefits,
              partner_segment_id,
              segment,
              image_path,
              is_active,
              instagram,
            }) => (
              <Card
                key={id}
                onClick={() => {
                  handleOpenModalEdit(
                    id,
                    name,
                    address,
                    address_number,
                    address_cep,
                    address_city,
                    address_state,
                    address_neighborhood,
                    phones,
                    site,
                    benefits,
                    partner_segment_id,
                    segment.name,
                    image_path,
                    is_active,
                    instagram,
                  );
                }}
                status={colors[is_active ? "active" : "inative"]}>
                <Figure>
                  <Image src={image_path} />
                </Figure>
                <Title>{name}</Title>
                <Subtitle>
                  {benefits}
                  <br />
                  <br />
                </Subtitle>
                <Comment>{segment.name.toString().toUpperCase() ?? ""}</Comment>
              </Card>
            ),
          )}
        </Cards>

        <Search
          InitialValue={searchValue}
          onChange={text => {
            setSearchValue(text);
            handleFilter(text);
          }}
          btnAdd={false}
        />

        <AddButton onClick={() => handleNewItem()}>
          <IncomeIcon /> Novo Parceria
        </AddButton>
      </Container>

      <Dialog
        fullScreen={fullScreen}
        open={modalOpen}
        onClose={event => {
          setModalOpen(false);
        }}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{titleModal}</DialogTitle>
        <DialogContent>
          <FormModal onSubmit={handleSave} autoComplete="off">
            <FormGroup>
              <Select
                id="is_active"
                name="is_active"
                label="Situação"
                tabIndex={101}
                initialValue={is_active}
                options={[
                  { value: true, text: "Ativo" },
                  { value: false, text: "Inativo" },
                ]}
                onChange={text => {
                  setIs_active(text);
                }}
                multiple={false}
              />

              <Select
                id="partner_segment_id"
                name="partner_segment_id"
                label="Segmento"
                tabIndex={102}
                initialValue={partner_segment_id}
                onChange={text => {
                  setPartner_segment_id(text);
                }}
                options={[{ value: "", text: "Selecione" }, ...segments.map(item => ({ value: item.id, text: item.name }))]}
                multiple={false}
                required={true}
              />
              <Input
                id="name"
                name="name"
                label="Nome Fantasia"
                tabIndex={103}
                type="text"
                initialValue={name}
                onChange={text => {
                  setName(text);
                }}
                required={true}
              />
              <Input
                id="phones"
                name="phones"
                label="Telefones"
                tabIndex={104}
                type="text"
                initialValue={phones}
                onChange={text => setPhones(text)}
                onKeyUp={text => setPhones(maskTelephone89Digitos(text))}
                required={true}
              />
              <Input
                id="address"
                name="address"
                label="Endereço"
                tabIndex={103}
                type="text"
                initialValue={address}
                onChange={text => {
                  setAddress(text);
                }}
                required={true}
              />
              <Input
                id="address_number"
                name="address_number"
                label="Nº Endereço"
                tabIndex={103}
                type="text"
                initialValue={address_number}
                onChange={text => {
                  setAddress_number(text);
                }}
              />
              <Input
                id="address_cep"
                name="address_cep"
                label="CEP"
                tabIndex={103}
                type="text"
                initialValue={address_cep}
                onChange={text => setAddress_cep(text)}
                onKeyUp={text => setAddress_cep(maskCep(text))}
              />
              <Input
                id="address_city"
                name="address_city"
                label="Cidade/UF"
                tabIndex={103}
                type="text"
                initialValue={address_city}
                onChange={text => {
                  setAddress_city(text);
                }}
                required={true}
              />

              <Input
                id="address_state"
                name="address_state"
                label="Estado (UF)"
                tabIndex={103}
                type="text"
                initialValue={address_state}
                onChange={text => {
                  setAddress_state(text);
                }}
                required={true}
              />
              <Input
                id="address_neighborhood"
                name="address_neighborhood"
                label="Bairro"
                tabIndex={103}
                type="text"
                initialValue={address_neighborhood}
                onChange={text => {
                  setAddress_neighborhood(text);
                }}
                required={true}
              />
              <Input
                id="site"
                name="site"
                label="Site"
                tabIndex={104}
                type="text"
                initialValue={site}
                onChange={text => {
                  setSite(text);
                }}
              />

              <Input
                id="instagram"
                name="instagram"
                label="Instagram"
                tabIndex={105}
                type="text"
                initialValue={instagram}
                onChange={text => {
                  setInstagram(text);
                }}
              />

              <Textarea
                id="benefits"
                name="benefits"
                label="Befenicios"
                tabIndex={106}
                type="text"
                initialValue={benefits}
                onChange={text => {
                  setBenefits(text);
                }}
                required={true}
              />

              <File
                id="image_path"
                initialFile={image_path}
                onChange={files => {
                  setImages(files);
                }}
              />
            </FormGroup>
            <button id="btnFormSubmit" style={{ display: "none" }}>
              enviar
            </button>
          </FormModal>
        </DialogContent>

        <DialogActions>
          <Button
            tabIndex={109}
            onClick={event => {
              setModalOpen(false);
            }}
            color="secondary">
            Fechar
          </Button>
          <Button tabIndex={104} color="primary" onClick={() => document.querySelector("#btnFormSubmit").click()}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
