import React, { useState } from "react";
import { ThemeProvider } from "styled-components";

import Routes from "./routes";
import GlobalStyle from "./styles/global";
import defaultTheme from "./styles/theme";
import "./styles/global.css";

import Notify, { NotifyContext } from "./util/notify";

function App() {
  const [openNotify, setOpenNotify] = useState(false);
  const [messageNotify, setMessageNotify] = useState("");
  const [typeNotify, setTypeNotify] = useState("success");
  const notify = {
    show: (open, message, type, onClose) => {
      setOpenNotify(open);
      setMessageNotify(message);
      setTypeNotify(type);
    },
    hide: () => {
      setOpenNotify(false);
    },
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <NotifyContext.Provider value={{ notify }}>
        <GlobalStyle />
        <Routes />
        <Notify open={openNotify} message={messageNotify} type={typeNotify} />
      </NotifyContext.Provider>
    </ThemeProvider>
  );
}

export default App;
